import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

//assets
// import edit from "assets/svg/edit.svg";
import paris from "assets/svg/paris.svg";
// import duplicate from "assets/svg/copy.svg";
// import timer from "assets/svg/timer2.svg";
// import deletee from "assets/svg/whiteDelete.svg";
import pollHeader from "assets/svg/pollHeader.svg";

//libraries
import { toast } from "react-toastify";

//utils

import { getActivePoll, storePollState } from "utils/pollStateManagement";
import { deletePollQuestionType } from "store/Poll/pollSlice";
import {
  getPollStatus,
  endPollService,
  isWordCloudCheck,
  startPollService,
  pollDetailService,
  moveToFinishedPoll,
  extractEachDuration,
  storeWorldCloudAnswer,
  isQuestionInActivePoll,
  storeOptionBasedAnswer,
  setPresentationActivePoll,
  resetPresentationActivePoll,
  fetchQuestionFromFinishedPolls,
} from "utils/activePresentationUtils";

//components
import ActiveWordCloud from "./Polls/ActiveWordCloud";
import OptionBasedQuestion from "./Polls/OptionBasedQuestion";
import OptionList from "components/Admin/Presentation/PollCreation/PollTemplates/OptionList";

//hooks
import { StartPoll } from "hooks/SignalR/Invokers/Presenter/StartPoll";
import { PresenterEndPoll } from "hooks/SignalR/Invokers/Presenter/PresenterEndPoll";
import { useGenericListener } from "hooks/SignalR/Listeners/Global/UseGenericListener";

export default function PollQuestionTemplate({
  details,
  connection,
  pollId,
  activeCount,
}) {
  const dispatch = useDispatch();

  const { presentationId } = useParams();
  const { id, question, options, questionType, questionImageUrl, duration } =
    details ?? {};

  const type = questionType?.toLowerCase();
  const isWordCloud = type === "word cloud";
  const isTrueFalse = type === "true or false";

  //usestate
  // const [count, setCount] = useState(0);
  const [timer, setTimer] = useState(0);
  const [pollStatus, setPollStatus] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [activePoll, setActivePoll] = useState(null);
  const [isPollFinished, setIsPollFinished] = useState(false);

  //useeffect
  useEffect(() => {
    setIsPollFinished(
      fetchQuestionFromFinishedPolls(details?.id, pollId)?.answers
    );
  }, [details, pollId]);

  useEffect(() => {
    setActivePoll(getActivePoll());
  }, [pollStatus]);

  useEffect(() => {
    const handleEvent = () => {
      setPollStatus(getPollStatus());
    };
    window.addEventListener("pollStatus", handleEvent);
    return () => {
      window.removeEventListener("pollStatus", handleEvent);
    };
  }, []);

  useEffect(() => {
    const isQuestionInPoll = pollDetailService(pollId)?.questions?.find(
      (question) => question?.id === details?.id
    );

    !isQuestionInPoll && setActivePoll(null);
  }, [details, pollId]);

  //timer
  useEffect(() => {
    let interval;
    const pollStarted = getPollStatus();
    if (pollStarted) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev > 0) return prev - 1;
          clearInterval();
          endPoll();
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer]);

  //functions

  //timer
  const endPoll = () => {
    moveToFinishedPoll(pollId);
    setIsPollFinished(
      fetchQuestionFromFinishedPolls(details?.id, pollId)?.answers
    );
    endPollService();
    PresenterEndPoll(connection, presentationId, null, pollId);
    resetPresentationActivePoll();
  };

  const beginPoll = () => {
    StartPoll(connection, presentationId, null, pollId);
  };

  ////// HANDLING POLLS

  //hook

  useGenericListener(connection, (presentationId, eventName, obj) => {
    const event = eventName?.toLowerCase();

    if (event === "startpoll") {
      const { pollId } = obj ?? {};
      storePollState(pollId, true);
      setPresentationActivePoll(pollId);
      startPollService();

      const pollDetails = pollDetailService(pollId);
      setTimer(
        extractEachDuration(pollId)?.reduce((acc, cur) => {
          return acc + cur;
        }, 0)
      );
      localStorage.removeItem("wordCloudAnswer");
      const isQuestionInPoll = pollDetails?.questions?.find(
        (question) => question?.id === details?.id
      );

      setActivePoll(isQuestionInPoll);
    }

    // Viewers answers
    if (event === "viewerpollanswer") {
      const { answer, questionId, questionType } = obj ?? {};

      // WordCloud
      if (isWordCloudCheck(questionType)) {
        storeWorldCloudAnswer(answer, questionId);
      } else {
        storeOptionBasedAnswer(answer, questionId);
      }
    }
  });

  ///////////

  const deleteQT = () => {
    const pollId = id;
    setIsLoading(true);
    dispatch(deletePollQuestionType({ presentationId, pollId })).then(
      ({ type, payload }) => {
        setIsLoading(false);
        if (type?.includes("fulfilled")) {
          toast.success("Poll question type deleted successfully");
          window.location.reload();
        }
      }
    );
  };

  return (
    <section className="">
      {/*  */}
      {(activePoll && pollStatus) ||
      isPollFinished ||
      isQuestionInActivePoll(details?.id) ? (
        <>
          {isWordCloudCheck(questionType) ? (
            <ActiveWordCloud
              timer={timer}
              questionId={details?.id}
              questionDetails={details}
              pollId={pollId}
              endPoll={endPoll}
              isPollFinished={isPollFinished}
            />
          ) : (
            <OptionBasedQuestion
              timer={timer}
              pollId={pollId}
              questionDetail={details}
              activePoll={activePoll}
              questionId={details?.id}
              questionDetails={details}
              isPollFinished={isPollFinished}
            />
          )}

          {/* {!isPollFinished && (
            <section className="flex justify-center w-full mt-1">
              <button
                onClick={endPoll}
                className="py-2 text-white bg-black rounded-lg w-[40%] mx-auto"
              >
                End poll
              </button>
            </section>
          )} */}
        </>
      ) : (
        <>
          <section className="py-6 bg-gray100 min-h-[50vh] max-h-[80vh] overflow-y-scroll">
            {/* poll template */}

            <section className="w-full  px-4 py-4 bg-white rounded-2xl md:w-[40rem] mx-auto ">
              {/* header */}
              <section className="flex items-center justify-between ">
                <div className="flex items-center font-bold gap-x-2">
                  <img src={pollHeader} alt="" />
                  <h3>{questionType} </h3>
                </div>

                {/* <div className="flex items-center text-xs gap-x-4">
            <div
              onClick={deleteQT}
              className="flex items-center font-medium cursor-pointer gap-x-1 text-gray450"
            >
              {isLoading ? (
                <ClipLoader size={10} color="gray" />
              ) : (
                <img src={deletee} alt="delete" />
              )}

              <p>Delete</p>
            </div>
          </div> */}
              </section>
              {/*  */}

              {/* body */}

              <section
                className={`flex flex-col  py-4 my-4 gap-y-4 bg-gray550 rounded-xl min-h-[350px] overflow-y-scroll ${
                  isWordCloud || isTrueFalse
                    ? "items-center justify-center"
                    : "text-left pl-6 "
                }`}
              >
                {questionImageUrl && isTrueFalse && (
                  <img
                    src={paris}
                    alt=""
                    className="w-[9.8719rem] h-[6.6094rem]"
                  />
                )}
                <h3 className="text-lg font-semibold text-black40 ">
                  {question}
                </h3>
                {!isWordCloud && (
                  <section
                    className={`flex gap-x-4  ${
                      !isTrueFalse && "flex-col w-6/12 gap-y-4"
                    }  `}
                  >
                    {options?.map((option) => {
                      return <OptionList key={option?.id} option={option} />;
                    })}
                  </section>
                )}

                <section className="flex my-4 font-semibold gap-x-1 text-gray40">
                  <img src={timer} alt="" />
                  <p className="mt-[2px]">{duration} Secs</p>
                </section>

                {activeCount === 0 && (
                  <section className="flex w-6/12 mt-2 font-semibold gap-x-1 text-gray40">
                    <button
                      onClick={beginPoll}
                      className="w-full py-3 text-white bg-black rounded-lg"
                    >
                      Start poll now
                    </button>
                  </section>
                )}
              </section>

              {/*  */}
            </section>

            {/*  */}
          </section>
        </>
      )}
    </section>
  );
}
