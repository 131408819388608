import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  _downloadSlide,
  _setThumbnail,
  _translateQuestion,
  _uploadPPT,
} from "services/Slides/slidesServices";

const initialState = {
  status: "idle",
  translatedAudio: {
    isLoading: false,
    data: null,
  },
  sideImages: null,
  presentationUI: {
    activeSlide: null,
    activeImage: null,
  },
};

export const setThumbnail = createAsyncThunk(
  "slides/setThumbnail",
  async (data) => {
    const response = await _setThumbnail(data);
    return response.data;
  }
);

export const uploadPPT = createAsyncThunk(
  "slides/uploadPPT",
  async ({ data, config }) => {
    const response = await _uploadPPT(data, config);
    return response.data;
  }
);

export const translateQuestion = createAsyncThunk(
  "slides/translateQuestion",
  async ({ audio, data }) => {
    const response = await _translateQuestion(audio, data);
    return response.data;
  }
);

export const downloadSlide = createAsyncThunk(
  "slides/downloadSlide",
  async ({ slideId, presentationId, presentaConnectionId }) => {
    const response = await _downloadSlide(
      slideId,
      presentationId,
      presentaConnectionId
    );
    return response.data;
  }
);

export const slideSlice = createSlice({
  name: "slides",
  initialState,
  reducers: {
    clearTranslatedAudio(state) {
      state.translatedAudio.data = null;
    },

    setActiveSlide(state, { payload }) {
      state.presentationUI.activeSlide = payload;
    },

    setSideImages(state, { payload }) {
      state.sideImages = payload;
    },

    clearSideImages(state) {
      state.sideImages = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadPPT.pending, (state) => {})
      .addCase(uploadPPT.fulfilled, (state, { payload }) => {})
      .addCase(uploadPPT.rejected, (state, { payload }) => {});

    //translated question
    builder
      .addCase(translateQuestion.pending, (state) => {
        state.translatedAudio.isLoading = true;
      })
      .addCase(translateQuestion.fulfilled, (state, { payload }) => {
        state.translatedAudio.isLoading = false;
        state.translatedAudio.data = payload;
      })
      .addCase(translateQuestion.rejected, (state, { payload }) => {
        state.translatedAudio.isLoading = false;
      });
  },
});

export const getSlidesState = (state) => state.slides;
export const {
  clearTranslatedAudio,
  setSideImages,
  clearSideImages,
  setActiveSlide,
} = slideSlice.actions;
export default slideSlice.reducer;
