import { toast } from "react-toastify";

export const JumpToNextSlide = (connection, presentationId, documentId, slideNumber) => {
  if (connection) {
    connection.invoke("MoveSlide", presentationId, slideNumber,documentId).catch((err) => {
      console.log(err);
      toast.error("An error occured while navigating slide");
    });
  }
};
