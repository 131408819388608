import React from "react";
import { Navigate } from "react-router-dom";

export default function ProtectedRoute({ children }) {
  const getToken = JSON.parse(localStorage.getItem("user"))?.token;

  let token = getToken;

  if (!token) {
    return <Navigate to="/login" replace />;
  } else {
    return children;
  }
}
