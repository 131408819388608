import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//component
import Sidebar from "./Sidebar";

//slices
import { closePollDrawer, closeSidebar, getUIState } from "store/UI";

//libraries
import { Drawer } from "antd";
import PollCreation from "views/Admin/Presentation/Drawers/PollCreation";

export default function AdminLayout() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  //useselector
  const { isOpen, isPollDrawerOpen } = useSelector(getUIState);

  //variables
  const height = window.innerHeight - 150;
  const isWorkspace = pathname === "/admin/workspace";

  //functions
  const onClose = () => {
    dispatch(closePollDrawer());
  };

  return (
    <section className="relative min-h-screen ">
      <section
        className={`transition-all duration-500  ease-in-out fixed lg:translate-x-0 lg:w-[18%]  w-[70%]   z-50 ${
          isOpen ? " translate-x-0" : " translate-x-[-1000px]"
        } `}
      >
        <Sidebar />
      </section>

      <section
        className={`absolute right-0 ${
          isWorkspace ? "py-0 " : "lg:w-[82%] py-4 px-8"
        } w-full bg-white h-full  `}
        onClick={() => dispatch(closeSidebar())}
      >
        <Outlet />
      </section>

      <Drawer
        // title="Basic Drawer"
        placement="bottom"
        closable={false}
        onClose={onClose}
        open={isPollDrawerOpen}
        bodyStyle={{ padding: "0px" }}
        height={height}
      >
        <PollCreation />
      </Drawer>
    </section>
  );
}
