import { GenericInvoker } from "../Global/GenericInvoker";

export const StartPoll = (
  connection,
  presentationId,
  presentaConnectionId,
  pollId
) => {
  if (connection) {
    // console.log(presentationId, presentaConnectionId, pollId);

    GenericInvoker(
      connection,
      presentationId,
      presentaConnectionId,
      "StartPoll",
      {
        pollId,
        // startTime: new Date(),
        // pollDuration: extractEachDuration(pollId),
      }
    );
  }
};
