import { configureStore } from "@reduxjs/toolkit";

//slices

import sidebarSlice from "./UI";
import pollSlice from "./Poll/pollSlice";
import slideSlice from "./Slides/slidesSlice";
import signalRSlice from "./SignalR/signalRSlice";

import workspaceSlice from "./Workspace/workspaceSlice";
import presentationSlice from "./Presentations/presentationSlice";
import fileStructureSlice from "./FileStructure/fileStructureSlice";
import paymentSlice from "./Payment/paymentSlice";
import settingSlice from "./Settings/settingSlice";
import teamsSlice from "./Teams/teamsSlice";
import briefcaseSlice from "./Briefcase/briefcaseSlice";

const store = configureStore({
  reducer: {
    poll: pollSlice,
    teams: teamsSlice,
    slides: slideSlice,
    signalR: signalRSlice,
    settings: settingSlice,
    briefcase:briefcaseSlice,
    workspace: workspaceSlice,
    sidebarState: sidebarSlice,
    paymentState: paymentSlice,
    presentations: presentationSlice,
    fileStructure: fileStructureSlice,
  },
});

export default store;
