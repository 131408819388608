import React from "react";

import ViewerCard from "./ViewerCard";

export default function ViewerList({
  viewers,
  connection,
  presentationId,
  isPresentationActive,
}) {
  return (
    <section className="">
      <section className="flex items-center justify-between px-3 mb-2">
        <h3 className="text-xs font-bold text-gray60">
          IN THE MEETING ({viewers?.length})
        </h3>
      </section>

      <section>
        {viewers?.map((viewer, index) => {
          return (
            <ViewerCard
              key={index}
              viewer={viewer}
              connection={connection}
              presentationId={presentationId}
              isPresentationActive={isPresentationActive}
            />
          );
        })}
      </section>
    </section>
  );
}
