import React from "react";

//assets
import clock from "assets/svg/clock.svg";

export default function StartPresentation({ closeModal }) {
  return (
    <section className="">
      <div className="flex justify-center">
        <img src={clock} alt="attention mode" className="w-40" />
      </div>

      <section className="w-[93%] mx-auto my-4 font-grotesk">
        <div className="text-center">
          <h1 className="text-2xl font-bold">Start Presentation</h1>
          <p className="text-sm">Let's proceed with the presentation</p>
        </div>

        <div className="flex mt-4 gap-x-4">
          <button
            onClick={closeModal}
            className="w-full py-3 text-sm text-white rounded-lg cursor-pointer bg-primary gap-x-2"
          >
            Begin Presentation
          </button>
        </div>
      </section>
    </section>
  );
}
