import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//assets
import logo from "assets/svg/fullLogo.svg";

//utils
import { altLogins } from "./data";
import { baseUrl } from "utils/baseUrl";
import emailValidation from "utils/emailValidation";
import { useToast } from "components/UI/ToastProvider";
import { errorToast, successToast } from "utils/toastUtils";

//libraries
import axios from "axios";
import { ClipLoader } from "react-spinners";

//components
import Label from "components/UI/Label";
import AuthInput from "components/UI/Inputs/AuthInput";
import LoginCarousel from "components/Authentication/Login/Carousel";

export default function Register() {
  const navigate = useNavigate();
  const toast = useToast();

  //usestate
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [isDigit, setIsDigit] = useState(false);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [isNonAlphanumeric, setIsNonAlphanumeric] = useState(false);

  //useeffect
  useEffect(() => {
    const { name, email, password } = formData;
    setIsButtonActive(name !== "" && email !== "" && password !== "");
  }, [formData]);

  //functions
  const openWindow = (name) => {
    let domain = window.location.host;
    window.location.href = `https://testapi.thepresenta.com/login/${name}?origin=https://${domain}/`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "password") {
      setIsDigit(/\d/.test(value));
      setIsLengthValid(value.length >= 8);
      setIsNonAlphanumeric(/[^a-zA-Z0-9]/.test(value));
    }
  };

  const registerFunc = (e) => {
    e.preventDefault();

    const { name, email, password } = formData;
    if (!name) {
      errorToast( "Enter your fullname");
      return;
    }

    if (!email) {
      errorToast( "Enter your email address");
      return;
    }

    if (!emailValidation.checkEmail(email)) {
      return;
    }

    if (!password) {
      errorToast( "Enter a password");
      return;
    }

    setIsButtonActive(false);
    setIsLoading(true);
    const data = {
      email,
      password,
      fullName: name,
    };

    axios
      .post(`${baseUrl}/Account/Register`, data)
      .then((response) => {
        if (response) {
          localStorage.setItem("email", email);
          localStorage.setItem("ref", response?.data?.reference);
          successToast( "An OTP has been sent to your email");
          navigate("/otp");
        }
      })
      .catch((err) => {
        let errorMsg =
          err?.response?.data?.message ??
          err.response?.data?.title ??
          err?.message;

        errorToast( errorMsg);
        console.log(errorMsg, "registration");
      })
      .finally(() => {
        setIsLoading(false);
        setIsButtonActive(true);
      });
  };

  return (
    <section className="flex justify-between h-screen overflow-hidden font-grotesk ">
      <section className="lg:w-[40%] w-full px-10 lg:py-2 py-10 overflow-y-scroll  hide-scrollbar">
        <section className=" mt-[3%] w-[90%] mx-auto  ">
          <Link to="/">
            <div>
              <img src={logo} alt="Presenta Logo" className="w-20 lg:w-24" />
            </div>
          </Link>

          <h1 className="font-bold lg:text-[2rem] text-2xl  text-left  mt-6 ">
            Join Us
          </h1>

          <section className="mt-4">
            <form onSubmit={registerFunc} className="">
              <div>
                <Label text="Full name" />
                <AuthInput
                  type="text"
                  placeholder="Enter your full name"
                  name="name"
                  value={formData.name}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
              </div>

              <div className="my-4">
                <Label text="Email address" />
                <AuthInput
                  placeholder="example@presenta.com"
                  name="email"
                  type="email"
                  value={formData.name}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
              </div>

              <div className="mb-2">
                <Label text="Password" />
                <AuthInput
                  placeholder="Enter your password"
                  isPassword={true}
                  name="password"
                  value={formData.name}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                <ul className="mt-2">
                  <li
                    className={
                      isLengthValid ? "text-green-500" : "text-red-500"
                    }
                  >
                    Passwords must be at least 8 characters.
                  </li>
                  <li className={isDigit ? "text-green-500" : "text-red-500"}>
                    Passwords must have at least one digit ('0'-'9').
                  </li>
                  <li
                    className={
                      isNonAlphanumeric ? "text-green-500" : "text-red-500"
                    }
                  >
                    Passwords must have at least one non-alphanumeric character.
                  </li>
                </ul>
              </div>

              <button
                className={`w-full py-3  mt-2 text-sm text-white bg-black rounded-md ${
                  isButtonActive
                    ? "opacity-100"
                    : "opacity-50 cursor-not-allowed"
                }`}
                disabled={!isButtonActive}
                type="submit"
              >
                {isLoading ? <ClipLoader color="white" size={12} /> : "Sign up"}
              </button>
            </form>

            <div className="flex items-center justify-center mt-4 gap-x-2">
              <p className="text-sm ">Have an account?</p>

              <Link to="/login">
                <div className="flex items-center px-2 py-1 text-xs border border-black rounded gap-x-2 ">
                  <p className="font-semibold">Login</p>
                  <i className="pi pi-arrow-right"></i>
                </div>
              </Link>
            </div>
          </section>

          <div className="font-medium text-center lg:my-4 text-gray40 ">
            <h3>OR</h3>
          </div>

          <section className="flex flex-col justify-center my-4 gap-y-2 gap-x-5 lg:my-3">
            {altLogins.map((el, index) => {
              return (
                <div
                  onClick={() => openWindow(el.name)}
                  key={index}
                  className="flex items-center justify-center py-2 border-2 rounded-full cursor-pointer gap-x-4 "
                >
                  <div>
                    <img
                      src={el.img}
                      alt={`${el.name} Logo`}
                      className="w-5 lg:w-[1.5rem]"
                    />
                  </div>
                  <div className="text-sm font-medium ">
                    Sign in with {el.name}
                  </div>
                </div>
              );
            })}
          </section>
        </section>
      </section>

      {/* carousel */}
      <section className="lg:w-[60%] hidden lg:block bg-orange10 h-screen px-10 ">
        <LoginCarousel />
      </section>
      {/*  */}
    </section>
  );
}
