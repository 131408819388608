import React, { useState } from "react";

//assets
import dots from "assets/svg/dots.svg";
import pause from "assets/svg/pause.svg";
import trash from "assets/svg/trash.svg";
import access from "assets/svg/access.svg";
import details from "assets/svg/details2.svg";

//libraries
import moment from "moment";
import { Dropdown, Menu } from "antd";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ConfirmationModal from "components/UI/Modal/ConfirmationModal";
import { getTeamMembers, removeTeamMember } from "store/Teams/teamsSlice";
import { useDispatch } from "react-redux";
import { successToast } from "utils/toastUtils";
import { useToast } from "components/UI/ToastProvider";
import { formatDate } from "utils/formatDate";

export default function TeamList({ teams }) {
  const dispatch = useDispatch();
  const toast = useToast();
  //usestate
  const [confirmRemoval, setConfirmRemoval] = useState(false);
  const [currentDetails, setCurrentDetails] = useState(null);
  const [isRemovalLoading, setisRemovalLoading] = useState(false);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);

  function getInitials(name, email) {
    // Split the name into words

    const names = name ?? email;

    let words =
      names?.split(" ")?.length < 2 ? names?.split("") : names?.split(" ");

    // Initialize an empty string for the initials
    let initials = `${words?.[0]}${words?.[1]}`;

    // Return the initials in uppercase
    return initials.toUpperCase();
  }

  const items = [
    {
      key: "1",
      label: (
        <div className="flex gap-x-2">
          <img src={details} alt="create new folder" className="w-4" />
          <p>Details</p>
        </div>
      ),
      // onClick: (file) => {
      //   setPresentationDoc(file?.id);
      //   setIsModalOpen(true);
      // },
    },
    {
      key: "4",
      label: (
        <div
          className="flex gap-x-2"
          onClick={() => setIsFileUploadModalOpen(true)}
        >
          <img src={access} alt="access control" className="w-4" />
          <p>Access control</p>
        </div>
      ),
    },

    {
      key: "6",
      label: (
        <div
          className="flex text-red-600 gap-x-2"
          onClick={() => setConfirmRemoval(true)}
        >
          <img src={trash} alt="delete file" className="w-4 " />
          <p>Remove</p>
        </div>
      ),
    },
  ];

  const columns = [
    {
      header: "Fullname",
      field: "fullName",
      sortable: true,
      body: (rowData) => {
        const { fullName, email } = rowData;
        return (
          <section className="flex items-center gap-x-2">
            <div className="bg-[#1877F2] text-white text-xs rounded-full px-2 py-2">
              {getInitials(fullName, email)}
            </div>
            <p>{fullName ?? email}</p>
          </section>
        );
      },
    },

    {
      header: "Email",
      field: "email",
      sortable: true,
    },

    {
      header: "Role",
      field: "role",
      body: (rowData) => {
        return (
          <select
            defaultValue={rowData?.role}
            className="px-2 py-2 rounded-lg outline-none w-28"
          >
            <option value="owner">Owner</option>
            <option value="editor">Editor</option>
            <option value="viewer">Viewer</option>
          </select>
        );
      },
    },

    {
      header: "Status",
      field: "status",
      body: (rowData) => {
        return (
          <section>
            {rowData?.active && (
              <div className="w-20 px-2 py-2 font-semibold text-center rounded-lg bg-green200 text-green400 ">
                Active
              </div>
            )}

            {!rowData?.active && (
              <div className="w-20 px-2 py-2 font-semibold text-center text-yellow-600 bg-yellow-100 rounded-lg ">
                Inactive
              </div>
            )}
          </section>
        );
      },
    },

    {
      header: "Date Added",
      field: "dateAdded",
      body: (rowData) => {
        return <section>{formatDate(rowData?.dateAdded, "lll")}</section>;
      },
    },

    {
      header: "",
      body: (file) => {
        return (
          <Dropdown
            trigger={["click"]}
            dropdownRender={(menu) => (
              <Menu>
                {items.map((item) => (
                  <Menu.Item
                    key={item.key}
                    onClick={() => {
                      // item?.onClick(file);
                      setCurrentDetails(file);
                    }}
                  >
                    {item.label}
                  </Menu.Item>
                ))}
              </Menu>
            )}
          >
            <div className="cursor-pointer">
              <img src={dots} alt="options" className="w-7" />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  //functions
  const closeRemoveModal = () => {
    setConfirmRemoval(false);
  };
  const removeMember = () => {
    // TODO: Implement logic to remove member from team

    const data = {
      id: currentDetails.id,
      teamId: currentDetails.teamId,
    };
    setisRemovalLoading(true);
    dispatch(removeTeamMember(data)).then(({ type }) => {
      setisRemovalLoading(false);
      if (type.includes("fulfilled")) {
        setConfirmRemoval(false);
        successToast( "Member removed successfully");
        dispatch(getTeamMembers());
      }
    });
  };

  return (
    <section>
      <DataTable
        stripedRows
        value={teams}
        className="text-sm"
        selectionMode="checkbox"
        // selection={selectedStaff}
        tableStyle={{ minWidth: "50rem" }}
        // onSelectionChange={(e) => setSelectedStaff(e.value)}
      >
        {columns.map((col, index) => {
          return (
            <Column
              key={index}
              body={col?.body}
              field={col.field}
              header={col.header}
              sortable={col?.sortable}
            />
          );
        })}
      </DataTable>

      {/* Modal  */}
      <>
        <ConfirmationModal
          open={confirmRemoval}
          isLoading={isRemovalLoading}
          onClose={closeRemoveModal}
          func={removeMember}
          text="Are you sure you want to remove this member from your team?"
        />
      </>
      {/*  */}
    </section>
  );
}
