import React from "react";

export default function ToggleSwitch({ toggleState }) {
  return (
    <div className="custom-switch">
      <label className="switch">
        <input
          type="checkbox"
          onChange={toggleState}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
}
