import React, { useEffect, useState } from 'react';

//libraries
import { Modal } from 'antd';

//assets
import shape from 'assets/svg/3dshape.svg';
import cardInfo from 'assets/svg/cardInfo.svg';
import passLock from 'assets/svg/passLock.svg';
import whiteEdit from 'assets/svg/whiteEdit.svg';
import avatar from 'assets/svg/settingsPlan.svg';
import masterIcon from 'assets/svg/masterIcon.svg';
import cardOutline from 'assets/svg/cardOutline.svg';
import billHistory from 'assets/svg/bill-history.svg';

//component
import InvoiceTable from 'views/Admin/Settings/Tables/InvoiceTable';
import PlansModal from '../Team/PlansModal';
import {
  cancelSubscription,
  getMyPlans,
  getPaymentState,
  getPlanHistory,
  getPlans,
  getPointHistory,
} from 'store/Payment/paymentSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/UI/Loader';
import { formatNumber } from 'utils/formatter';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import PointHistoryTable from 'views/Admin/Settings/Tables/PointHistoryTable';
import ConfirmationModal from 'components/UI/Modal/ConfirmationModal';
import { successToast } from 'utils/toastUtils';
import { useToast } from 'components/UI/ToastProvider';
import { formatDate } from 'utils/formatDate';

export default function Billing() {
  const dispatch = useDispatch();
  const toast = useToast();

  //useselector
  const { myPlans, planHistory } = useSelector(getPaymentState);
  const { plan, planType, price, nextDue, status, active } = myPlans.data ?? {};

  //usestate
  const [tab, setTab] = useState(0);
  const [openPlanModal, setOpenPlanModal] = useState(false);

  const [confirmCancellation, setConfirmCancellation] = useState(false);
  const [isCancellationLoading, setIsCancellationLoading] = useState(false);

  //useeffects
  useEffect(() => {
    loadAPIS();
  }, []);

  //function
  const loadAPIS = () => {
    dispatch(getPlans());
    dispatch(getMyPlans());
    dispatch(getPlanHistory());
    dispatch(getPointHistory());
  };
  const closeConfirmCancellation = () => {
    setConfirmCancellation(false);
  };

  const closeModal = () => {
    setOpenPlanModal(false);
  };

  const stopSubscription = () => {
    setIsCancellationLoading(true);
    dispatch(cancelSubscription()).then(({ type }) => {
      setIsCancellationLoading(false);

      if (type.includes('fulfilled')) {
        closeConfirmCancellation();
        successToast('Subscription Cancelled Successfully');

        loadAPIS();
      }
    });
  };

  const bgImage = {
    background: `url(${cardOutline}) no-repeat -20% 10%`,
  };
  return (
    <section>
      {myPlans.isLoading && <Loader />}

      {!myPlans.isLoading && (
        <section>
          {/* banner  */}
          <section className='relative px-4 border-2 rounded-xl'>
            <img src={shape} alt='' className='absolute left-[40%] top-0' />

            <section className='flex justify-between py-4'>
              <section className='flex items-center gap-x-4'>
                <div>
                  <img
                    src={avatar}
                    alt='avatar'
                    className='w-[4.75rem] h-[4.75rem] rounded-full'
                  />
                </div>

                <div>
                  <h2 className='flex items-center text-2xl font-semibold'>
                    {plan} plan{' '}
                    <small
                      className={` text-xs rounded-full ml-2  capitalize font-semibold py-1 px-3 ${
                        active
                          ? 'bg-green-200 text-green-700'
                          : 'bg-yellow-200 text-yellow-600'
                      }`}
                    >
                      {active ? 'Active' : 'Inactive'}
                    </small>
                  </h2>

                  <section className='flex items-center mt-3 gap-x-10'>
                    <div className='flex items-center gap-x-1'>
                      <p>Plan type:</p>
                      <p className='font-semibold'> {planType}</p>
                    </div>

                    <div className='flex items-center gap-x-1'>
                      <p>Price:</p>
                      <p className='font-semibold'> ${formatNumber(price)}</p>
                    </div>

                    <div className='flex items-center gap-x-1'>
                      <p>Next billing:</p>
                      <p className='font-semibold'>
                        {' '}
                        {nextDue ? formatDate(nextDue, 'll') : '---'}
                      </p>
                    </div>
                  </section>
                </div>
              </section>

              <section className='flex items-end gap-x-2'>
                <button
                  onClick={() => setOpenPlanModal(true)}
                  className='flex items-center p-2 text-sm text-white bg-black rounded-lg gap-x-2'
                >
                  <img src={whiteEdit} alt='' className='w-5 h-5' />
                  <span>Change plan</span>
                </button>
                {active && (
                  <button
                    onClick={() => setConfirmCancellation(true)}
                    className='flex items-center p-2 text-sm text-white bg-red-600 rounded-lg gap-x-2'
                  >
                    <i className='text-xl text-white pi pi-power-off'></i>
                    <span>Cancel subscription</span>
                  </button>
                )}
              </section>
            </section>
          </section>
          {/*  */}

          {/* card information  */}
          {/* <section className="pb-8 my-8">
            <div className="flex self-start font-semibold gap-x-2">
              <img src={cardInfo} alt="" />
              <p>Card information</p>
            </div>

            <section className="w-full">
              <section
                style={bgImage}
                className=" relative mx-auto cardGradient px-4 py-2 w-[22rem] h-[11.875rem] rounded-xl"
              >
                <h4 className="pt-4 font-semibold">Stripe</h4>

                <div className="absolute w-[90%] text-sm font-semibold bottom-4 ">
                  <section className="flex justify-between ">
                    <div className="w-9/12">
                      <div className="flex justify-between">
                        <h4>JOHN DOE</h4>

                        <h4>06/24</h4>
                      </div>
                      <p className="text-lg">**** **** **** 1234</p>
                    </div>

                    <div>
                      <img src={masterIcon} alt="" />
                    </div>
                  </section>
                </div>
              </section>
            </section>
          </section> */}
          {/*  */}

          {/* order history  */}

          <section className='py-10 my-8 border-t '>
            <section className='flex justify-between'>
              <section className='w-[30%]'>
                <section className='flex flex-col gap-y-4'>
                  <button
                    onClick={() => setTab(0)}
                    className={` ${
                      tab === 0 && 'bg-orange10'
                    } px-4 py-1 w-9/12 flex items-center rounded-lg gap-x-2 `}
                  >
                    <img src={billHistory} alt='' />
                    <p className='mt-1 font-semibold'>Billing history</p>
                  </button>
                  <button
                    onClick={() => setTab(1)}
                    className={` ${
                      tab === 1 && 'bg-orange10'
                    } px-4 py-1 w-9/12 flex items-center rounded-lg gap-x-2 `}
                  >
                    <img src={passLock} alt='' />
                    <p className='mt-1 font-semibold'>Order history</p>
                  </button>
                </section>
              </section>

              <section className='w-[65%]'>
                {tab === 1 && <InvoiceTable history={planHistory?.data} />}
                {tab === 0 && <PointHistoryTable history={planHistory?.data} />}
              </section>
            </section>
          </section>

          {/*  */}

          {/* point history  */}
          {/* <section className="py-10 my-8 border-t ">
            <section className="flex justify-between">
              <section className="w-[30%]">
                <div className="flex items-center gap-x-1">
                  <img src={passLock} alt="" />
                  <p className="mt-1 font-semibold">Point history</p>
                </div>
              </section>

              <section className="w-[65%]">
                <PointHistoryTable history={planHistory?.data} />
              </section>
            </section>
          </section> */}
          {/*  */}

          {/* Modal  */}

          <ConfirmationModal
            open={confirmCancellation}
            isLoading={isCancellationLoading}
            onClose={closeConfirmCancellation}
            func={stopSubscription}
            text='Are you sure you want to cancel your subscription?'
          />

          <Modal
            open={openPlanModal}
            footer={false}
            onCancel={closeModal}
            width='92%'
            className='plan-modal'
          >
            <PlansModal />
          </Modal>

          {/*  */}
        </section>
      )}
    </section>
  );
}
