import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//components
import Loader from "components/UI/Loader";
import CreateSpace from "./Modals/CreateSpace";
import BriefcaseList from "./Tables/BriefcaseList";
import AdminNavbar from "components/UI/AdminLayout/Navbar";

//assets
import addPlus from "assets/svg/add-circle.svg";
import emptyState from "assets/svg/emptyBriefcase.svg";

//libraries
import { Modal } from "antd";

//slices
import {
  getBriefcases,
  getBriefcaseState,
} from "store/Briefcase/briefcaseSlice";
import { getTeamInfo, getTeamsState } from "store/Teams/teamsSlice";

export default function MyBriefCase() {
  const dispatch = useDispatch();
  //useRef
  const createSpaceModal = useRef();

  //useselector
  const { teamInfo } = useSelector(getTeamsState);
  const { briefcases } = useSelector(getBriefcaseState);

  //usestates
  const [navProps, setNavProps] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  //useeffects
  useEffect(() => {
    dispatch(getTeamInfo());
  }, [dispatch]);

  useEffect(() => {
    const teamId = teamInfo.data?.teamId;
    teamId && dispatch(getBriefcases(teamId));
  }, [teamInfo, dispatch]);

  useEffect(() => {
    setNavProps({
      title: "Briefcase",
      subTitle: `Explore your presentations`,
      modalTitle: "Create new space",
      func: () => window.dispatchEvent(new CustomEvent("openCreateSpaceModal")),
    });
  }, []);

  //useeffects
  useEffect(() => {
    const handleEditEvent = () => {
      openCreateSpaceModal();
    };

    window.addEventListener("openCreateSpaceModal", handleEditEvent);

    return () => {
      window.removeEventListener("openCreateSpaceModal", handleEditEvent);
    };
  }, []);

  //function
  const openCreateSpaceModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    createSpaceModal.current?.resetModal();
  };

  return (
    <section className="relative">
      <AdminNavbar navProps={navProps} />

      <section className="mt-20">
        {/* loader */}
        {(briefcases.isLoading || teamInfo.isLoading) && <Loader />}
        {/*  */}

        {!briefcases.isLoading && (
          <section>
            {(briefcases.data?.length < 1 ||
              (!teamInfo.isLoading && !teamInfo.data)) && (
              <section className="flex flex-col  items-center justify-center lg:h-[60vh]">
                <div className="mb-4 center-content">
                  <img
                    src={emptyState}
                    alt="No briefcase"
                    className="center-content"
                  />
                </div>

                <div className="text-center">
                  <h1 className="text-xl font-semibold lg:text-2xl">
                    A briefcase for all your playlists
                  </h1>
                  <p className="text-sm">
                    Create and manage your presentation playlists here.
                  </p>
                  <div className="mt-2 center-content">
                    <button
                      onClick={openCreateSpaceModal}
                      className="flex items-center px-4 py-2 text-sm text-white bg-black rounded-lg gap-x-2 "
                    >
                      <img src={addPlus} alt="" className="w-5 h-5" />
                      <span>Create a briefcase</span>
                    </button>
                  </div>
                </div>

                <section></section>
              </section>
            )}

            {briefcases.data?.length > 0 && (
              <section>
                <BriefcaseList />
              </section>
            )}
          </section>
        )}
      </section>

      {/* Modal */}
      <Modal
        footer={null}
        centered={false}
        open={isModalOpen}
        onCancel={handleCancel}
        style={{
          top: 20,
        }}
      >
        <CreateSpace closeModal={handleCancel} ref={createSpaceModal} />
      </Modal>
    </section>
  );
}
