import React, { useEffect, useState } from "react";

//utils
import { imageStream } from "utils/imageStream";

//assets
import pdfImg from "assets/image/pdf.png";
import pptImg from "assets/image/ppt.png";
import excelImg from "assets/image/excel.png";
import folderImg from "assets/svg/folderImg.svg";

//libraries
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";

export default function FileThumbnail({ file, width, height }) {
  const dispatch = useDispatch();

  //usestate
  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    let isCancelled = false;
    if (!file) {
      setThumbnail(folderImg);
      return;
    }
    const fetchImageStream = async () => {
      setThumbnail(null);

      const url = await imageStream(file);
      if (!isCancelled) {
        setThumbnail(url);
      }
    };

    if (file) {
      if (file.endsWith(".ppt") || file.endsWith(".pptx")) {
        setThumbnail(pptImg);
      } else if (file.endsWith(".csv") || file.endsWith(".xlsx")) {
        setThumbnail(excelImg);
      } else if (file.endsWith(".pdf")) {
        setThumbnail(pdfImg);
      } else {
        fetchImageStream();
      }
    } else {
      setThumbnail(folderImg);
    }

    return () => {
      isCancelled = true;
    };
  }, [file, dispatch]);

  const style = {
    width: `${width ?? "36"}px`,
    height: `${height ?? "36"}px`,
    objectFit: "cover",
    objectPosition: "center",
  };
  return (
    <div className="flex items-center">
      {thumbnail ? (
        <img
          src={thumbnail}
          alt={file?.title}
          style={style}
          className={`rounded-lg `}
        />
      ) : (
        <div
          className={`w-[${width ?? "36"}px] h-[${
            height ?? "36"
          }px] rounded-lg cursor-pointer flex justify-center items-center`}
        >
          <ClipLoader size={12} color="gray" />
        </div>
      )}
    </div>
  );
}
