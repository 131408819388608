import { toast } from "react-toastify";
import { getSignalRConnection } from "store/SignalR/signalRSlice";

export const EndSignalRConnection = () => {
  let connection = getSignalRConnection();

  connection
    .stop()
    .then(() => {
      //   toast.success("Connection stopped successfully.");
    })
    .catch((err) => {
      toast.error("Error occurred while stopping SignalR connection:", err);
    });
};
