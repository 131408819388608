import Axios from "lib/axiosInterceptor";

export const _setThumbnail = (data) => {
  return Axios.post("/Presentation/SetThumbnail", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const _uploadPPT = (data, config) => {
  return Axios.post("/document/upload", data, {
    ...config,
    headers: {
      "Content-Type": "multipart/form-data",
      ...(config.headers || {}),
    },
  });
};

export const _getSlideStream = (id) => {
  return Axios({
    url: `/Document/stream/${id}`,
    method: "GET",
    responseType: "blob", // Get the response as a blob
    headers: {
      // Accept: "audio/*",
      Accept: "octet/stream",
      //  "image/jpeg",
    },
  }).catch((err) => {
    console.log(err, "stream error");
  });
};

export const _translateQuestion = (audio, data) => {
  const { presentationId, presentaConnectionId } = data ?? {};
  return Axios.post(
    `/Document/QuestionByAudio?presentationId=${presentationId}&presentaConnectionId=${presentaConnectionId}`,
    audio,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const _downloadSlide = (
  slideId,
  presentationId,
  presentaConnectionId
) => {
  return Axios({
    url: `/Document/download/${slideId}?presentationId=${presentationId}&presentaConnectionId=${presentaConnectionId}`,
    method: "GET",
    responseType: "blob",
    headers: {
      Accept: "application/octet-stream",
    },
  });
};
