import React, { useState } from "react";

//libraries
import { ClipLoader } from "react-spinners";

//invokers
import { AllowGuestIn } from "hooks/SignalR/Invokers/Presenter/AllowGuestIn";

//assets
import avatar from "assets/svg/avatar.svg";
import allow from "assets/svg/greenAllow.svg";
import decline from "assets/svg/redDecline.svg";

//components
import FlexContainer from "components/UI/StyleComponents/FlexContainer";
import { toast } from "react-toastify";

export default function RequestListCard({
  connection,
  presentationId,
  viewer,
  isPresentationActive,
}) {
  //usestate
  const [isGrantAccessLoading, setIsGrantAccessLoading] = useState(false);
  const [isDenyAccessLoading, setIsDenyAccessLoading] = useState(false);

  //invokers
  const allowViewer = (email, allowStatus) => {
    if (
      isPresentationActive === "started" ||
      isPresentationActive === "paused"
    ) {
      allowStatus
        ? setIsGrantAccessLoading(true)
        : setIsDenyAccessLoading(true);
      AllowGuestIn(
        connection,
        presentationId,
        email,
        allowStatus ? setIsGrantAccessLoading : setIsDenyAccessLoading,
        allowStatus
      );
    } else {
      toast("Resume presentation to perform action", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  return (
    <FlexContainer extraClass="py-3 border-b px-1">
      <div className="flex items-center gap-x-2">
        <div>
          <img
            src={avatar}
            // src={viewer?.img}
            alt={viewer?.name}
            className="w-8 rounded-full"
          />
        </div>

        <div>
          <h3 className="font-semibold ">{viewer?.name}</h3>
          <p className="text-xs text-gray60 ">{viewer?.email}</p>
        </div>
      </div>

      <section className="flex text-xs gap-x-2 ">
        <div
          onClick={() => allowViewer(viewer?.email, true)}
          className="flex items-center cursor-pointer gap-x-1 text-green600"
        >
          {isGrantAccessLoading ? (
            <ClipLoader color="green" size={12} />
          ) : (
            <img src={allow} alt="" />
          )}

          <p>Allow </p>
        </div>

        <div
          onClick={() => allowViewer(viewer?.email, false)}
          className="flex items-center text-red-600 cursor-pointer gap-x-1"
        >
          {isDenyAccessLoading ? (
            <ClipLoader color="red" size={12} />
          ) : (
            <img src={decline} alt="" />
          )}
          <p>Decline </p>
        </div>
      </section>
    </FlexContainer>
  );
}
