import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  _getTeamInfo,
  _getTeamMembers,
  _inviteTeam,
  _removeTeamMember,
} from "services/Team/teamServices";

const initialState = {
  status: "idle",
  teamInfo: {
    isLoading: false,
    data: null,
  },
  teamMembers: {
    isLoading: false,
    data: null,
  },
  sideImages: [],
  presentationUI: {
    activeSlide: null,
    activeImage: null,
  },
};
//get
export const getTeamInfo = createAsyncThunk("teams/getTeamInfo", async () => {
  const response = await _getTeamInfo();
  return response.data;
});

export const getTeamMembers = createAsyncThunk(
  "teams/getTeamMembers",
  async () => {
    const response = await _getTeamMembers();
    return response.data;
  }
);

//post
export const inviteTeam = createAsyncThunk("teams/inviteTeam", async (data) => {
  const response = await _inviteTeam(data);
  return response.data;
});
export const removeTeamMember = createAsyncThunk(
  "teams/removeTeamMember",
  async (data) => {
    const response = await _removeTeamMember(data);
    return response.data;
  }
);

export const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    clearteamMembers(state) {
      state.teamMembers.data = null;
    },

    setActiveSlide(state, { payload }) {
      state.presentationUI.activeSlide = payload;
    },

    setSideImages(state, { payload }) {
      state.sideImages = payload;
    },

    clearSideImages(state) {
      state.sideImages = [];
    },
  },
  extraReducers: (builder) => {
    //get team info
    builder
      .addCase(getTeamInfo.pending, (state) => {
        state.teamInfo.isLoading = true;
        state.teamInfo.data = null;
      })
      .addCase(getTeamInfo.fulfilled, (state, { payload }) => {
        state.teamInfo.isLoading = false;
        state.teamInfo.data = payload;
      })
      .addCase(getTeamInfo.rejected, (state, { payload }) => {
        state.teamInfo.isLoading = false;
      });

    //get team members
    builder
      .addCase(getTeamMembers.pending, (state) => {
        state.teamMembers.isLoading = true;
      })
      .addCase(getTeamMembers.fulfilled, (state, { payload }) => {
        state.teamMembers.isLoading = false;
        state.teamMembers.data = payload;
      })
      .addCase(getTeamMembers.rejected, (state, { payload }) => {
        state.teamMembers.isLoading = false;
      });
  },
});

export const getTeamsState = (state) => state.teams;
export const {
  clearteamMembers,
  setSideImages,
  clearSideImages,
  setActiveSlide,
} = teamsSlice.actions;
export default teamsSlice.reducer;
