import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

//utils
import { baseUrl } from "utils/baseUrl";
import { isPPTFile } from "utils/getFileType";
import { imageStream } from "utils/imageStream";
import { getConnectionId } from "utils/localStorageServices";

//libraries
import axios from "axios";
import moment from "moment-timezone";
// import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

//assets

//slices
import {
  connectSignalR,
  getConnectionState,
  getSignalRConnection,
  getSignalRState,
  setConnecting,
} from "store/SignalR/signalRSlice";
import {
  getPresentationDetails,
  getPresentationState,
} from "store/Presentations/presentationSlice";
import { getPollDetails, getPollState } from "store/Poll/pollSlice";
import Navbar from "components/Admin/ActivePresentation/LargeScreen/Navbar";

//SIGNALR
//invokers
import { MoveSlide } from "hooks/SignalR/Invokers/Presenter/MoveSlide";
import { JumpToSlide } from "hooks/SignalR/Invokers/Presenter/JumpToSlide";
import { JumpToNextSlide } from "hooks/SignalR/Invokers/Presenter/JumpToNextSlide";

//hooks
import { useSignalRConnectionStatus } from "hooks/SignalR/Listeners/Global/useSignalConnectionStatus";
import { usePresentationAccessGranted } from "hooks/SignalR/Listeners/Presenter/usePresentationAccessGranted";

//components
import Loader from "components/UI/Loader";
import BottomNavigation from "./BottomNavigation";
import PollQuestionTemplate from "components/Admin/ActivePresentation/LargeScreen/PollQuestionTemplate";
import { Toast } from "primereact/toast";
import { clearPolls, storePolls } from "utils/activePresentationUtils";
import { formatDate } from "utils/formatDate";

let connection = null;

export default function LargeScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  connection = getSignalRConnection();
  const { connectionStatus } = useSelector(getSignalRState);
  const { presentationId } = useParams();

  // usememo
  const documentSlideCache = useMemo(() => ({}), []);

  //hooks
  useSignalRConnectionStatus(connection);
  usePresentationAccessGranted(connection, presentationId);

  // useref
  const toast = useRef(null);
  const activeImgRef = useRef(null);

  //useselector
  const { pollDetails } = useSelector(getPollState);
  const { connectionId } = useSelector(getSignalRState);
  const { presentationDetails } = useSelector(getPresentationState);

  //usestate
  const [activeCount, setActiveCount] = useState(0);
  const [documentObj, setDocumentObj] = useState({});
  const [documentList, setDocumentList] = useState([]);
  const [activePlaylist, setActivePlaylist] = useState(null);
  const [nextStreamImage, setNextStreamImage] = useState(null);
  const [activeStreamImage, setActiveStreamImage] = useState(0);
  const [loadingFirstDocument, setLoadingFirstDocument] = useState(true);
  const [isPresentationActive, setIsPresentationActive] = useState("idle");

  //useeffect

  //begin signalR connection
  useEffect(() => {
    if (!connection || connectionStatus !== "connected") {
      dispatch(setConnecting());
      dispatch(connectSignalR());
    }
  }, [dispatch, connectionStatus]);

  // fetch presentation details
  useEffect(() => {
    if (presentationId) {
      dispatch(getPresentationDetails(presentationId));
      clearPolls();
    }
  }, [dispatch, presentationId]);

  //handles presentation details (stores document list, saves active document & caches document ids/keys)
  useEffect(() => {
    if (presentationDetails.data) {
      const { data } = presentationDetails ?? {};

      if (data) {
        const { doc } = data ?? {};

        if (doc) {
          let docs = [...doc];
          const sortedDocs = docs.sort((a, b) => {
            const first = Number(a?.position ?? 0);
            const second = Number(b?.position ?? 0);
            if (first < second) {
              return -1;
            } else if (first > second) {
              return 1;
            } else if (first === second) {
              return 0;
            }
            return 0;
          });

          if (docs?.length > 0) {
            const firstDocument = sortedDocs?.[0];
            const currentItem =
              presentationDetails?.data?.presentation?.currentItem;
            const getDetailsOfCurrentItem = sortedDocs?.find(
              (doc) => doc?.documentId === currentItem
            );

            if (getDetailsOfCurrentItem?.type?.toLowerCase() === "poll") {
              setActivePlaylist(firstDocument);
              setLoadingFirstDocument(false);
            } else {
              setActivePlaylist(currentItem ?? firstDocument?.documentId);
            }

            setNextStreamImage(null);
            setActiveCount(
              presentationDetails?.data?.presentation?.currentSlide ?? 0
            );
          }

          setDocumentList(sortedDocs);

          sortedDocs?.forEach((document) => {
            documentSlideCache[document.documentId] = [];
          });

          setDocumentObj(documentSlideCache);
        }
      }
    }
  }, [presentationDetails, documentSlideCache]);

  //THE ENGINE
  useEffect(() => {
    //convert object into an array of keys
    const documentKeys = Object.keys(documentObj);

    const storePoll = (pollId) => {
      if (pollId && documentSlideCache[pollId]?.length < 1) {
        dispatch(getPollDetails({ presentationId, pollId })).then(
          ({ type, payload }) => {
            if (type?.includes("fulfilled")) {
              storePolls(payload);
              const questionList = payload?.questions;

              documentSlideCache[pollId] = questionList;

              if (activePlaylist === pollId) {
                setLoadingFirstDocument(false);
                // console.log(documentSlideCache, "first load poll");
                // Mark first document slides as loaded
              }
            }
          }
        );
      }
    };

    const slideObj = async (docSlides, docId, slide, fileType, retries = 3) => {
      for (let attempt = 1; attempt <= retries; attempt++) {
        const id = slide?.id ?? slide;
        try {
          const slideExist = documentSlideCache[docId]?.some(
            (slide) => slide.slideId === id
          );
          if (!slideExist) {
            const url = await imageStream(id);

            if (url) {
              const slideObj = {
                slideId: id,
                imgUrl: url,
                title: slide?.title ?? "1",
                note: slide?.note,
                fileType,
              };

              documentSlideCache[docId].push(slideObj);
              documentSlideCache[docId].sort((a, b) => {
                const first = Number(a?.title.split("_")?.[1]);
                const second = Number(b?.title.split("_")?.[1]);
                if (first < second) {
                  return -1;
                } else if (first > second) {
                  return 1;
                }
                return 0;
              });

              // Check if this is the first document and if all slides are loaded
              if (
                activePlaylist === docId &&
                documentSlideCache[docId].length === (docSlides.length ?? 1)
              ) {
                setLoadingFirstDocument(false); // Mark first document slides as loaded

                // console.log(documentSlideCache, "first load slide");
              }
            }
            break; //Exit when successful
          } else {
            break; //exit iteration and move to the next
          }
        } catch (err) {
          if (attempt === retries) {
            console.error(
              `Error fetching image stream for slide ID: ${id} after ${retries} attempts`,
              err
            );
          } else {
            console.warn(
              `Retrying image stream for slide ID: ${id}, attempt ${attempt}`
            );
          }
        }
      }
    };

    const getDocumentDetails = async (docId, retries = 3) => {
      //this retries a broken request 3 times
      for (let attempt = 1; attempt <= retries; attempt++) {
        try {
          const documentType = documentList
            ?.find((doc) => doc?.documentId === docId)
            ?.type?.toLowerCase();

          if (documentType === "poll") {
            storePoll(docId);
            break;
            // Exit the loop if successful
          } else {
            //fetch document details
            const documentDetails = await axios.get(
              `${baseUrl}/Document/${docId}`
            );

            // assign slides
            const docSlides = documentDetails?.data?.slides;
            const fileType = documentDetails?.data?.url?.replace(".", "");

            if (docSlides?.length > 0) {
              //function to get stream of each slide
              await Promise.all(
                docSlides.map(async (slide) => {
                  await slideObj(docSlides, docId, slide, fileType);
                })
              );
            } else {
              // usecase for when it's a different MIME type (image, video)
              await slideObj(1, docId, documentDetails?.data?.id);
            }
            break;
            // Exit the loop if successful
          }
        } catch (err) {
          if (attempt === retries) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Error occured while fetching slides",
            });
            console.error(
              `Error fetching document details for document ID: ${docId} after ${retries} attempts`,
              err
            );
          } else {
            console.warn(
              `Retrying document details for document ID: ${docId}, attempt ${attempt}`
            );
          }
        }
      }
    };

    const fetchDocumentList = async () => {
      try {
        const docId = activePlaylist?.documentId ?? activePlaylist;

        await getDocumentDetails(docId);
        let remainingKeys = documentKeys.filter((key) => key !== docId);

        await Promise.all(
          remainingKeys.map(
            (key) =>
              documentSlideCache[key]?.length < 1 && getDocumentDetails(key)
          )
        );

        // console.log(documentSlideCache, "check2");
      } catch (err) {
        console.error("Error in fetching document list", err);
      }
    };

    if (documentKeys?.length > 0) {
      fetchDocumentList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentObj, documentSlideCache, dispatch, presentationId]); //here last 2

  //renders active image and next image

  useEffect(() => {
    if (!loadingFirstDocument) {
      const id = activeCount === -1 ? 0 : activeCount;

      const imgUrl = documentSlideCache?.[activePlaylist]?.[id];

      setActiveStreamImage(imgUrl);

      const nextImage = documentSlideCache?.[activePlaylist]?.[id + 1]?.imgUrl;
      setNextStreamImage(nextImage ?? null);
    }
  }, [
    activeCount,
    loadingFirstDocument,
    activePlaylist,
    documentSlideCache,
    pollDetails,
  ]); //here 1

  /////

  // UI useffects

  useEffect(() => {
    const handleEventListener = (e) => {
      if (connection) {
        if (e.key === "ArrowRight") {
          navigateSlides("next");
        }

        if (e.key === "ArrowLeft") {
          navigateSlides("prev");
        }
      }
    };

    window.addEventListener("keydown", handleEventListener);

    return () => {
      window.removeEventListener("keydown", handleEventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCount]);

  //function

  //function
  const enterFullscreen = () => {
    const elem = activeImgRef.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      elem.webkitRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  };

  const handleFullscreen = () => {
    if (!document.fullscreenElement && !activeStreamImage?.question) {
      enterFullscreen();
    } else {
      exitFullscreen();
    }
  };

  // navigation functions
  const navigatePlaylist = (doc) => {
    const { documentId } = doc ?? {};

    //get first and second slide in document
    const isPoll = documentSlideCache?.[documentId]?.[0]?.questionType; //don't show polls during navigation
    const secondSlide = documentSlideCache[documentId]?.[1] ?? null;

    setNextStreamImage(secondSlide);
    setActivePlaylist(documentId);
    setActiveCount(0);

    if (isPresentationActive === "started") {
      if (connectionStatus === "connected") {
        !isPoll && JumpToNextSlide(connection, presentationId, documentId, 0);
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Staus",
          detail: "Connecting...",
        });
      }
    } else {
      setActiveCount(0);
    }
  };
  const jumpToNext = () => {
    const nextSlide = activeCount + 1;
    const documentId = activePlaylist;
    const isPoll = documentSlideCache?.[documentId]?.[0]?.questionType; //don't show polls during navigation

    if (isPresentationActive === "started") {
      if (isPoll) {
        setActiveCount(nextSlide);
      } else {
        JumpToNextSlide(connection, presentationId, documentId, nextSlide);
      }
    } else {
      setActiveCount(nextSlide);
    }
  };

  const jumpTo = (index) => {
    const documentId = activePlaylist?.documentId ?? activePlaylist;
    const isPoll = documentSlideCache?.[documentId]?.[0]?.questionType; //don't show polls during navigation

    if (isPresentationActive === "started") {
      if (isPoll) {
        setActiveCount(index);
      } else {
        JumpToSlide(connection, presentationId, documentId, index);
      }
    } else {
      setActiveCount(index);
    }
  };

  const navigateSlides = (type) => {
    const slides = documentSlideCache?.[activePlaylist];

    const slideNum = type === "next" ? activeCount + 1 : activeCount - 1;
    const isPoll = slides?.[0]?.questionType; //don't show polls during navigation
    // console.log(
    //   type,
    //   activeCount,
    //   slides,
    //   presentationId,
    //   activePlaylist,
    //   "jkk"
    // );
    if (slides?.length > 1) {
      if (isPresentationActive === "started") {
        if (isPoll) {
          setActiveCount(slideNum);
        } else {
          MoveSlide(
            type,
            connection,
            activeCount,
            slides,
            presentationId,
            activePlaylist
          );
        }
      } else {
        slideNum < documentSlideCache[activePlaylist]?.length &&
          setActiveCount(slideNum);
      }
    }
  };

  //signalR

  //event triggers
  console.log(Intl.DateTimeFormat().resolvedOptions().timeZone, "time zone");

  const beginPresentation = () => {
    if (isPresentationActive !== "started") {
      setIsPresentationActive("loading");
      connection
        .invoke("Attend", presentationId, 0, getConnectionId())
        .then(() => {
          const presentationStartTime = formatDate(
            presentationDetails?.data?.presentation?.startTime
          );
          const presentationEndTime = formatDate(
            presentationDetails?.data?.presentation?.endTime
          );

          //start timer
          if (isPresentationActive === "idle") {
            if (
              presentationStartTime < presentationEndTime ||
              !presentationStartTime
            ) {
              const startTime = Date.now();
              localStorage.setItem("startTime", JSON.stringify(startTime));
              localStorage.setItem("isActive", JSON.stringify(true));
            } else {
              const dateObject = new Date(presentationStartTime);

              const milliseconds = dateObject.getTime();
              presentationStartTime &&
                localStorage.setItem("startTime", milliseconds);
            }

            window.dispatchEvent(new Event("timer"));
          }

          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Presentation has begun",
          });
        });
    }
  };

  const endPresentation = () => {
    setIsPresentationActive("loading");
    connection.invoke("EndPresentation", presentationId).then(() => {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Presentation ended successfully ",
      });
      dispatch(getPresentationDetails(presentationId));
    });
    navigate(`/admin/presentation-metric/${presentationId}`);
  };

  //event listeners

  useEffect(() => {
    if (connection) {
      connection.on("Attend");
      connection.on("MoveSilde");
      connection.on("EndPresentation");

      // all event listeners
      connection.on(
        "EventResponse",
        (id, eventName, status, message, presenterConnectionId) => {
          eventName = eventName?.toLowerCase();
          //attend response
          if (eventName === "attend") {
            if (status) {
              setIsPresentationActive("started");
            } else if (!status) {
              setIsPresentationActive("ended");
            }
          }

          //moveSlide response
          if (eventName === "moveslide") {
            const msgSplit = message.split(" ");

            if (msgSplit?.length > 1) {
              setActiveCount(Number(msgSplit[3]));
            }
          }

          //endPresentation response
          if (eventName === "endpresentation") {
            if (status) {
              setIsPresentationActive("ended");
            } else if (!status) {
              setIsPresentationActive("started");
            }
          }
        }
      );

      //
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  return (
    <section>
      {/* nav */}
      <Toast ref={toast} className="text-sm" position="top-center" />
      <Navbar
        toast={toast}
        connection={connection}
        presentationId={presentationId}
        endPresentation={endPresentation}
        beginPresentation={beginPresentation}
        isPresentationActive={isPresentationActive}
        setIsPresentationActive={setIsPresentationActive}
      />

      {/* main */}
      <section className=" mt-[5rem] overflow-hidden  ">
        {/* loader */}
        {(presentationDetails?.isLoading || loadingFirstDocument) && <Loader />}
        {/*  */}

        {!presentationDetails?.isLoading && !loadingFirstDocument && (
          <section className="w-[95%] mx-auto">
            <section className="w-full">
              <section className="flex items-center justify-between gap-x-4">
                {/* main image */}
                <section
                  onClick={() =>
                    !activeStreamImage?.question && handleFullscreen
                  }
                  className="mx-auto   w-[74%]"
                >
                  <div
                    className={`px-4 pt-4 pb-6 ${
                      !activeStreamImage?.question && "bg-gray100"
                    }  rounded-xl [  lg:max-h-[38rem]  vl:max-h-[40rem] vvl:max-h-[46rem] ] `}
                  >
                    {activeStreamImage ? (
                      <>
                        {!activeStreamImage?.question && (
                          <img
                            ref={activeImgRef}
                            alt="presentation slide"
                            src={activeStreamImage?.imgUrl}
                            className={` rounded-lg cursor-pointer mx-auto  [  lg:h-[32rem]  vl:h-[34rem] vvl:h-[40rem] ] ${
                              !isPPTFile(activeStreamImage?.fileType)
                                ? "object-contain"
                                : "w-full"
                            }   `}
                          />
                        )}

                        {activeStreamImage?.question && (
                          <PollQuestionTemplate
                            activeCount={activeCount}
                            details={activeStreamImage}
                            pollId={activePlaylist}
                            connection={connection}
                          />
                        )}
                      </>
                    ) : (
                      <div
                        className={`flex items-center justify-center rounded-lg cursor-pointer mx-auto  [  lg:w-[950px]  vl:w-[1130px] vvl:w-[1300px] ]   [  lg:h-[33rem]  vl:h-[38rem] vvl:h-[45rem] ] object-contain  `}
                      >
                        <ClipLoader size={12} color="gray" />
                      </div>
                    )}

                    {/* */}
                  </div>
                </section>

                {/*  */}

                {/* next image */}

                {nextStreamImage && (
                  <section className="w-[25%] ">
                    <section className="" onClick={jumpToNext}>
                      <div className="rounded-2xl bg-orange40 [ lg:mr-[-4rem]   ]">
                        <img
                          src={nextStreamImage}
                          alt="presentation slide"
                          className={`cursor-pointer rounded-2xl p-2 [  lg:h-[16rem]  vl:h-[17rem] vvl:h-[23rem] ] w-full `}
                        />
                      </div>

                      <div className="w-full py-2 text-sm font-medium text-center text-gray40">
                        {" "}
                        <p>Slide {activeCount + 2}</p>
                      </div>
                    </section>
                  </section>
                )}

                {/*  */}
              </section>

              {activeStreamImage?.note && (
                <section
                  className={` my-4  ${
                    !nextStreamImage ? "mx-auto w-[75%]" : "w-full"
                  }    `}
                >
                  <div className="bg-[#EFEDE3] text-black rounded-2xl h-[80px] text-left p-4 font-medium ">
                    {activeStreamImage?.note}
                  </div>
                </section>
              )}
            </section>

            {/*  */}

            {/* bottom navigation  */}
            <BottomNavigation
              jumpTo={jumpTo}
              activeCount={activeCount}
              activeImgRef={activeImgRef}
              documentList={documentList}
              navigateSlides={navigateSlides}
              activePlaylist={activePlaylist}
              navigatePlaylist={navigatePlaylist}
              nextStreamImage={nextStreamImage}
              documentSlideCache={documentSlideCache}
            />

            {/*  */}
          </section>
        )}
      </section>

      {/*  */}
    </section>
  );
}
