import Axios from "lib/axiosInterceptor";

//get
export const _getBriefcases = (teamId) => {
  return Axios.get(`/Spaces?teamId=${teamId}`);
};

//post
export const _createBriefcase = (data) => {
  return Axios.post("/Spaces/Create", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const _updateBriefcase = (data) => {
  return Axios.post("/Spaces/Update", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
