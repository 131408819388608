import { useEffect } from "react";

export const useSignalREventListener = (connection, eventHandler) => {
  useEffect(() => {
    if (connection) {
      connection.on("EventResponse", eventHandler);
    }
    return () => {
      if (connection) {
        connection.off("EventResponse", eventHandler);
      }
    };
  }, [connection, eventHandler]);
};
