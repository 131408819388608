import React from "react";

//components
import LargeScreen from "./Views/largeScreen";
import MobileView from "./Views/mobile";

export default function ActivePresentation() {
  return (
    <section>

<LargeScreen />
      {/* <section className="hidden lg:block">
        <LargeScreen />
      </section>

      <section className="block lg:hidden">
        <MobileView />
      </section> */}
    </section>
  );
}
