import React, { useState, useEffect } from 'react';

const Timer = () => {
  const [seconds, setSeconds] = useState(() => {
    const savedStartTime = localStorage.getItem('startTime');

    if (savedStartTime) {
      const startTime = JSON.parse(savedStartTime);
      return Math.floor((Date.now() - startTime) / 1000);
    }
    return 0;
  });

  const [isActive, setIsActive] = useState(() => {
    const savedIsActive = localStorage.getItem('isActive');
    return savedIsActive ? JSON.parse(savedIsActive) : false;
  });

  useEffect(() => {
    // do we need this second useEffect?

    const handleStorageChange = () => {
      beginTimer();
    };

    window.addEventListener('timer', handleStorageChange);

    return () => {
      window.removeEventListener('timer', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        const savedStartTime = localStorage.getItem('startTime');
        if (savedStartTime) {
          const startTime = JSON.parse(savedStartTime);
          setSeconds(Math.floor((Date.now() - startTime) / 1000));
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive]);

  const beginTimer = () => {
    // const isActive = localStorage.getItem('isActive');
    const isActive = JSON.parse(localStorage.getItem('isActive'));
    const savedStartTime = localStorage.getItem('startTime');
    if (savedStartTime && isActive) {
      const startTime = JSON.parse(savedStartTime);
      setSeconds(Math.floor((Date.now() - startTime) / 1000));
      setIsActive(isActive);
    }
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    }`;
  };

  return (
    <div>
      <h1 className='text-sm font-semibold'>{formatTime(seconds)}</h1>
    </div>
  );
};

export default Timer;