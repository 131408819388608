import React, { useState } from "react";

//assets
import avatar from "assets/svg/avatar.svg";
import remove from "assets/svg/remove.svg";

//libraries
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

//components
import FlexContainer from "components/UI/StyleComponents/FlexContainer";
import { RemoveGuest } from "hooks/SignalR/Invokers/Presenter/RemoveGuest";
import { GetUsersList } from "hooks/SignalR/Invokers/Presenter/GetUsersList";
import { useSignalREventListener } from "hooks/SignalR/Listeners/Global/useSignalRListener";
export default function ViewerCard({
  viewer,
  connection,
  presentationId,
  isPresentationActive,
}) {
  const { name, email } = viewer ?? {};

  //usestate
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState("");

  //   hook
  useSignalREventListener(
    connection,
    (id, eventName, status, message, presenterConnectionId) => {
      const event = eventName?.toLowerCase();
      const msg = message?.toLowerCase();
      console.log(event, msg, "eventname");
      setIsLoading(false);
      if (event === "removeuser" && "user has been removed") {
        if (selectedEmail === email) {
          toast.success(`${selectedEmail} removed successfully`);
          GetUsersList(connection, presentationId);
        }
      }
    }
  );

  //invokers
  const removeViewer = (email) => {
    if (
      isPresentationActive === "started" ||
      isPresentationActive === "paused"
    ) {
      setIsLoading(true);
      setSelectedEmail(email);
      RemoveGuest(connection, presentationId, email, true);
    } else {
      toast("Resume presentation to perform action", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  return (
    <div>
      <FlexContainer extraClass="py-3 border-b px-2">
        <div className="flex items-center gap-x-2">
          <div>
            <img src={avatar} alt={name} className="w-8 rounded-full" />
          </div>

          <div>
            <h3 className="font-semibold ">{name}</h3>
            <p className="text-xs text-gray60 ">{email}</p>
          </div>
        </div>

        <div
          onClick={() => removeViewer(email)}
          className="flex items-center text-xs font-semibold cursor-pointer gap-x-1 text-gray40"
        >
          {isLoading ? (
            <ClipLoader color="gray" size={12} />
          ) : (
            <img src={remove} alt="remove viewer" />
          )}
          <p>Remove</p>
        </div>
      </FlexContainer>
    </div>
  );
}
