import React from 'react';

//assets
import show from 'assets/svg/showPassword.svg';
import UsePasswordToggle from 'hooks/UsePasswordToggle';
import eyeSlash from 'assets/svg/eye-slash.svg';

export default function AuthInput({
  name,
  type,
  onChange,
  customRef,
  isPassword,
  placeholder,
  defaultValue,
  className,
  disabled,
}) {
  //hook
  const { showPassword, togglePassword } = UsePasswordToggle();

  let inputType;

  if (isPassword && showPassword) {
    inputType = 'text';
  }

  if (isPassword && !showPassword) {
    inputType = 'password';
  }

  if (!isPassword) {
    inputType = type;
  }

  return (
    <div className='mt-2 rounded-md text-gray700 bg-orange10 relative overflow-hidden'>
      <input
        ref={customRef}
        name={name}
        type={inputType}
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={` ${className} ${
          isPassword ? 'pr-[50px]' : 'pr-5'
        }  w-full outline-none bg-inherit border-none placeholder:text-sm py-3 pl-5 text-sm`}
        onChange={onChange}
      />

      {isPassword && (
        <div className='cursor-pointer w-[20px] bg-none absolute top-[50%] -translate-y-[50%] right-5'>
          <img
            src={showPassword ? eyeSlash : show}
            alt='toggle password'
            onClick={togglePassword}
            className='cursor-pointer'
          />
        </div>
      )}
    </div>
  );
}
