import Axios from "lib/axiosInterceptor";

//get
export const _getAllWorkspace = () => {
  return Axios.get(`/Document/Workspaces`);
};

export const _getWorkspaceFolders = (workspaceId) => {
  return Axios.get(`/Document/Folder?workspaceId=${workspaceId}`);
};

export const _getFolderFiles = (id) => {
  return Axios.get(`/Document/Folder?id=${id}`);
};
export const _getSubfolderFiles = (id) => {
  return Axios.get(`/Document/Folder?id=${id}`);
};

export const _getDocumentFiles = (id) => {
  return Axios.get(`/Document/${id}`);
};

//post
export const _createFolder = ({ data, config }) => {
  return Axios.post(`/Document/Folder`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...(config.headers || {}),
    },
  });
};

//put
export const _updateFolder = (folderId, newTitle) => {
  return Axios.post(`/Document/Folder/Rename/${folderId}?newTitle=${newTitle}`);
};

//delete
export const _deleteFolder = (folderId) => {
  return Axios.post(`/Document/Folder/Delete/${folderId}`);
};

export const _archiveFolder = (folderId) => {
  return Axios.post(`/Document/Folder/Delete/${folderId}?archive=true`);
};
