import React, { useEffect, useMemo, useState } from "react";

//utils
import {
  getPollStatus,
  getWordCloudAnswers,
  isWordCloudCheck,
  storeWorldCloudAnswer,
} from "utils/activePresentationUtils";

//assets
import timerImg from "assets/svg/timer2.svg";

//libraries
import ReactWordcloud from "react-wordcloud-fork";

//slices
import { getSignalRConnection } from "store/SignalR/signalRSlice";

//hooks
import { useGenericListener } from "hooks/SignalR/Listeners/Global/UseGenericListener";

let connection = null;
export default function ActiveWordCloud({
  timer,
  questionDetails,
  pollId,
  endPoll,
  isPollFinished,
}) {
  const { duration, question } = questionDetails ?? {};
  connection = getSignalRConnection();
  const [words, setWords] = useState([]);

  useEffect(() => {
    const wordList =
      isPollFinished ?? getWordCloudAnswers(questionDetails?.id, pollId);
    setWords(wordList);
  }, [questionDetails, pollId, isPollFinished]);

  //useeffect
  useEffect(() => {
    const handleEditEvent = (event) => {
      const { wordList } = event?.wordList ?? {};

      if (wordList) {
        setWords(wordList);
      }
    };

    window.addEventListener("wordCloudUpdated", handleEditEvent);

    return () => {
      window.removeEventListener("wordCloudUpdated", handleEditEvent);
    };
  }, []);

  ////// HANDLING POLLS

  //hook

  useGenericListener(connection, (presentationId, eventName, obj) => {
    const event = eventName?.toLowerCase();

    if (event === "viewerpollanswer") {
      const { answer, questionId, questionType } = obj ?? {};

      if (isWordCloudCheck(questionType)) {
        const wordList = storeWorldCloudAnswer(answer, questionId);
        setWords(wordList);
      }
    }
  });

  ///////////
  const options = useMemo(
    () => ({
      rotations: 2,
      fontSizes: [12, 60],
      rotationAngles: [-90, 0],
    }),
    []
  );

  const memoizedWords = useMemo(() => words, [words]);

  return (
    <section className="mx-auto max-w-[80%]  h-[70vh] flex items-center justify-center  bg-gray100 ">
      <section className="my-6 rounded-xl">
        <h3 className="flex items-start self-start justify-center pt-2 text-3xl font-semibold text-center text-gray40">
          {question}
        </h3>

        {memoizedWords?.length < 1 && getPollStatus() && (
          <div className="w-full h-full ">
            <p className="text-2xl font-semibold text-center">
              Waiting for response...
            </p>
          </div>
        )}

        {memoizedWords?.length > 0 && (
          <section className="h-[50vh]flex items-center justify-center">
            <ReactWordcloud words={words} options={options} />
          </section>
        )}

        <section className="flex flex-col justify-center ">
          <div className="flex justify-center my-4 font-bold gap-x-1 text-gray40">
            <img src={timerImg} alt="" />
            <p className="mt-[2px]">{isPollFinished ? duration : timer} Secs</p>
          </div>
          {!isPollFinished && (
            <button
              onClick={endPoll}
              className="py-2 text-white bg-black rounded-lg w-[40%] mx-auto"
            >
              End poll
            </button>
          )}
        </section>
      </section>
    </section>
  );
}
