import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSignalRState } from "store/SignalR/signalRSlice";

export default function SignalRStatusBar() {
  const { connectionStatus } = useSelector(getSignalRState);

  const [status, setStatus] = useState("connecting");

  useEffect(() => {
    setStatus(connectionStatus);
  }, [connectionStatus]);

  return (
    <section>
      <section
        className={`flex items-center text-[13px] font-semibold  capitalize gap-x-1
            ${status === "connected" && " text-green-600"}
              ${
                (status === "reconnecting" || status === "connecting") &&
                " text-yellow-600"
              } 
             
              ${status === "disconnected" && " text-yellow-700"} 
            `}
      >
        <p> {status}</p>
        {status === "connected" && (
          <div className="flex items-center justify-center w-4 h-4 bg-green-600 rounded-full">
            <i
              className="text-white pi pi-check "
              style={{ fontSize: "7px" }}
            ></i>
          </div>
        )}

        {(status === "reconnecting" || status === "connecting") && (
          <i
            className="text-yellow-600 pi pi-spin pi-spinner"
            style={{ fontSize: "12px" }}
          ></i>
        )}

        {status === "disconnected" && (
          <div className="flex items-center justify-center w-4 h-4 text-white bg-red-600 rounded-full">
            <p>!</p>
          </div>
        )}
      </section>
    </section>
  );
}
