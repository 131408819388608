import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as signalR from "@microsoft/signalr";
import { MessagePackHubProtocol } from "@microsoft/signalr-protocol-msgpack";
import { baseUrl } from "utils/baseUrl";
import { getToken } from "utils/localStorageServices";
import { toast } from "react-toastify";

let connection = null; // Hold the connection object outside the Redux store

const initialState = {
  connectionId: null,
  isConnected: false,
  connectionStatus: "connecting",
};

export const connectSignalR = createAsyncThunk("signalR/connect", async () => {
  connection = new signalR.HubConnectionBuilder()
    .withUrl(`${baseUrl}/pubsub/present`, {
      accessTokenFactory: () => {
        return `${getToken()}`;
      },
    })
    // .withHubProtocol(new MessagePackHubProtocol())
    .withAutomaticReconnect()
    .build();

  await connection.start();
  return connection.connectionId;
});

export const signalRSlice = createSlice({
  name: "signalR",
  initialState,
  reducers: {
    setReconnecting: (state) => {
      state.connectionStatus = "reconnecting";
    },
    setReconnected: (state) => {
      state.connectionStatus = "connected";
      state.error = null;
    },
    setConnecting: (state) => {
      state.connectionStatus = "connecting";
      state.error = null;
    },

    setDisconnected: (state, action) => {
      state.connectionStatus = "disconnected";
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(connectSignalR.fulfilled, (state, { payload }) => {
      state.connectionId = payload;
      state.isConnected = true;
      state.connectionStatus = "connected";
      // toast.success("SignalR connected");
    });
  },
});

export const getSignalRState = (state) => state.signalR;

export const { setReconnecting, setReconnected, setDisconnected, setConnecting } =
  signalRSlice.actions;

export const getSignalRConnection = () => connection;
export const getConnectionState = () => connection?.state;
export default signalRSlice.reducer;
