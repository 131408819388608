import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//libraries
import { Chart } from 'primereact/chart';
import { Dropdown, Menu, Modal, Tooltip } from "antd";

//assets
import team from 'assets/svg/team.svg';
import topup from 'assets/svg/topup.svg';
import avatar from 'assets/svg/avatar.svg';

import blacky from 'assets/svg/blacky.svg';
import logo from 'assets/svg/fullLogo.svg';
import folder from 'assets/svg/folder.svg';
// import support from "assets/svg/support.svg";
import settings from 'assets/svg/settings.svg';
import overview from 'assets/svg/overview.svg';
import inactiveBriefcase from 'assets/svg/presentations.svg';
import inactiveDirectory from 'assets/svg/directory.svg';
import inactiveSettings from 'assets/svg/inactiveSettings.svg';
import inactiveWorkspace from 'assets/svg/inactiveWorkspace.svg';
import activeBreifcase from 'assets/svg/activePresentation.svg';
import activePresentation from 'assets/svg/presention-chart.svg';

//component
import FlexContainer from '../../StyleComponents/FlexContainer';

//slices
import { closeSidebar } from 'store/UI';
import { getUserDetails } from 'utils/localStorageServices';
import NavLink from './NavLink';
import TopupModal from './TopupModal';
import { formatNumber, trimText } from 'utils/formatter';
import { getPaymentState, getPointBalance } from 'store/Payment/paymentSlice';
import { ClipLoader } from 'react-spinners';
import { getProfilePic, getSettingsState } from 'store/Settings/settingSlice';
import ToggleDirectoryView from 'components/Admin/Directory/ToggleDirectoryView';

export default function Sidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const topUpModalRef = useRef();

  //useselector
  const { pointBalance } = useSelector(getPaymentState);
  const { profilePic } = useSelector(getSettingsState);

  //usestate
  const [chartData, setChartData] = useState({});
  const [profileImg, setProfileImg] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [chartOptions, setChartOptions] = useState({});
  const [openTopupModal, setOpenTopupModal] = useState(false);

  //useeffects
  useEffect(() => {
    const profile = profilePic.data;

    profile ? setProfileImg(profilePic.data) : setProfileImg(avatar);
  }, [profilePic]);

  useEffect(() => {
    dispatch(getPointBalance());
    dispatch(getProfilePic());
  }, [dispatch]);

  useEffect(() => {
    setUserDetails(getUserDetails());

    const data = {
      datasets: [
        {
          data: [30, 100],
          backgroundColor: ['#ffffff', '#FF6929'],
          // hoverBackgroundColor: ["#ffffff80", "#FF692980"],
        },
      ],
    };
    const options = {
      cutout: '80%',
      animation: false,
    };

    setChartData(data);
    setChartOptions(options);
  }, [dispatch]);

  //variables
  const navItems = [
    // {
    //   icon: overview,
    //   inactiveIcon: inactiveOverview,
    //   name: "Overview",
    //   link: "/admin/dashboard",
    // },

    {
      icon: activePresentation,
      inactiveIcon: activePresentation,
      name: 'My Presentations',
      link: '/admin/mypresentations',
      otherLinks: ['/admin/presentation-metric'],
    },

    // {
    //   icon: activeBreifcase,
    //   inactiveIcon: inactiveBriefcase,
    //   name: 'Briefcase',
    //   link: '/admin/mybriefcase',
    // },

    {
      icon: folder,
      inactiveIcon: inactiveWorkspace,
      name: 'Workspace',
      link: '/admin/workspace',
    },

    // {
    //   icon: inactiveDirectory,
    //   inactiveIcon: inactiveDirectory,
    //   name: "Directory",
    //   link: "/admin/directory",
    // },
  ];

  const navItems2 = [
    {
      icon: team,
      inactiveIcon: team,
      name: 'Team',
      link: '/admin/team',
    },
    // {
    //   icon: support,
    //   inactiveIcon: support,
    //   name: "Support",
    //   link: "/admin/support",
    // },

    {
      icon: settings,
      inactiveIcon: inactiveSettings,
      name: 'Settings & more',
      link: '/admin/settings',
    },
  ];

  const style = {
    backgroundImage: `url(${blacky})`,
  };

  //functions
  const closeTopupModal = () => {
    setOpenTopupModal(false);
    topUpModalRef.current.resetModal();
  };

  const logoutFunc = () => {
    localStorage.clear();
    navigate('/login');
  };

  return (
    <section className='h-screen bg-orange20 '>
      <section className='px-4 py-4 '>
        {/* logo */}
        <FlexContainer>
          <img src={logo} alt='Presenta Logo' className='w-24' />

          <div className='block lg:hidden'>
            <i
              className='pi pi-times'
              style={{ fontSize: '1.4rem' }}
              onClick={() => dispatch(closeSidebar())}
            ></i>
          </div>
        </FlexContainer>

        <section className="mt-6">
          <section className="mb-2">
            <section className="flex items-center justify-between px-4 py-3 text-sm font-semibold border rounded-lg border-gray10 bg-orange10">
              <div className="flex items-center gap-x-1">
                <p>
                  {String(pointBalance?.data?.balance ?? "").length > 7 ? (
                    <Tooltip
                      title={
                        pointBalance?.data?.balance.toLocaleString() +
                          " Points" ?? ""
                      }
                    >
                      {Intl.NumberFormat("en", { notation: "compact" }).format(
                        pointBalance?.data?.balance
                      )}
                    </Tooltip>
                  ) : (
                    formatNumber(pointBalance?.data?.balance)
                  )}{" "}
                  point(s)
                </p>
                {pointBalance?.isLoading && (
                  <ClipLoader color='gray' size={10} />
                )}
              </div>

              <div
                onClick={() => setOpenTopupModal(true)}
                className='flex items-center font-semibold cursor-pointer gap-x-1 text-primary'
              >
                <img src={topup} alt='' />
                <p>Top up</p>
              </div>
            </section>
          </section>

          {/* first half */}
          <section>
            {navItems.map((nav, index) => {
              return <NavLink nav={nav} key={index} />;
            })}
          </section>
          {/* Directory */}
          {/* <ToggleDirectoryView /> */}

          {/* second half */}
          {/* mt-[0.7rem]  lh-sidebarmt */}
          <section className='absolute bottom-5'>
            {navItems2.map((nav, index) => {
              return <NavLink nav={nav} key={index} />;
            })}

            <section
              onClick={logoutFunc}
              className='flex items-center pl-3 my-2 text-xs cursor-pointer lh-lg gap-x-3'
            >
              <i className='pi pi-sign-out ' style={{ fontSize: '0.7rem' }}></i>
              <p>Logout</p>
            </section>

            <section
              style={style}
              className='px-4 py-2 text-xs text-white bg-[#131212] rounded-md w-[92%] mx-auto'
            >
              <FlexContainer className='text-white '>
                <section className='relative'>
                  {' '}
                  <Chart
                    type='doughnut'
                    data={chartData}
                    options={chartOptions}
                    className=' z-10 max-w-[3.5rem]'
                  />
                  <p className='absolute top-[45%] right-[30%] z-50 text-[10px] font-semibold'>
                    80%
                  </p>
                </section>
                <i
                  className='self-start text-xs cursor-pointer pi pi-times'
                  style={{ color: 'white' }}
                ></i>
              </FlexContainer>

              <div className='my-2 lh-lg'>
                <h3 className='mb-1 text-sm font-semibold'>Used Space</h3>
                {/* lh-lg */}
                <p className='text-xs leading-[1.2rem] my-3 '>
                  Your team has used 80% of your available space. Need more?
                </p>
              </div>

              <div className='w-full text-xs lh-lg'>
                <FlexContainer>
                  <p className='cursor-pointer text-primary'>Dismiss</p>

                  <p className='cursor-pointer'>Upgrade Plan</p>
                </FlexContainer>
              </div>
            </section>

            <section className='pt-2 mt-2 border-t'>
              <div className='flex items-center gap-x-2 '>
                {!profilePic.isLoading && (
                  <img
                    src={profileImg}
                    alt='avatar'
                    className='w-8 h-8 rounded-full'
                  />
                )}

                {profilePic.isLoading && (
                  <div className='flex items-center justify-center w-8 rounded-full bg-orange30'>
                    {' '}
                    <ClipLoader color='gray' size={12} />
                  </div>
                )}

                {/* {!profilePic.isLoading ? (
                  <img
                    src={profileImg}
                    alt="avatar"
                    className="w-8 rounded-full"
                  />
                ) : (
                  <div className="flex items-center justify-center w-8 rounded-full bg-orange30">
                    {" "}
                    <ClipLoader color="gray" size={12} />
                  </div>
                )} */}

                <div>
                  <h3 className='text-sm font-medium lh-lg'>
                    {userDetails?.user || userDetails?.fullName}
                  </h3>
                  <p className='text-xs text-gray60 lh-lg '>
                    {trimText(userDetails?.email, 24)}
                  </p>
                </div>
              </div>
            </section>
          </section>
          {/*  */}
        </section>
      </section>

      <Modal
        width={480}
        open={openTopupModal}
        onCancel={closeTopupModal}
        footer={null}
      >
        <TopupModal closeModal={closeTopupModal} ref={topUpModalRef} />
      </Modal>
    </section>
  );
}
