import { _getSlideStream } from "services/Slides/slidesServices";

export const imageStream = async (id, isAudio) => {
  try {
    const response = await _getSlideStream(id);

    if (response && response.data) {
      // const blob = isAudio
      //   ? new Blob([response.data], { type: "audio/*" })
      //   : response.data;

      //const blob = new Blob([response.data], { type: "audio/*" });
      const url = URL.createObjectURL(response.data);
      return url;
    } else {
      console.error("Response does not contain data");
    }
  } catch (error) {
    console.error("Failed to fetch image:", error);
  }
};
