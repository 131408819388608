import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  _createFolder,
  _getFolderFiles,
  _getWorkspaceFolders,
  _getAllWorkspace,
  _getSubfolderFiles,
  _deleteFolder,
  _updateFolder,
  _archiveFolder,
} from "services/Workspace/workspaceServices";

const initialState = {
  status: "idle",
  allWorkspace: {
    isLoading: "idle",
    data: null,
  },
  folderFiles: {
    data: {},
    // data: null,
    isLoading: false,
  },

  subfolderFiles: {
    isLoading: false,
    data: null,
  },
  workspaceFolders: {
    isLoading: "idle",
    data: null,
    tableData: {},
  },
};

//GET

export const getAllWorkspace = createAsyncThunk(
  "workspace/getAllWorkspace",
  async () => {
    const response = await _getAllWorkspace();
    return response.data;
  }
);

export const getWorkspaceFolders = createAsyncThunk(
  "workspace/getWorkspaceFolders",
  async (workspaceId) => {
    const response = await _getWorkspaceFolders(workspaceId);
    //hide audio
    const folders = response.data?.filter(
      (doc) => !doc?.title?.includes("vrecord")
    );
    return folders;
  }
);

export const getFolderFiles = createAsyncThunk(
  "workspace/getFolderFiles",
  async (id) => {
    const response = await _getFolderFiles(id);

    return response.data;
  }
);

export const getSubfolderFiles = createAsyncThunk(
  "workspace/getSubfolderFiles",
  async (id) => {
    const response = await _getSubfolderFiles(id);
    return response.data;
  }
);

//POST

export const createFolder = createAsyncThunk(
  "workspace/createFolder",
  async (data) => {
    const response = await _createFolder(data);
    return response.data;
  }
);

//PUT

export const updateFolder = createAsyncThunk(
  "workspace/updateFolder",
  async ({ folderId, newTitle }) => {
    const response = await _updateFolder(folderId, newTitle);
    return response.data;
  }
);

//DELETE
export const archiveFolder = createAsyncThunk(
  "workspace/archiveFolder",
  async (folderId) => {
    const response = await _archiveFolder(folderId);
    return response.data;
  }
);

export const deleteFolder = createAsyncThunk(
  "workspace/deleteFolder",
  async (folderId) => {
    const response = await _deleteFolder(folderId);
    return response.data;
  }
);

export const workspaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    clearFolderFiles(state, { payload }) {
      if (payload) {
        state.folderFiles.data.payload = null;
      } else {
        state.folderFiles = {};
      }
    },
  },
  extraReducers: (builder) => {
    // All workspaces
    builder
      .addCase(getAllWorkspace.pending, (state) => {
        state.allWorkspace.isLoading = true;
      })
      .addCase(getAllWorkspace.fulfilled, (state, { payload }) => {
        state.allWorkspace.data = payload;
        state.allWorkspace.isLoading = false;
      })
      .addCase(getAllWorkspace.rejected, (state, { payload }) => {
        state.allWorkspace.isLoading = false;
      });

    // workspace folders
    builder
      .addCase(getWorkspaceFolders.pending, (state, action) => {
        state.workspaceFolders.isLoading = true;
        const workspaceId = action.meta.arg;
        state.workspaceFolders.tableData[workspaceId] = {
          isLoading: true,
          files: null,
        };
      })
      .addCase(getWorkspaceFolders.fulfilled, (state, action) => {
        state.workspaceFolders.data = action.payload?.filter((folder) => {
          return !folder?.title?.includes("vrecords");
        });

        state.workspaceFolders.isLoading = false;

        const workspaceId = action.meta.arg;
        state.workspaceFolders.tableData[workspaceId] = {
          isLoading: false,
          files: action.payload,
        };
      })
      .addCase(getWorkspaceFolders.rejected, (state, { payload }) => {
        state.workspaceFolders.isLoading = false;
      });

    //All folder files
    builder
      .addCase(getFolderFiles.pending, (state, action) => {
        state.folderFiles.isLoading = true;

        const folderId = action.meta.arg;

        state.folderFiles.data[folderId] = { isLoading: true, files: null };
      })
      .addCase(getFolderFiles.fulfilled, (state, action) => {
        state.folderFiles.isLoading = false;
        // state.folderFiles.data = payload;

        const folderId = action.meta.arg;
        state.folderFiles.data[folderId] = {
          isLoading: false,
          files: action.payload,
        };
      })
      .addCase(getFolderFiles.rejected, (state, { payload }) => {
        state.folderFiles.isLoading = false;
      });

    //All subfolder files
    builder
      .addCase(getSubfolderFiles.pending, (state) => {
        state.subfolderFiles.isLoading = true;
      })
      .addCase(getSubfolderFiles.fulfilled, (state, { payload }) => {
        state.subfolderFiles.isLoading = false;
        state.subfolderFiles.data = payload;
      });
  },
});

export const getWorkspaceState = (state) => state.workspace;
export const { clearFolderFiles } = workspaceSlice.actions;

export default workspaceSlice.reducer;
