import React from "react";

//assets
import demo from "assets/video/demo.mp4";

//libraries
import { TypeAnimation } from "react-type-animation";

export default function Banner() {
  return (
    <section>
      <section className=" mx-auto [lg:w-[80%] w-[99%] ]">
        <div className="text-center">
          <h1 className="  [ lg:text-[4rem]  text-2xl lg:leading-tight font-semibold ] ">
            <span className="text-primary">
              <TypeAnimation
                sequence={[
                  " Transforming",
                  1000,
                  "Innovating",
                  2000,
                  "Evolutionalizing",
                ]}
                speed={20}
                wrapper="span"
                cursor={true}
                repeat={Infinity}
              />
            </span>{" "}
            Presentations, <br /> Anywhere, Anytime
          </h1>

          <p className=" mx-auto text-black30  [ lg:w-[50%] w-[90%] ] [ lg:text-[1.25rem] text-sm ] [ lg:mt-6 mt-4 ]  [ lg:leading-[2rem] leading-[1rem] ] ">
            Elevate your presentations to new heights with Presenta, the
            ultimate solution for modern presenters. Seamlessly create, deliver,
            and engage with your audience like never before, whether you're in a
            boardroom, a classroom, or hosting a webinar.
          </p>
        </div>
      </section>
      <section className=" my-4  lg:h-[42rem] video-section flex items-center">
        <div className="  z-0   [  mt-10 mb-4 min-xl:my-20 lg:my-0  ] [ flex justify-center items-center ] [ lg:w-[65%] w-10/12  mx-auto  ]  shadow-lg rounded-lg">
          <video autoPlay muted loop={true} className="">
            <source src={demo} type="video/mp4" />
          </video>
        </div>
      </section>
    </section>
  );
}
