import { toast } from "react-toastify";

export const MoveSlide = (
  type,
  connection,
  activeCount,
  sideImages,
  presentationId,
  documentId,
) => {
  let direction;
  if (type === "prev") {
    if (activeCount !== 0) {
      direction = activeCount - 1;
    } else {
      direction = 0;
    }
  }

  if (type === "next") {
    if (activeCount === sideImages?.length - 1) {
      direction = 0;
    } else {
      direction = activeCount + 1;
    }
  }


  if (connection) { 
    connection.invoke("MoveSlide", presentationId,  direction , documentId).catch((err) => {
      console.log(err);
      toast.error("An error occured while navigating slide");
    });
  }
};

