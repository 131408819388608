import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//assets
import msg from "assets/svg/sms.svg";
import avatar from "assets/svg/avatar.svg";
import shape from "assets/svg/3dshape.svg";
import phoneIcon from "assets/svg/phone.svg";
import passLock from "assets/svg/passLock.svg";
import whiteEdit from "assets/svg/whiteEdit.svg";

//libraries
import { Modal } from "antd";
import { ClipLoader } from "react-spinners";

//utils
// import { imageStream } from "utils/imageStream";
import { useToast } from "components/UI/ToastProvider";
import { errorToast, successToast } from "utils/toastUtils";
import { getUserDetails } from "utils/localStorageServices";

//components
import ToggleSwitch from "components/UI/ToggleSwitch";
import AuthInput from "components/UI/Inputs/AuthInput";
import EditProfile from "views/Admin/Settings/Modal/EditProfile";

//slices
import {
  changePassword,
  getProfilePic,
  getSettingsState,
} from "store/Settings/settingSlice";

export default function AccountSettings() {
  const { fullName, email, phone } = getUserDetails() ?? {};
  const dispatch = useDispatch();

  const toast = useToast();

  const { profilePic } = useSelector(getSettingsState);

  //useref
  const oldPasswordRef = useRef(null);
  const newPasswordRef = useRef(null);

  //usestate
  const [profileImg, setProfileImg] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toggleState1, setToggleState1] = useState(false);
  const [isPasswordChangeLoading, setIsPasswordChangeLoading] = useState(false);

  const [isDigit, setIsDigit] = useState(false);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [isNonAlphanumeric, setIsNonAlphanumeric] = useState(false);

  //useeffects
  useEffect(() => {
    const profile = profilePic.data;

    profile ? setProfileImg(profilePic.data) : setProfileImg(avatar);
  }, [profilePic]);

  useEffect(() => {
    dispatch(getProfilePic());
  }, [dispatch]);

  //function
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "newpassword") {
      setIsDigit(/\d/.test(value));
      setIsLengthValid(value.length >= 8);
      setIsNonAlphanumeric(/[^a-zA-Z0-9]/.test(value));
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const toggleState = () => {
    setToggleState1(!toggleState1);
  };

  const changeOldPassword = () => {
    const oldPassword = oldPasswordRef.current.value;
    const newPassword = newPasswordRef.current.value;

    if (!oldPassword || !newPassword) {
      errorToast( "Fill all password fields");
      return;
    }
    const data = {
      oldPassword,
      newPassword,
    };

    setIsPasswordChangeLoading(true);
    dispatch(changePassword(data)).then(({ type }) => {
      setIsPasswordChangeLoading(false);
      if (type?.includes("fulfilled")) {
        newPasswordRef.current.value = "";
        oldPasswordRef.current.value = "";
        setIsDigit(false)
        setIsLengthValid(false)
        setIsNonAlphanumeric(false)
        successToast( "Password reset successful ");
      }
    });
  };

  const bgGradient = {
    background: "linear-gradient(90deg, #FFF2EB 0%, #CDC7C3 100%)",
  };

  return (
    <section>
      {/* banner  */}
      <section style={bgGradient} className="relative px-4 rounded-lg">
        <img src={shape} alt="" className="absolute left-[40%] top-0" />

        <section className="flex justify-between py-4">
          <section className="flex items-center gap-x-4">
            <div>
              {!profilePic.isLoading && (
                <img
                  src={profileImg}
                  alt="avatar"
                  className="w-[4.75rem] h-[4.75rem] rounded-full"
                />
              )}

              {profilePic.isLoading && (
                <div className="w-[4.75rem] h-[4.75rem] bg-orange30 rounded-full flex items-center justify-center">
                  {" "}
                  <ClipLoader color="gray" size={12} />
                </div>
              )}
            </div>

            <div>
              <h2 className="text-2xl font-semibold">{fullName}</h2>

              <section className="flex items-center mt-3 gap-x-10">
                <div className="flex items-center gap-x-1">
                  <img src={msg} alt="" />
                  <p>{email}</p>
                </div>

                <div className="flex items-center gap-x-1">
                  <img src={phoneIcon} alt="" />
                  <p>{phone ?? "---"}</p>
                </div>
              </section>
            </div>
          </section>

          <section className="flex items-end">
            <button
              onClick={() => setIsModalOpen(true)}
              className="flex items-center p-2 text-sm text-white bg-black rounded-lg gap-x-2"
            >
              <img src={whiteEdit} alt="" />
              <span>Edit profile</span>
            </button>
          </section>
        </section>
      </section>
      {/*  */}

      {/* email preference  */}

      {/* <section className="py-10 my-4 border-t ">
        <section className="flex">
          <section className="w-[30%]">
            <div className="flex items-center gap-x-1">
              <img src={msg} alt="" />
              <p className="mt-1 font-semibold">Email preference</p>
            </div>
          </section>

          <section className="w-[70%]">
            <section className="flex flex-col w-1/2 gap-y-8">
              <section className="flex justify-between">
                <div>
                  <h4 className="text-lg font-medium">New visitors </h4>
                  <p className="text-sms">
                    Save my login details for next time.
                  </p>
                </div>

                <div>
                  {" "}
                  <ToggleSwitch toggleState={toggleState} />
                </div>
              </section>

              <section className="flex justify-between">
                <div>
                  <h4 className="text-lg font-medium">Presentation overview</h4>
                  <p className="text-sms">
                    Save my login details for next time.
                  </p>
                </div>

                <div>
                  {" "}
                  <ToggleSwitch toggleState={toggleState} />
                </div>
              </section>

              <section className="flex justify-between">
                <div>
                  <h4 className="text-lg font-medium">
                    Team member’s activity
                  </h4>
                  <p className="text-sms">
                    Save my login details for next time.
                  </p>
                </div>

                <div>
                  {" "}
                  <ToggleSwitch toggleState={toggleState} />
                </div>
              </section>
            </section>
          </section>
        </section>
      </section> */}
      {/*  */}

      {/* security  */}
      {/* border-t */}
      <section className="py-10 my-4 ">
        <section className="flex">
          <section className="w-[30%]">
            <div className="flex items-center gap-x-1">
              <img src={passLock} alt="" />
              <p className="mt-1 font-semibold">Security</p>
            </div>
          </section>

          <section className="w-[70%]">
            <section className="flex flex-col w-1/2 gap-y-6">
              <section className="">
                <h4 className="text-sm font-medium">Old Password </h4>
                <AuthInput
                  name="oldpassword"
                  isPassword={true}
                  customRef={oldPasswordRef}
                  placeholder="Enter old password"
                />
              </section>

              <section className="">
                <h4 className="text-sm font-medium">New Password </h4>
                <div>
                  <AuthInput
                    name="newpassword"
                    isPassword={true}
                    customRef={newPasswordRef}
                    onChange={handleInputChange}
                    placeholder="Enter new password"
                  />
                  <ul className="mt-2 text-[13px]">
                    <li
                      className={
                        isLengthValid ? "text-green-500" : "text-red-500"
                      }
                    >
                      Passwords must be at least 8 characters.
                    </li>
                    <li className={isDigit ? "text-green-500" : "text-red-500"}>
                      Passwords must have at least one digit ('0'-'9').
                    </li>
                    <li
                      className={
                        isNonAlphanumeric ? "text-green-500" : "text-red-500"
                      }
                    >
                      Passwords must have at least one non-alphanumeric
                      character.
                    </li>
                  </ul>
                </div>

                <button
                  onClick={changeOldPassword}
                  disabled={isPasswordChangeLoading}
                  className="w-full py-3 my-4 text-sm text-white bg-black rounded-lg"
                >
                  {isPasswordChangeLoading ? (
                    <ClipLoader color="white" size={12} />
                  ) : (
                    " Save"
                  )}
                </button>
              </section>
            </section>
          </section>
        </section>
      </section>
      {/*  */}

      {/* modal  */}
      <>
        <Modal
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          width="526px"
        >
          <EditProfile avatar={profileImg} closeModal={handleCancel} />
        </Modal>
      </>

      {/*  */}
    </section>
  );
}
