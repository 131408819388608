import React, { useEffect, useState } from "react";

//hooks
import { useGenericListener } from "hooks/SignalR/Listeners/Global/UseGenericListener";

//slice
import { getSignalRConnection } from "store/SignalR/signalRSlice";

// Asset imports
import trophy from "assets/svg/trophy.svg";
import timerImg from "assets/svg/timer2.svg";
import pollHeader from "assets/svg/pollHeader.svg";
import profile from "assets/svg/profile-2user.svg";
import greenTimer from "assets/svg/green-timer.svg";

//libraries
import { Chart } from "primereact/chart";

//utils
import {
  getPollStatus,
  getViewerCount,
  isWordCloudCheck,
  isQuestionInActivePoll,
  storeOptionBasedAnswer,
  fetchQuestionFromFinishedPolls,
} from "utils/activePresentationUtils";
import { generateRandomColor } from "utils/colorGenerator";

let connection = null;
export default function OptionBasedQuestion({
  timer,
  pollId,
  endPoll,
  activePoll,
  questionId,
  isPollFinished,
  questionDetails,
}) {
  connection = getSignalRConnection();

  const { duration } = questionDetails ?? {};
  const questionOptions = questionDetails?.options;

  // Use state
  const [answer, setAnswer] = useState(null);
  const [totalVotes, setTotalVotes] = useState(0);
  const [graphDataArr, setGraphDataArr] = useState([]);
  const [optionLabels, setOptionLabels] = useState([]);
  const [mixedChartData, setMixedChartData] = useState({});
  const [mixedChartOptions, setMixedChartOptions] = useState({});
  const [participationCount, setParticipationCount] = useState(0);

  useEffect(() => {
    // Graph X axis
    const generatedColors = [];
    const colorSet = new Set();

    let labels = questionDetails?.options?.map((option) => option?.text) || [];

    const graphXAxis = labels.forEach(() => {
      let color;
      do {
        color = `${generateRandomColor()}40`;
      } while (colorSet.has(color));
      colorSet.add(color);
      generatedColors.push(color);
    });

    setOptionLabels(graphXAxis);

    //Graph Y Axis
    let initialGraphData;
    const activePollAnswers = isQuestionInActivePoll(
      questionDetails?.id
    )?.answers;

    let cachedAnswers;
    if (getPollStatus()) {
      cachedAnswers = activePollAnswers;
    } else {
      cachedAnswers = fetchQuestionFromFinishedPolls(
        questionDetails?.id,
        pollId
      )?.answers;
    }

    if (!cachedAnswers) {
      initialGraphData = labels?.map(() => 0);
    } else {
      let allTestAnswers = [];

      initialGraphData = cachedAnswers?.map((answer) => {
        return answer?.text;
      });

      cachedAnswers.forEach((answer) => {
        allTestAnswers = [...allTestAnswers, ...answer?.texts];
      });

      initialGraphData = [];
      labels.forEach((label, index) => {
        const optionList = allTestAnswers?.filter((text) => {
          return text?.toLowerCase() === label?.toLowerCase();
        });

        initialGraphData[index] = optionList?.length;
      });
    }

    setOptionLabels(labels);
    setGraphDataArr(initialGraphData);
  }, [questionDetails, isPollFinished]);

  useEffect(() => {
    if (!answer?.texts) {
      return;
    } else {
      // Find index of selected options
      const selectedOptionIndexes = answer?.texts?.map((answerText) => {
        return questionOptions?.findIndex(
          (option) => option?.text?.toLowerCase() === answerText?.toLowerCase()
        );
      });

      // Update graph data
      graphDataArr?.length > 0 &&
        setGraphDataArr((prevGraphDataArr) => {
          const newGraphDataArr = [...prevGraphDataArr];
          selectedOptionIndexes.forEach((index) => {
            if (index !== -1) {
              newGraphDataArr[index] += 1;
            }
          });
          return newGraphDataArr;
        });
    }
  }, [answer, questionOptions]);

  useEffect(() => {
    const generatedColors = [];
    const colorSet = new Set();
    const calculatePercentages = (data) => {
      const total = data.reduce((sum, value) => sum + value, 0);
      return data.map((value) => (value / total) * 100);
    };

    if (!optionLabels) return;
    optionLabels.forEach(() => {
      let color;
      do {
        color = `${generateRandomColor()}`;
      } while (colorSet.has(color));
      colorSet.add(color);
      generatedColors.push(color);
    });

    const percentageData = calculatePercentages(graphDataArr);
    console.log(graphDataArr);
    console.log(percentageData, "percentage");

    const data = {
      labels: optionLabels,
      datasets: [
        {
          type: "bar",
          label: "Attention",
          backgroundColor: generatedColors,
          borderColor: generatedColors,
          borderWidth: 1,
          data: percentageData,
          borderRadius: 4,
        },
      ],
    };

    const options = {
      plugins: {
        legend: {
          display: false,
          position: "top",
        },
      },
      scales: {
        x: {
          stacked: true,
          barThickness: 100,
          grid: {
            display: false,
            drawBorder: false,
            color: "rgba(0,0,0,0.1)",
          },
        },
        y: {
          min: 0,
          max: 100,
          ticks: {
            callback: function (value) {
              return value + "%";
            },
          },
          stacked: true,
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
      maintainAspectRatio: false,
    };

    setMixedChartData(data);
    setMixedChartOptions(options);

    const votes = graphDataArr?.reduce((acc, cur) => {
      return acc + cur;
    }, 0);

    const percent = ((votes / getViewerCount()) * 100)?.toFixed(0);
    setTotalVotes(votes);
    setParticipationCount(percent);
  }, [graphDataArr, optionLabels]);

  // Handling polls
  useGenericListener(connection, (presentationId, eventName, obj) => {
    const event = eventName?.toLowerCase();
    if (event === "viewerpollanswer") {
      const { answer, questionId, questionType } = obj ?? {};
      if (!isWordCloudCheck(questionType)) {
        setAnswer(answer);
        storeOptionBasedAnswer(answer, questionId);
      }
    }
  });

  // console.log(isPollFinished, timer, "ll");
  return (
    <section>
      <section className="bg-white mt-6 max-h-[84vh] min-h-[50vh] overflow-y-scroll hide-scrollbar border rounded-xl py-4 px-6">
        {/* Header */}
        <section className="">
          <div className="flex items-center gap-x-2">
            <img src={pollHeader} alt="" className="w-[3.75rem]" />
            <div>
              <h3 className="text-xl font-bold">Poll result </h3>
              <p>See a break down of your poll result</p>
            </div>
          </div>
        </section>

        {/* Body */}
        <section className="px-6 pt-4 my-4 rounded-2xl bg-gray100">
          {/* Stats */}
          <section className="flex justify-between w-full">
            <div>
              <p className="pt-4 text-xl font-bold">{activePoll?.question}</p>
            </div>

            <section className="flex items-center gap-x-6">
              <section className="flex items-center gap-x-3">
                <img src={trophy} alt="" className="w-[3.75rem]" />
                <div>
                  <p className="text-sm">Participation</p>
                  <h3 className="font-bold mt-[1px] ">
                    {participationCount}% Responded
                  </h3>
                </div>
              </section>

              <section className="flex items-center gap-x-3">
                <img src={greenTimer} alt="" className="w-[3.75rem]" />
                <div>
                  <p className="text-sm">Average response time</p>
                  <h3 className="font-bold mt-[1px] ">
                    {activePoll?.duration} secs
                  </h3>
                </div>
              </section>
            </section>
          </section>

          {/* Chart */}
          <section className="py-4 w-[70%]">
            <Chart
              type="bar"
              height="300"
              width="200"
              data={mixedChartData}
              options={mixedChartOptions}
            />
          </section>

          <section className="py-4 flex-between">
            <section className="flex items-center gap-x-6">
              <div className="flex justify-center my-4 font-bold gap-x-1 text-gray40">
                <img src={timerImg} alt="" />
                <p className="mt-[2px]">
                  {isPollFinished ? duration : timer} Secs
                </p>
              </div>

              <div className="flex items-center text-sm font-semibold text-center text-gray40 gap-x-1">
                <img src={profile} alt="" />
                <p className="pt-1">{totalVotes} votes</p>
              </div>
            </section>

            <section>
              {!isPollFinished && (
                <section className="flex justify-center w-full mt-1">
                  <button
                    onClick={endPoll}
                    className="px-4 py-1 mx-auto font-bold text-white bg-black rounded-full w-fit"
                  >
                    End poll
                  </button>
                </section>
              )}
            </section>
          </section>
        </section>
      </section>
    </section>
  );
}
