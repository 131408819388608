import { GetUsersList } from "hooks/SignalR/Invokers/Presenter/GetUsersList";
import { useEffect } from "react";
import { toast } from "react-toastify";

export const usePresentationAccessGranted = (connection, presentationId) => {
  useEffect(() => {
    const handleEventResponse = (id, eventName, status, message) => {
      if (eventName?.toLowerCase() === "grantaccess") {
        if (status) {
          toast.success(message);
          GetUsersList(connection, presentationId)
        } else if (!status) {
          toast.error(message);
        }
      }
    };
    if (connection) {
      connection.on("EventResponse", handleEventResponse);
    }

    return () => {
      if (connection) {
        connection.on("EventResponse", handleEventResponse);
      }
    };
  }, [connection, presentationId]);
};
