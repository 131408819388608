import React, { useEffect, useState } from "react";

//utils
import { imageStream } from "utils/imageStream";

//assets
import pdfImg from "assets/image/pdf.png";
import pptImg from "assets/image/ppt.png";
import excelImg from "assets/image/excel.png";
import folderImg from "assets/svg/folderImg.svg";
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { clearFolderFiles } from "store/Workspace/workspaceSlice";

export default function FileThumbnail({ file }) {
  const dispatch = useDispatch();

  //usestate
  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    const fileUrl = file?.url;
    const fetchImageStream = async () => {
      setThumbnail(null);

      dispatch(clearFolderFiles(file?.folderId));
      if (!fileUrl) return;
      const url = await imageStream(file?.id);
      if (!isCancelled) {
        setThumbnail(url);
      }
    };

    if (fileUrl) {
      if (fileUrl.endsWith(".ppt") || fileUrl.endsWith(".pptx")) {
        setThumbnail(pptImg);
      } else if (fileUrl.endsWith(".csv") || fileUrl.endsWith(".xlsx")) {
        setThumbnail(excelImg);
      } else if (fileUrl.endsWith(".pdf")) {
        setThumbnail(pdfImg);
      } else if (
        fileUrl.endsWith(".png") ||
        fileUrl.endsWith(".jpeg") ||
        fileUrl.endsWith(".jpg") ||
        fileUrl.endsWith(".svg")
      ) {
        fetchImageStream();
      }
    } else {
      setThumbnail(folderImg);
    }

    return () => {
      isCancelled = true;
    };
  }, [file, dispatch]);

  return (
    <div className="flex items-center gap-x-4">
      {thumbnail ? (
        <img
          src={thumbnail}
          alt={file?.title}
          className={`${"w-8 h-8 rounded-lg"}`}
        />
      ) : (
        <div
          className={`w-8 h-8 rounded-lg cursor-pointer flex justify-center items-center`}
        >
          <ClipLoader size={12} color="gray" />
        </div>
      )}

      <p>{file?.title}</p>
    </div>
  );
}
