import { GenericInvoker } from "../Global/GenericInvoker";

export const PresenterEndPoll = (
  connection,
  presentationId,
  presentaConnectionId,
  pollId
) => {
  if (connection) {
    // console.log(presentationId, presentaConnectionId, pollId);

    GenericInvoker(
      connection,
      presentationId,
      presentaConnectionId,
      "EndPoll",
      {
        pollId,
      }
    );
  }
};
