import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//assets
import logo from "assets/svg/fullLogo.svg";
import lineBg from "assets/svg/lineBg.svg";

//libraries
import axios from "axios";
import { ClipLoader } from "react-spinners";

//utils
import { baseUrl } from "utils/baseUrl";
import { altLogins } from "./data";

//components
import Label from "components/UI/Label";
import AuthInput from "components/UI/Inputs/AuthInput";
import LoginCarousel from "components/Authentication/Login/Carousel";

//utils
import { errorToast } from "utils/toastUtils";
import { useToast } from "components/UI/ToastProvider";

export default function Login() {
  const navigate = useNavigate();
  const toast = useToast();

  //usestate
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);

  useEffect(() => {
    const { email, password } = formData;
    if (email !== "" && password !== "") {
      setIsButtonActive(true);
    } else {
      setIsButtonActive(false);
    }
  }, [formData]);

  //functions

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const loginFunc = (e) => {
    e.preventDefault();

    if (formData.email === "") {
      errorToast( "Enter an email address");
      return;
    }

    if (formData.password === "") {
      errorToast( "Enter password");
      return;
    }

    const data = {
      userName: formData.email,
      password: formData.password,
    };

    setIsLoading(true);
    setIsButtonActive(false);

    axios
      .post(`${baseUrl}/Account/login`, data)
      .then((response) => {
        localStorage.setItem("email", formData.email);

        localStorage.setItem("user", JSON.stringify(response.data));

        if (response.data?.code) {
          localStorage.setItem("ref", response?.data?.code);
          navigate("/otp");
        } else {
          navigate("/admin/mypresentations");
        }
      })
      .catch((err) => {
        let message = err?.response?.data?.message ?? err.message;
        errorToast( message);
      })
      .finally(() => {
        setIsLoading(false);
        setIsButtonActive(true);
      });
  };

  const openWindow = (name) => {
    let domain = window.location.host;
    // https://testapi.thepresenta.com/
    window.location.href = `${baseUrl}/login/${name}?origin=https://${domain}/login`;
  };

  const lineBgStyle = {
    background: `url(${lineBg}) no-repeat bottom`,
    // backgroundSize: "cover",
  };

  return (
    <section className="flex justify-between h-screen overflow-y-hidden font-grotesk ">
      {/* form */}
      <section
        style={lineBgStyle}
        className="lg:w-[40%] w-full px-14 lg:py-4 py-10"
      >
        <Link to="/">
          <div>
            <img src={logo} alt="Presenta Logo" className="w-20 ml-5 lg:w-24" />
          </div>
        </Link>

        <section className="lg:mt-6 mt-[3%] w-[90%] mx-auto ">
          <h1 className="font-bold lg:text-[2rem] text-2xl  text-left  ">
            Login
          </h1>

          <section className="mt-4">
            <form onSubmit={loginFunc} className="">
              <div>
                <Label text="Email address" />
                <AuthInput
                  type="email"
                  name="email"
                  placeholder="example@presenta.com"
                  onChange={(e) => handleInputChange(e)}
                />
              </div>

              <div className="my-4">
                <Label text="Password" />
                <AuthInput
                  isPassword={true}
                  placeholder="Enter your password"
                  name="password"
                  onChange={(e) => handleInputChange(e)}
                />
              </div>

              <button
                // disabled={!isButtonActive}
                className={`w-full py-3  text-sm text-white bg-black rounded-md ${
                  isButtonActive
                    ? "opacity-100"
                    : "opacity-50 cursor-not-allowed"
                }`}
              >
                {isLoading ? <ClipLoader color="white" size={12} /> : "Login"}
              </button>
            </form>

            <div className="flex items-center justify-center mt-4 gap-x-2 ">
              <p className="text-sm ">Don’t have an account?</p>

              <Link to="/signup">
                <div className="flex items-center px-2 py-1 text-xs border border-black rounded gap-x-2 ">
                  <p className="font-semibold">Join Us</p>
                  <i className="pi pi-arrow-right"></i>
                </div>
              </Link>
            </div>
          </section>

          <div className="font-medium text-center lg:my-4 text-gray40 ">
            <h3>OR</h3>
          </div>

          <section className="flex flex-col justify-center my-4 gap-y-2 gap-x-5 lg:mb-6">
            {altLogins.map((el, index) => {
              return (
                <div
                  onClick={() => openWindow(el.name)}
                  key={index}
                  className="flex items-center justify-center py-2 border-2 rounded-full cursor-pointer gap-x-4 "
                >
                  <div>
                    <img
                      src={el.img}
                      alt={`${el.name} Logo`}
                      className="w-5 lg:w-[1.5rem]"
                    />
                  </div>
                  <div className="text-sm font-medium ">
                    Sign in with {el.name}
                  </div>
                </div>
              );
            })}
          </section>
        </section>
      </section>
      {/*  */}

      {/* carousel */}
      <section className="lg:w-[60%] hidden lg:block bg-orange10 h-screen px-10 ">
        <LoginCarousel />
      </section>
      {/*  */}
    </section>
  );
}
