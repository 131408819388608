import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  _createBriefcase,
  _getBriefcases,
  _updateBriefcase,
} from "services/Briefcase/briefcaseServices";

const initialState = {
  briefcases: {
    isLoading: false,
    data: null,
  },
};
//get
export const getBriefcases = createAsyncThunk(
  "briefcase/getBriefcases",
  async (teamId) => {
    const response = await _getBriefcases(teamId);
    return response.data;
  }
);

//POST
export const createBriefcase = createAsyncThunk(
  "briefcase/createBriefcase",
  async (data) => {
    const response = await _createBriefcase(data);
    return response.data;
  }
);

export const updateBriefcase = createAsyncThunk(
  "briefcase/updateBriefcase",
  async (data) => {
    const response = await _updateBriefcase(data);
    return response.data;
  }
);

export const briefcaseSlice = createSlice({
  name: "briefcase",
  initialState,
  reducers: {
    clearteamMembers(state) {
      state.teamMembers.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBriefcases.pending, (state) => {
        state.briefcases.isLoading = true;
        state.briefcases.data = null;
      })
      .addCase(getBriefcases.fulfilled, (state, { payload }) => {
        state.briefcases.isLoading = false;
        state.briefcases.data = payload;
      })
      .addCase(getBriefcases.rejected, (state) => {
        state.briefcases.isLoading = false;
      });
  },
});

export const getBriefcaseState = (state) => state.briefcase;
export const {
  clearteamMembers,
  setSideImages,
  clearSideImages,
  setActiveSlide,
} = briefcaseSlice.actions;
export default briefcaseSlice.reducer;
