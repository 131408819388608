import React from "react";

//libraries
import { Modal } from "antd";
import { ClipLoader } from "react-spinners";

export default function ConfirmationModal({
  open,
  text,
  func,
  onClose,
  isLoading,
}) {
  return (
    <section>
      <Modal open={open} footer={null} closable={false} onCancel={onClose} >
        <section className="py-6">
          <p className="text-lg font-semibold">{text}</p>

          <section className="flex w-full mt-4 gap-x-4">
            <button
              className="w-1/2 py-2 text-white bg-green-600 rounded-lg"
              onClick={() => func()}
            >
              {isLoading ? <ClipLoader color="white" size={12} /> : "Yes"}
            </button>

            <button
              className="w-1/2 py-2 text-white bg-red-600 rounded-lg"
              onClick={() => onClose()}
            >
              No
            </button>
          </section>
        </section>
      </Modal>
    </section>
  );
}
