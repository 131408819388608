import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  setDisconnected,
  setReconnected,
  setReconnecting,
} from "store/SignalR/signalRSlice";

export const useSignalRConnectionStatus = (connection) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (connection) {
      connection.onreconnecting(() => {
        dispatch(setReconnecting());

        // toast.warn("Reconnecting...");
      });

      connection.onreconnected(() => {
        dispatch(setReconnected());
        // toast.success("Connection re-establisihed");
      });

      connection.onclose((e) => {
        dispatch(setDisconnected());
        if (e) {
          toast.error(`Connection closed with error: "${e || ""}"`);
        }
      });
    }
  }, [connection, dispatch]);
};
