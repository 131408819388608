import { baseUrl } from "utils/baseUrl";

import axios from "axios";
import { getToken } from "utils/localStorageServices";
import { errorToast } from "utils/toastUtils";

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    // Accept: "application/json",
    // "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  // timeout: 1000,
});

instance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    config.headers["Authorization"] = `Bearer ${getToken() ?? ""}`;

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger

    // Do something with response data
    return response;
  },
  function (error) { 
    let errorStatus = error?.response?.status;
    const errorList = error?.response?.data?.errors;
    let requestMsg =
      error?.response?.data?.message ??
      error.response?.data?.title ??
      error?.message;
    const isViewerMode = window.location.href?.includes("viewer-mode");
    console.log(error);

    if (
      !errorStatus &&
      requestMsg === "Network Error" &&
      !error?.config?.url?.includes("/Document/stream/")
    ) {
      console.log("here");
      errorToast("Network Error");

      return;
    }

    // if (errorStatus === 401 && !isViewerMode) {
    //   errorToast("Session Expired");
    //   localStorage.clear();
    //   window.location.href = "/";
    //   return Promise.reject(error);
    // }
    // errorStatus === 404 ||
    // if (isViewerMode && errorStatus === 401) {
    //   let routeOrigin = window.location.origin;
    //   let presentationId = window.location.href?.split("/")?.[4];
    //   localStorage.setItem("connectionId", "");

    //   errorToast(requestMsg);
    //   // toast.error(requestMsg);
    //   if (requestMsg) {
    //     setTimeout(() => {
    //       window.location.href = `${routeOrigin}/join-presentation/${presentationId}`;
    //     }, 1000);
    //   }
    // }

    if (requestMsg === "One or more validation errors occurred.") {
      for (let error in errorList) {
        errorToast(errorList[error][0]);
        // toast.error(errorList[error][0]);
      }
    } else if (!error?.config?.url?.includes("/Document/stream/")) {
      errorToast(requestMsg);
      // toast.error(requestMsg);
    }

    return Promise.reject(error);
  }
);

export default instance;
