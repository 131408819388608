import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

//assets
import logo from "assets/svg/fullLogo.svg";

//libraries
import axios from "axios";
import Countdown from "react-countdown";
import { ClipLoader } from "react-spinners";

//utils
import { baseUrl } from "utils/baseUrl";
import LoginCarousel from "components/Authentication/Login/Carousel";
import { useToast } from "components/UI/ToastProvider";
import { errorToast, successToast } from "utils/toastUtils";

export default function OTP() {
  const toast = useToast();
  const navigate = useNavigate();

  //usestate
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [otpValues, setOtpValues] = useState(Array(6).fill(""));

  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  //useeffect
  useEffect(() => {
    for (const value of otpValues) {
      if (value === "") {
        setIsButtonActive(false);
      } else {
        setIsButtonActive(true);
      }
    }
  }, [otpValues]);

  //functions
  const handlePaste = (e, index) => {
    e.preventDefault();

    // Access the clipboard data
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text/plain");

    if (/^[0-9]*$/.test(pastedData) && pastedData.length <= 6 - index) {
      const newValues = [...otpValues];

      for (let i = 0; i < pastedData.length; i++) {
        newValues[index + i] = pastedData[i];
      }

      setOtpValues(newValues);

      // Focus on the next input if available
      if (otpValues.length > index + pastedData.length) {
        inputRefs[index + pastedData.length].current.focus();
      }
    }
  };

  const handleChange = (e, index) => {
    const { value } = e.target;

    if (/^[0-9]*$/.test(value)) {
      const newValues = [...otpValues];
      newValues[index] = value;
      setOtpValues(newValues);

      if (otpValues.length > index + 1) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const verifyAccount = (e) => {
    e.preventDefault();

    let email = localStorage.getItem("email");
    let reference = localStorage.getItem("ref");

    // if (email || reference) {
    //   toast.error(
    //     "Essential details are missing for the process to be completed "
    //   );
    // }

    const data = {
      otp: otpValues.join(""),
      reference,
      recipient: email,
      username: email,
      mode: "email",
    };

    setIsLoading(true);
    setIsButtonActive(false);

    axios
      .post(`${baseUrl}/Account/VerifyAccount`, data)
      .then((response) => {
        localStorage.clear();
        successToast( "Account verified");
        navigate("/login");
      })
      .catch((err) => {
        let message = err?.response?.data?.message ?? err.message;

        errorToast( message);
        console.log(err, "OTP");
      })
      .finally(() => {
        setIsLoading(false);
        setIsButtonActive(true);
      });
  };

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <span>You are good to go!</span>;
    } else {
      // Render a countdown
      return (
        <span>
          {/* {hours}:{minutes}:{seconds} */}
          {minutes}:{seconds}
        </span>
      );
    }
  };

  return (
    <section className="flex justify-between h-screen font-grotesk ">
      <section className="lg:w-[40%] w-full px-10 lg:py-4 py-10">
        <div>
          <img src={logo} alt="Presenta Logo" className="w-20 lg:w-24" />
        </div>

        <section className="lg:mt-[10%] mt-[8%]">
          <h1 className="font-bold lg:text-[2rem] text-2xl  text-left  ">
            OTP
          </h1>

          <div className="my-10 text-sm font-medium text-left lg:my-2 text-gray40 ">
            <h3>Please enter the OTP sent to your email address.</h3>
          </div>

          <form onSubmit={verifyAccount} className="mt-[5%]">
            <div className="flex items-center justify-center my-5 lg:my-10 gap-x-4">
              {otpValues.map((value, index) => {
                return (
                  <input
                    inputMode="numeric"
                    ref={inputRefs[index]}
                    key={index}
                    placeholder="0"
                    type="text"
                    value={value}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace") {
                        if (index !== 0 && !otpValues[index]) {
                          inputRefs[index - 1].current.focus();
                        }
                      }
                    }}
                    onPaste={(e) => handlePaste(e, index)}
                    className="otp text-center text-lg text-sm bg-orange10 rounded-lg outline-none placeholder:text-sm  placeholder:text-center placeholder:text-lg lg:w-[3.5rem] lg:h-[3.5rem] w-[2.2rem] h-[2.2rem] border"
                  />
                );
              })}
            </div>

            <button
              disabled={!isButtonActive}
              className={`w-full py-3 my-4 text-sm text-white bg-black rounded-md ${
                isButtonActive ? "opacity-100" : "opacity-50 cursor-not-allowed"
              }`}
            >
              {isLoading ? <ClipLoader color="white" size={12} /> : "Continue"}
            </button>
          </form>

          <div className="flex items-center justify-center mt-4 gap-x-1">
            <p className="text-sm ">Resend link in</p>

            <div className="text-sm font-bold text-primary">
              {" "}
              <Countdown
                autoStart={true}
                zeroPadTime={2}
                date={Date.now() + 180000}
                renderer={renderer}
              />
            </div>
          </div>
        </section>
      </section>

      {/* carousel */}
      <section className="lg:w-[60%] hidden lg:block bg-orange10 h-screen px-10 ">
        <LoginCarousel />
      </section>
      {/*  */}
    </section>
  );
}
