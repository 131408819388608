import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { useDispatch, useSelector } from "react-redux";

//assets
import addPlus from "assets/svg/add-square.svg";
import orangeSpace from "assets/svg/orangeSpace.svg";
import uploadThumb from "assets/svg/uploadThumb.svg";

import { ClipLoader } from "react-spinners";
import { getTeamsState } from "store/Teams/teamsSlice";
import { errorToast } from "utils/toastUtils";
import { useToast } from "components/UI/ToastProvider";
import { createBriefcase, getBriefcases } from "store/Briefcase/briefcaseSlice";

function CreateSpace(props, ref) {
  const { closeModal } = props;
  const toast = useToast();
  const dispatch = useDispatch();
  const uploadFileRef = useRef();

  useImperativeHandle(ref, () => ({
    resetModal() {
      setChips([]);
      setTitle(null);
      setInputValue("");
      setImageFile(null);
      setDescription(null);
      setImagePreview(null);
    },
  }));

  //useselector
  const { teamInfo } = useSelector(getTeamsState);

  //usestate
  const [chips, setChips] = useState([]);
  const [title, setTitle] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  //functions

  //  chip functions
  const handleRemoveChip = (index) => {
    const newChips = [...chips];
    newChips.splice(index, 1);
    setChips(newChips);
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddChip = (event) => {
    if (
      (event.key === "Enter" || event.key === ",") &&
      inputValue.trim() !== ""
    ) {
      event.preventDefault();
      setChips([...chips, inputValue.trim()]);
      setInputValue("");
    }
  };
  //

  const openUploadModal = () => {
    uploadFileRef.current.click();
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    // Clear the file input value to allow selecting the same file again
    if (e.target?.value) {
      e.target.value = null;
    }
    if (file) {
      const type = file.type;

      // Check if the file type is allowed
      if (!type?.includes("image")) {
        setImageFile(null);
        setImagePreview(null);
        errorToast("This file type is not allowed.");
        return;
      }

      setImageFile(file);
      const preview = URL.createObjectURL(file);
      setImagePreview(preview);
    }
  };

  const dragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };

  const drop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    // setIsButtonActive(true);
    // setPptFile(file);
  };

  const createSpace = () => {
    const teamId = teamInfo.data?.teamId;
    const tags = chips.join(",");

    if (!teamId) {
      errorToast("You don't belong to a team");
      return;
    }
    if (!title) {
      errorToast("Enter title");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("TeamId", teamId);
    formData.append("Title", title);
    formData.append("Description", description);
    formData.append("Tags", tags);
    formData.append("Banner", imageFile);

    dispatch(createBriefcase(formData)).then(() => {
      setIsLoading(false);
      closeModal();
      dispatch(getBriefcases(teamId));
    });
  };

  return (
    <section className="py-3">
      <div className="mt-[-20px]">
        <img src={orangeSpace} alt="" />
      </div>

      <section>
        <div>
          <h1 className="text-2xl font-semibold">Create Space</h1>

          <p className="mt-2 text-sm">
            Create a playlist to organize your presentation materials
          </p>
        </div>

        <section>
          <section>
            <input
              hidden
              type="file"
              ref={uploadFileRef}
              onChange={handleFileUpload}
            />
            {!imagePreview && (
              <div
                draggable
                onDrop={(e) => drop(e)}
                onDragOver={(e) => dragOver(e)}
                className="my-6 border-2 border-dashed rounded-lg cursor-pointer bg-gray20"
                onClick={openUploadModal}
              >
                <div className="flex flex-col items-center py-4 text-center">
                  <p>
                    {" "}
                    <span className="font-semibold text-primary">
                      Click to upload thumbnail
                    </span>{" "}
                    or drag and drop
                  </p>
                  <div>
                    <img src={uploadThumb} alt="" className="mt-2" />
                  </div>
                </div>
              </div>
            )}

            {imagePreview && (
              <div className="relative my-6 rounded-lg">
                <img
                  src={imagePreview}
                  alt=""
                  className="rounded-lg w-full h-[7.25rem] object-cover"
                />
                <div
                  onClick={openUploadModal}
                  className="absolute cursor-pointer bottom-1 left-2 "
                >
                  <img src={uploadThumb} alt="" className="mt-2" />
                </div>
              </div>
            )}
          </section>

          {/* form  */}
          <section className="mt-4">
            <div className="flex flex-col gap-y-1">
              <label htmlFor="space" className="font-normal text-gray700">
                Name your space
              </label>
              <input
                type="text"
                // ref={amountRef}
                // value={amount}
                // onInput={handleInput}
                placeholder="Enter space name"
                onChange={(e) => setTitle(e.target.value)}
                className="py-3 pl-4 text-sm rounded-lg outline-none bg-orange10"
              />
            </div>

            <div className="flex flex-col my-4 gap-y-2">
              <label htmlFor="space" className="font-normal text-gray700">
                Tags
              </label>
              <div
                type="text"
                className=" bg-orange10 transition-all flex items-center gap-x-2 gap-y-1  max-w-xl flex-wrap [ py-1 px-2 ] [ border rounded-lg outline-none ] [ placeholder:text-grey90 ]"
              >
                {chips.map((chip, index) => (
                  <div
                    key={index}
                    className="flex items-center px-4 py-1 border rounded-full text-gray500 bg-gray30 gap-x-2 border-gray180"
                  >
                    <p> {chip}</p>
                    <i
                      className="text-xs cursor-pointer pi pi-times mt-[2px]"
                      onClick={() => handleRemoveChip(index)}
                    ></i>
                  </div>
                ))}

                <input
                  type="text"
                  value={inputValue}
                  placeholder="Enter tag"
                  onKeyDown={handleAddChip}
                  onChange={handleInputChange}
                  className="flex-1 p-1 m-1 bg-transparent border-none outline-none "
                />
              </div>
            </div>

            <div className="flex flex-col gap-y-1">
              <label htmlFor="description" className="font-normal text-gray700">
                Description
              </label>
              <div className="flex items-center w-full gap-x-2">
                <textarea
                  type="text"
                  placeholder="what is your space about"
                  // value={formatNumber(convertedPoints)}
                  onChange={(e) => setDescription(e.target.value)}
                  className={` w-full
                   py-3 pl-4 text-sm rounded-lg outline-none bg-orange10 transition-all`}
                />
                {/* {isConversionLoading && (
                  <ClipLoader size={12} color="gray" className="w-1/12" />
                )} */}
              </div>
            </div>

            <section className="flex my-4 mt-6 gap-x-4">
              <button
                disabled={isLoading}
                onClick={createSpace}
                className={`flex items-center justify-center w-full px-4 py-3 text-white  ${
                  isLoading ? "opacity-40 cursor-not-allowed" : "opacity-100"
                } bg-black rounded-lg gap-x-2`}
              >
                {!isLoading ? (
                  <>
                    <img src={addPlus} alt="" />
                    Create space
                  </>
                ) : (
                  <ClipLoader size={12} color="white" />
                )}
              </button>
            </section>
          </section>
          {/*  */}
        </section>
      </section>
    </section>
  );
}

export default forwardRef(CreateSpace);
