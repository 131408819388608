import { Toast } from "primereact/toast";
import React, { createContext, useContext, useEffect, useRef } from "react";
import { setToast } from "utils/toastUtils";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const toast = useRef(null);

  useEffect(() => {
    setToast(toast.current);
  }, []);

  return (
    <ToastContext.Provider value={toast}>
      <Toast ref={toast} className="text-sm" position="top-center" />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};
