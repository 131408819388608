import React, { useEffect, useState } from "react";

import { getUserDetails } from "utils/localStorageServices";

//components
import TeamList from "../Team/Table/TeamList";
import Billing from "components/Admin/Settings/Billing";
import AdminNavbar from "components/UI/AdminLayout/Navbar";
import AccountSettings from "components/Admin/Settings/AccountSettings";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTeamMembers, getTeamsState } from "store/Teams/teamsSlice";

export default function Settings() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  //useselector
  const { teamMembers } = useSelector(getTeamsState);

  //usestates
  const [tab, setTab] = useState(0);
  const [teams, setTeams] = useState([]);
  const [navProps, setNavProps] = useState({});

  //useeffects
  useEffect(() => {
    const tabParam = searchParams.get("tab")?.toLowerCase();
    !tabParam && setTab(0);
    tabParam === "account" && setTab(0);
    tabParam === "billing" && setTab(1);
    tabParam === "team" && setTab(2);
  }, [searchParams]);

  useEffect(() => {
    const user = getUserDetails();
    const userName = user?.user ?? user?.fullName;
    setNavProps({
      title: "Settings",
      subTitle: `Welcome back ${userName}`,
    });

    dispatch(getTeamMembers());
  }, [dispatch]);

  useEffect(() => {
    const myTeam = teamMembers.data;
    if (myTeam) {
      setTeams(myTeam);
    }
  }, [teamMembers]);

  return (
    <section className="relative">
      <AdminNavbar navProps={navProps} />

      <section className="my-20">
        <section className="flex border-b gap-x-4">
          <Link to="/admin/settings?tab=account">
            <button
              onClick={() => setTab(0)}
              className={`px-4 pb-2 cursor-pointer   ${
                tab === 0 && "border-b-2  text-primary border-b-primary"
              }`}
            >
              Account settings
            </button>
          </Link>

          <Link to="/admin/settings?tab=billing">
            <button
              onClick={() => setTab(1)}
              className={`px-4 pb-2 cursor-pointer   ${
                tab === 1 && "border-b-2  text-primary border-b-primary"
              }`}
            >
              Billing
            </button>
          </Link>

          <Link to="/admin/settings?tab=team">
            <button
              onClick={() => setTab(2)}
              className={`px-4 pb-2 cursor-pointer   ${
                tab === 2 && "border-b-2  text-primary border-b-primary"
              }`}
            >
              Team
            </button>
          </Link>
        </section>

        <section className="my-8">
          {tab === 0 && <AccountSettings />}

          {tab === 1 && <Billing />}

          {tab === 2 && <TeamList teams={teams} />}
        </section>
      </section>
    </section>
  );
}
