import { StartPoll } from "hooks/SignalR/Invokers/Presenter/StartPoll";
import { useEffect } from "react";
import { getActivePoll } from "utils/pollStateManagement";

export const useGetViewersList = (
  connection,
  presentationId,
  connectionState,
  eventHandler
) => {
  useEffect(() => {
    const handleEventResponse = (id, users) => {
      // const activePoll = getActivePoll();
      // activePoll &&
      //   StartPoll(connection, presentationId, null, activePoll?.pollId);

      eventHandler(users);
      // console.log(users, "virewers");
    };
    if (connection) {
      connection.on("UserList", handleEventResponse);
    }
    return () => {
      if (connection) {
        connection.off("UserList", handleEventResponse);
      }
    };
  }, [connection, presentationId, connectionState, eventHandler]);
};
