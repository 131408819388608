import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//libraries
import { Drawer, Modal } from "antd";

//component
import Loader from "components/UI/Loader";
import PollCreation from "./Drawers/PollCreation";
import AddFile from "./Modals/AddDocuments/AddFile";
import MainContent from "../../../components/Admin/Presentation/PresentationDetails/MainContent/MainContent";
import EditPresentationNavbar from "components/Admin/Presentation/PresentationDetails/Navbar/EditPresentationNavbar";

//slices
import {
  clearSideImages,
  setActiveSlide,
  setSideImages,
} from "store/Slides/slidesSlice";
import {
  getDocumentFiles,
  getPresentationDetails,
  getPresentationState,
} from "store/Presentations/presentationSlice";
import { getPollDetails, getPollState } from "store/Poll/pollSlice";
import { closePollDrawer, getUIState } from "store/UI";

//utils
import { imageStream } from "utils/imageStream";

export default function PresentationDetails() {
  const { presentationId } = useParams();

  const dispatch = useDispatch();

  //usememo
  const imageCache = useMemo(() => ({}), []);

  //useselector
  const { documentFiles, presentationDetails } =
    useSelector(getPresentationState);
  const { pollDetails } = useSelector(getPollState);

  //usestate
  const [startTime, setStartTime] = useState(null);
  const [documentList, setDocumentList] = useState([]);
  const [activeImage, setActiveImage] = useState(null);
  const [activePlaylist, setActivePlaylist] = useState(null);
  const [presentationStatus, setPresentationStatus] = useState(null);
  const [isAddFileModalOpen, setIsAddFileModalOpen] = useState(false);

  //useeffect

  // Protect inspect tab
  //disable right click
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };
    window.addEventListener("contextmenu", handleContextMenu);

    return () => {
      window.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  //

  // get presentation details
  useEffect(() => {
    if (presentationDetails.data) {
      const { doc, presentation } = presentationDetails.data;

      const sortedDocuments = [...doc].sort((a, b) => {
        const first = Number(a?.position ?? 0);
        const second = Number(b?.position ?? 0);
        if (first < second) {
          return -1;
        } else if (first > second) {
          return 1;
        }
        return 0;
      });

      setDocumentList(sortedDocuments);
      setStartTime(presentation?.scheduledTime);
      setPresentationStatus(presentation?.status);

      if (doc?.length > 0) {
        const activeDoc = sortedDocuments?.[0];
        setActivePlaylist(activeDoc);
      }
    }
  }, [presentationDetails, dispatch]);

  // update UI
  useEffect(() => {
    let isCancelled = false;
    setActiveImage(null);

    const getStream = async (id) => {
      const cachedImg = imageCache[id];

      if (cachedImg) {
        setActiveImage(cachedImg);
      } else {
        const url = await imageStream(id);
        imageCache[id] = url;
        setActiveImage(url);
      }
    };

    if (documentFiles.data) {
      const slides = [...documentFiles.data?.slides];

      slides.sort((a, b) => {
        const first = Number(a?.title.split("_")?.[1]);
        const second = Number(b?.title.split("_")?.[1]);
        if (first < second) {
          return -1;
        } else if (first > second) {
          return 1;
        }
        return 0;
      });
      dispatch(setSideImages(slides));

      if (!isCancelled) {
        if (slides.length > 0) {
          getStream(slides?.[0]?.id);
          dispatch(setActiveSlide(slides?.[0]));
        } else {
          getStream(documentFiles.data?.id);
        }
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [documentFiles, dispatch]);

  //switch between playlist
  useEffect(() => {
    dispatch(clearSideImages());

    const fileType = activePlaylist?.type?.toLowerCase();

    if (fileType === "file") {
      setActiveImage(null);
      dispatch(getDocumentFiles(activePlaylist?.documentId));
    } else {
      setActiveImage("kdkdk");
      const pollId = activePlaylist?.documentId;
      dispatch(getPollDetails({ presentationId, pollId })).then(
        ({ type, payload }) => {
          if (type?.includes("fulfilled")) {
            const activeQuestionType = payload?.questions?.[0];
            const questions = payload?.questions;
            dispatch(setSideImages(questions));
            setActiveImage(activeQuestionType);
          }
        }
      );
    }
  }, [activePlaylist, dispatch, presentationId]);

  useEffect(() => {
    dispatch(clearSideImages());
    setActiveImage(null);
    if (presentationId) {
      dispatch(getPresentationDetails(presentationId));
    }
  }, [dispatch, presentationId]);

  useEffect(() => {
    // Add the fadeIn class to the active image element
    const element = document.getElementById(`mainImg`);
    if (element) {
      element.classList.add(activeImage?.transition);
    }
    // Remove the fadeIn class after the animation completes
    const timeout = setTimeout(() => {
      if (element) {
        element.classList.remove(activeImage?.transition);
      }
    }, 1000); // Adjust this value to match your animation duration
    return () => clearTimeout(timeout);
  }, [activeImage]); // Update the dependency to include activeImage

  //function

  const handleAddFileCancel = () => {
    setIsAddFileModalOpen(false);
  };

  // polls

  //useselector
  const { isPollDrawerOpen } = useSelector(getUIState);

  //functions
  const onClose = () => {
    dispatch(closePollDrawer());
  };

  return (
    <>
      <section>
        {/* loader */}
        {presentationDetails.isLoading && <Loader />}
        {/*  */}
      </section>

      {!presentationDetails.isLoading && (
        <section className="relative ">
          {/* nav */}

          <EditPresentationNavbar
            startTime={startTime}
            documentList={documentList}
            presentationId={presentationId}
            activePlaylist={activePlaylist}
            setActivePlaylist={setActivePlaylist}
            presentationStatus={presentationStatus}
          />
          {/*  */}

          {/* main */}
          <MainContent
            activeImage={activeImage}
            setActiveImage={setActiveImage}
            activePlaylist={activePlaylist}
          />

          {/*  */}

          <>
            {/* Modal */}

            <Modal
              open={isAddFileModalOpen}
              onCancel={handleAddFileCancel}
              footer={null}
              width={1100}
              centered
              destroyOnClose={true}
            >
              <AddFile
                closeModal={handleAddFileCancel}
                presentationId={presentationId}
              />
            </Modal>
            {/*  */}

            {/* Drawer */}
            <>
              <Drawer
                placement="bottom"
                closable={false}
                onClose={onClose}
                open={isPollDrawerOpen}
                destroyOnClose={true}
                bodyStyle={{ padding: "0px" }}
                // height={height}
                height="98%"
              >
                <PollCreation closeDrawer={onClose} />
              </Drawer>
            </>

            {/*  */}
          </>
        </section>
      )}
    </>
  );
}
