import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//assets
import dots from "assets/svg/dots.svg";
import sort from "assets/svg/sort.svg";
import move from "assets/svg/move.svg";
import link from "assets/svg/link.svg";
import trash from "assets/svg/trash.svg";
import access from "assets/svg/access.svg";
import search from "assets/svg/search.svg";
import rename from "assets/svg/rename.svg";
// import avatar from "assets/svg/avatar.svg";
import fileImg from "assets/svg/document-text.svg";
import presentNow from "assets/svg/presentNow.svg";
import shareButton from "assets/svg/send-sqaure-2.svg";
import plus from "assets/svg/plus-small.svg";

//components

//libraries
import moment from "moment";
import { toast } from "react-toastify";
import { Column } from "primereact/column";
import { Dropdown, Menu, Modal } from "antd";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";

//slices
import {
  getFileStructureState,
  pushOpenedFolder,
  setMostActiveFolder,
} from "store/FileStructure/fileStructureSlice";
import { useLocation } from "react-router-dom";
import CreateTag from "../Modals/CreateTag";
import { getBriefcaseState } from "store/Briefcase/briefcaseSlice";
import FileThumbnail from "components/Admin/BriefCase/FileThumbnail";
import { DateFromNow } from "utils/formatDate";

export default function BriefcaseList() {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const { briefcases } = useSelector(getBriefcaseState);

  //useref
  const selectedFile = useRef(null);

  //useselector
  const { mostActiveFolder } = useSelector(getFileStructureState);

  //usestates
  const [cases, setCases] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);

  const [setIsFileUploadModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [presentationDoc, setPresentationDoc] = useState(null);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  //useffect
  useEffect(() => {
    const cases = briefcases.data;
    cases && setCases(cases);
  }, [briefcases]);

  const items = [
    {
      key: "1",
      label: (
        <div
          className="flex gap-x-2"
          onClick={() => {
            setPresentationDoc(selectedFile.current?.id);
            setIsModalOpen(true);
          }}
        >
          <img src={presentNow} alt="create new folder" className="w-4" />
          <p>Prepare Presentation</p>
        </div>
      ),
      // onClick: (file) => {
      //   setPresentationDoc(file?.id);
      //   setIsModalOpen(true);
      // },
    },

    {
      key: "2",
      label: (
        <div
          className="flex gap-x-2"
          onClick={() => setIsFileUploadModalOpen(true)}
        >
          <img src={move} alt="move file" className="w-4" />
          <p>Move file</p>
        </div>
      ),
    },

    {
      key: "3",
      label: (
        <div
          className="flex gap-x-2"
          onClick={() => setIsFileUploadModalOpen(true)}
        >
          <img src={rename} alt="rename file" className="w-4" />
          <p>Rename file</p>
        </div>
      ),
    },

    {
      key: "4",
      label: (
        <div
          className="flex gap-x-2"
          onClick={() => setIsFileUploadModalOpen(true)}
        >
          <img src={access} alt="access control" className="w-4" />
          <p>Access control</p>
        </div>
      ),
    },

    {
      key: "5",
      label: (
        <div
          className="flex gap-x-2"
          onClick={() => setIsFileUploadModalOpen(true)}
        >
          <img src={link} alt="share file" className="w-4" />
          <p>Share</p>
        </div>
      ),
    },

    {
      key: "6",
      label: (
        <div
          className="flex gap-x-2"
          // onClick={() =>
          //   selectedDocumentId?.isFolder
          //     ? setConfirmFolderDelete(true)
          //     : setConfirmDelete(true)
          // }
        >
          <img src={trash} alt="delete file" className="w-4" />
          <p>Delete</p>
        </div>
      ),
    },
  ];

  const columns = [
    {
      sortable: true,
      field: "title",
      header: "Name",
      body: (rowData) => {
        const { title, fileNum, bannerId } = rowData ?? {};

        return (
          <section>
            <section className="flex items-center gap-x-2">
              <FileThumbnail file={bannerId} width="59" height="49" />

              <div className="pt-1 text-sm font-medium ">
                <p className="text-blue400">{title}</p>
                <div className="flex items-center gap-x-1 text-gray40">
                  <img src={fileImg} alt="" />
                  <p className="my-1 font-light text-blue300">
                    {fileNum} files
                  </p>
                </div>
              </div>
            </section>
          </section>
        );
      },
    },
    {
      field: "dateCreated",
      sortable: true,
      header: "Last updated",
      body: (rowData) => {
        return (
          <div className="">
            <p>{DateFromNow(rowData?.dateCreated)}</p>
          </div>
        );
      },
    },

    {
      header: "Tags",
      body: (rowData) => {
        let tags = rowData.tags.split(",") ?? [];
        const leftOvers = tags.length - 2;
        const first4Tags = tags.slice(0, 2);

        return (
          <section onClick={() => openTagModal(rowData)}>
            {tags?.length > 0 && (
              <div className="flex items-center text-[0.8125rem] font-medium gap-x-2">
                {first4Tags?.map((tag, index) => {
                  return (
                    <div
                      className="flex items-center px-2 py-1 border rounded-full text-gray500 bg-gray30 gap-x-1 border-gray180"
                      key={index}
                    >
                      <span className="w-[0.375rem] rounded-full h-[0.375rem] bg-gray170"></span>
                      <p>{tag}</p>
                    </div>
                  );
                })}

                {leftOvers > 0 && (
                  <div className="px-2 py-1 border rounded-full text-gray500 bg-gray30 border-gray180">
                    <p>+{leftOvers}</p>
                  </div>
                )}
              </div>
            )}

            {tags?.length < 1 && (
              <div className="flex justify-start px-2 py-1 text-sm font-medium text-gray500">
                <img src={plus} alt="" />
                <p> add tag</p>
              </div>
            )}
          </section>
        );
      },
    },
    {
      header: "Access",
      body: (file) => {
        return (
          <div className="flex items-center gap-x-4">
            {file?.access?.map((avi, index) => {
              // Calculate position offsets
              let marginLeft;
              if (index === 0) {
                marginLeft = "ml-[0px]";
              } else {
                marginLeft = "ml-[-20px]";
              }
              return (
                <img
                  key={index}
                  src={avi}
                  alt={file?.name}
                  className={`object-contain h-8 w-8 rounded-[50%] ${marginLeft}`}
                />
              );
            })}
          </div>
        );
      },
    },
    {
      field: "",
      header: "",
      body: (file) => {
        return (
          <section
            onClick={(e) => {
              e.stopPropagation();
              selectedFile.current = file;
            }}
            className="flex items-center gap-x-4"
          >
            <div>
              <img src={shareButton} alt="" />
            </div>

            <Dropdown
              trigger={["click"]}
              dropdownRender={(menu) => (
                <Menu>
                  {items.map((item) => (
                    <Menu.Item
                      key={item.key}
                      onClick={() => {
                        setSelectedDocumentId(file);
                        // item?.onClick(file);
                      }}
                    >
                      {item.label}
                    </Menu.Item>
                  ))}
                </Menu>
              )}
            >
              <div className="cursor-pointer">
                <img src={dots} alt="options" className="w-7" />
              </div>
            </Dropdown>
          </section>
        );
      },
    },
  ];

  //functions
  const openTagModal = (data) => {
    setIsTagModalOpen(true);
    console.log(data, "jjj");
    setSelectedTags(data);
  };

  const closeTagModal = () => {
    setIsTagModalOpen(false);
  };

  const openFolder = ({ data }) => {
    const { id, isFolder, title } = data ?? {};
    //highlight

    isFolder && dispatch(pushOpenedFolder({ id, title }));

    if (isFolder) {
      dispatch(setMostActiveFolder(data));
    }
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;

    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  return (
    <section>
      {/* search & filter */}
      <section>
        <section className={`w-full justify-end flex`}>
          {/* <div className="flex items-center px-4 py-2 text-xs rounded-md gap-x-2 bg-gray30">
            <img src={sort} alt="sort" />
            All files
          </div> */}

          {mostActiveFolder !== "team" && (
            <div className="flex items-center self-end justify-end w-56 px-4 py-2 text-xs rounded-md gap-x-2 bg-gray30">
              <img src={search} alt="sort" />
              <input
                placeholder=" search folders"
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                className="bg-transparent w-[90%] py-1 outline-none"
              />
            </div>
          )}
        </section>
      </section>
      {/*  */}

      <section>
        <div className="my-4">
          <DataTable
            value={cases}
            paginator
            rows={30}
            className="text-xs"
            selectionMode="checkbox"
            selection={selectedStaff}
            tableStyle={{ minWidth: "50rem" }}
            onRowClick={openFolder}
            onSelectionChange={(e) => setSelectedStaff(e.value)}
            filters={filters}
            globalFilterFields={["title"]}
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
            ></Column>
            {columns?.map((col, index) => {
              if (
                pathname?.toLowerCase()?.includes("/presentation-details") &&
                col.field === "options"
              ) {
                // eslint-disable-next-line array-callback-return
                return;
              }

              return (
                <Column
                  key={index}
                  body={col?.body}
                  field={col.field}
                  header={col.header}
                  sortable={col?.sortable}
                  className="cursor-pointer"
                />
              );
            })}
          </DataTable>
        </div>
      </section>

      {/*  */}

      <>
        <Modal footer={null} open={isTagModalOpen} onCancel={closeTagModal}>
          <CreateTag details={selectedTags} closeModal={closeTagModal} />
        </Modal>
      </>

      {/* <ConfirmationModal
        open={confirmDelete}
        isLoading={isDeleteLoading}
        onClose={closeConfirmDelete}
        func={trashPresentation}
        text="Are you sure you want to delete this document?"
      />

      <ConfirmationModal
        open={confirmFolderDelete}
        isLoading={isFolderDeleteLoading}
        onClose={closeFolderConfirmDelete}
        func={deleteWorkspaceFolder}
        text="Are you sure you want to delete this folder?"
      />

      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        // maskClosable={false}
      >
        <SetPresentationTime
          docId={presentationDoc}
          closeModal={handleCancel}
          ref={setPresentationTimeRef}
        />
      </Modal> */}
    </section>
  );
}
