import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//component
import PollCreationForm from "../Poll/PollCreationForm";
import FlexContainer from "components/UI/StyleComponents/FlexContainer";

//slices
import {
  getPresentationDetails,
  getPresentationState,
} from "store/Presentations/presentationSlice";
import { getPolls, pollCreation } from "store/Poll/pollSlice";

//libraries
import { ClipLoader } from "react-spinners";

//utils
import { successToast } from "utils/toastUtils";
import {
  getPollsObject,
  getStoredPolls,
  removePollCreationTemplate,
} from "utils/pollStateManagement";

const PollCreation = ({ closeDrawer }) => {
  const pollCreationForm = useRef();
  const { presentationId } = useParams();

  const dispatch = useDispatch();

  const { presentationDetails } = useSelector(getPresentationState);

  //usestate
  const [isTemplate, setIsTemplate] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const createPoll = (isTemplate) => {
    setIsTemplate(isTemplate);

    setIsLoading(true);
    const data = {
      presentationId: isTemplate ? null : presentationId,
      title: getPollsObject()?.title,
      startTime: new Date(),
      //  moment(),

      position: presentationDetails?.data?.doc?.length,
      status: 0,
      questions: getStoredPolls(),
    };

    dispatch(pollCreation({ data, presentationId })).then((payload) => {
      setIsLoading(false);
      if (payload?.meta?.requestStatus === "fulfilled") {
        successToast(`Polls created succefully`);
        dispatch(getPolls(presentationId));
        dispatch(getPresentationDetails(presentationId));
        removePollCreationTemplate();
        closeDrawer();
      }
    });
  };

  return (
    <section>
      <section className="relative ">
        <section>
          {/* nav */}
          <nav className="fixed z-30 w-full bg-white border-b ">
            <section className="flex">
              <FlexContainer extraClass="px-4 w-full items-center">
                <section className="flex items-center gap-x-4 ">
                  <div
                    className="py-4 pl-2 pr-5 border-r-2 cursor-pointer"
                    onClick={closeDrawer}
                  >
                    <i
                      className="pi pi-times"
                      style={{ fontSize: "0.7rem" }}
                    ></i>
                  </div>

                  <div className="flex items-center font-bold font-grotesk gap-x-2">
                    <h2 className="">Pitch presentation</h2>

                    <i
                      className="pi pi-angle-right"
                      style={{ fontSize: "1rem" }}
                    ></i>

                    <h2 className="">Create a poll</h2>
                  </div>
                </section>

                <div className="flex gap-x-2">
                  <button
                    onClick={() => createPoll(true)}
                    className="px-4 py-2 text-xs font-semibold text-black border-2 border-black rounded-lg cursor-pointer gap-x-1"
                  >
                    {isLoading && isTemplate ? (
                      <ClipLoader size={12} color="black" />
                    ) : (
                      "Save as template"
                    )}
                  </button>

                  <button
                    onClick={() => createPoll(false)}
                    className="px-4 py-2 text-xs font-semibold text-white bg-black rounded-lg cursor-pointer gap-x-1"
                  >
                    {isLoading && !isTemplate ? (
                      <ClipLoader size={12} color="white" />
                    ) : (
                      "Add to presentation"
                    )}
                  </button>
                </div>
              </FlexContainer>
            </section>
          </nav>
          {/*  */}

          {/* main  */}
          <PollCreationForm ref={pollCreationForm} />
          {/*  */}
        </section>
      </section>
    </section>
  );
};

export default PollCreation;
