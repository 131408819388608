import React, { useEffect, useRef, useState } from "react";

//libraries
import { ClipLoader } from "react-spinners";

//assets
import empty from "assets/svg/emptyTemplate.svg";

//invokers
import { GetQuestions } from "hooks/SignalR/Invokers/Presenter/GetQuestions";

//components
import NowAnswering from "components/Admin/ActivePresentation/QuestionsDrawer/NowAnswering";

//listeners
import { useGetQuestions } from "hooks/SignalR/Listeners/Presenter/useGetQuestions";
import QuestionCard from "components/Admin/ActivePresentation/QuestionsDrawer/QuestionCard";

export default function AllQuestions({ connection, presentationId }) {
  const drawerRef = useRef();

  //usestate
  const [tab, setTab] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  useEffect(() => {
    if (connection) {
      GetQuestions(connection, presentationId);
    }
  }, [presentationId, connection, tab]);

  //hooks
  useGetQuestions(connection, presentationId, (questionList) => {
    const questions = questionList.sort((a, b) =>
      a?.askTime < b?.askTime ? 1 : -1
    );
    setQuestions(
      questions.filter((question) => {
        if (tab === 1) {
          return !question?.isAnswered;
        }

        if (tab === 2) {
          return question?.isAnswered;
        }

        return question;
      })
    );
    setIsLoading(false);
  });

  return (
    <section className="relative">
      <section className="fixed z-[99999] py-5 bg-white lg:top-12 mt-[-40px] lg:mt-0 lg:w-[27%] w-[90%]">
        <div className="flex items-center text-xs font-medium gap-x-4 text-gray60">
          <p
            className={` cursor-pointer ${
              tab === 0 && " font-bold text-primary"
            } `}
            onClick={() => setTab(0)}
          >
            ALL
          </p>
          <p
            className={` cursor-pointer ${
              tab === 1 && " font-bold text-primary"
            } `}
            onClick={() => setTab(1)}
          >
            UNANSWERED
          </p>
          <p
            className={` cursor-pointer ${
              tab === 2 && " font-bold text-primary"
            } `}
            onClick={() => setTab(2)}
          >
            {" "}
            ANSWERED
          </p>
        </div>
      </section>

      <section className="mt-10" ref={drawerRef}>
        {isLoading && (
          <div className="flex items-center justify-center w-full mt-20">
            <ClipLoader size={20} color="#FF6929" />
          </div>
        )}

        {!isLoading && (
          <section>
            {/* now answering  */}
            {selectedQuestion && (
              <section className="flex flex-col">
                <NowAnswering
                  connection={connection}
                  question={selectedQuestion}
                  setSelectedQuestion={setSelectedQuestion}
                />
              </section>
            )}

            {/*  */}

            {/* question queue  */}

            <div>
              <div className="mt-4">
                <p className="text-xs font-bold gap-x-4 text-gray60">
                  QUESTION QUEUE
                </p>
              </div>
              {questions?.length > 0 &&
                questions?.map((question, index) => {
                  return (
                    <section key={index}>
                      {question?.id !== selectedQuestion?.id && (
                        <QuestionCard
                          question={question}
                          connection={connection}
                          drawerRef={drawerRef}
                          setSelectedQuestion={setSelectedQuestion}
                        />
                      )}
                    </section>
                  );
                })}

              {questions.length < 1 && (
                <div className="h-[60vh] flex flex-col items-center justify-center">
                  <img src={empty} alt="no viewer" />
                  <p className="text-lg font-medium">No question on the list</p>
                </div>
              )}
            </div>
            {/*  */}
          </section>
        )}
      </section>
    </section>
  );
}
