import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//assets
import allow from "assets/svg/greenAllow.svg";
import decline from "assets/svg/redDecline.svg";
import fileEmpty from "assets/svg/emptyTemplate.svg";

//libraries
import { ClipLoader } from "react-spinners";

//SignalR
import { getConnectionState } from "store/SignalR/signalRSlice";
import { GetUsersList } from "hooks/SignalR/Invokers/Presenter/GetUsersList";
import { useGetViewersList } from "hooks/SignalR/Listeners/Presenter/useGetViewersList";

//components
import RequestList from "components/Admin/ActivePresentation/RequestList";
import ViewerList from "components/Admin/ActivePresentation/ViewerDrawer/InvitedViewers/ViewerList";
import { useSignalREventListener } from "hooks/SignalR/Listeners/Global/useSignalRListener";
import { toast } from "react-toastify";
import { storeViewerCount } from "utils/activePresentationUtils";

export default function AllViewers({ connection, isPresentationActive }) {
  const { presentationId } = useParams();

  const connectionState = getConnectionState();

  //usestate
  const [viewers, setViewers] = useState([]);
  const [requests, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isGrantAllLoading, setIsGrantAllLoading] = useState(false);

  //hook functions
  const showViewers = (users) => {
    storeViewerCount(users);
    setViewers(
      users.filter((user) => {
        return user.status === 1;
      })
    );
    setRequests(
      users.filter((user) => {
        return user.status === 0;
      })
    );
    setIsLoading(false);
  };

  //hooks
  useGetViewersList(connection, presentationId, connectionState, showViewers);

  useSignalREventListener(
    connection,
    (id, eventName, status, message, presenterConnectionId) => {
      const event = eventName?.toLowerCase();
      const msg = message?.toLowerCase();
      console.log(event, msg, "eventname");
      setIsGrantAllLoading(false);
      if (event === "grantaccesstoall" && "grantaccesstoall") {
        GetUsersList(connection, presentationId);
        toast.success("Access has been granted to all requests");
      }
    }
  );

  useEffect(() => {
    setIsLoading(true);
    GetUsersList(connection, presentationId);
  }, [connection, presentationId]);

  // function
  const grantAllAccess = () => {
    if (
      isPresentationActive === "started" ||
      isPresentationActive === "paused"
    ) {
      setIsGrantAllLoading(true);
      connection.invoke("GrantAccessToAll", presentationId).catch((err) => {
        toast.error("An error occured while granting access to all");
      });
    } else {
      toast("Resume presentation to perform action", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
      });
    }
  };

  return (
    <section>
      <section className="">
        {isLoading && (
          <div className="flex items-center h-[70vh] justify-center w-full mt-20">
            <ClipLoader size={20} color="#FF6929" />
          </div>
        )}

        {!isLoading && (
          <section className="flex flex-col gap-y-8">
            {/* waiting list */}
            {requests?.length > 0 && (
              <section>
                <section className="flex items-center justify-between mb-2">
                  <h3 className="text-xs font-bold text-gray60">
                    WAITING TO JOIN
                  </h3>

                  <section className="flex text-xs gap-x-2">
                    <div
                      onClick={grantAllAccess}
                      className="flex items-center px-2 py-1 font-bold rounded-full cursor-pointer gap-x-1 bg-green100 text-green600"
                    >
                      {isGrantAllLoading ? (
                        <ClipLoader color="green" size={12} />
                      ) : (
                        <img src={allow} alt="" />
                      )}
                      <p>Allow all</p>
                    </div>

                    {/* <div className="flex items-center px-2 py-1 font-bold text-red-600 bg-red-100 rounded-full cursor-pointer gap-x-1">
                      <img src={decline} alt="" />
                      <p>Decline all</p>
                    </div> */}
                  </section>
                </section>
                <RequestList
                  requests={requests}
                  connection={connection}
                  presentationId={presentationId}
                  isPresentationActive={isPresentationActive}
                />
              </section>
            )}

            {/*  */}

            {/* viewers list */}

            {viewers?.length > 0 && (
              <ViewerList
                viewers={viewers}
                connection={connection}
                presentationId={presentationId}
                isPresentationActive={isPresentationActive}
              />
            )}
            {/*  */}

            {viewers?.length < 1 && requests?.length < 1 && (
              <section className="flex flex-col items-center justify-center h-[70vh] text-sm ">
                <img src={fileEmpty} alt="" />
                <div className="text-center">
                  <p className="font-semibold">
                    There are no active viewers in this presentation{" "}
                  </p>
                </div>
              </section>
            )}
          </section>
        )}
      </section>
    </section>
  );
}
