//route guard
import AuthRoute from './AuthRoute';
import ProtectedRoute from './ProtectedRoute';

//components

import Team from 'views/Admin/Team';
import Home from 'views/Website/Home';
import AboutUs from 'views/Website/About';
import OTP from 'views/Authentication/OTP';
import Pricing from 'views/Website/Pricing';
import Workspace from 'views/Admin/Workspace';
import Dashboard from 'views/Admin/Dashboard';
import ViewerMode from 'views/User/ViewerMode';
import MyDocuments from 'views/Admin/Documents';
import WaitingArea from 'views/User/WaitingArea';
import Presentation from 'views/Admin/Presentation';
import AdminLayout from 'components/UI/AdminLayout';
import Register from 'views/Authentication/Register';
import Onboarding from 'views/Authentication/Onboarding';
import JoinPresentation from 'views/User/JoinPresentation';
// import DocumentList from "views/Admin/Documents/PollCreation";
import ActivePresentation from 'views/Admin/ActivePresentation';
import WebsiteLayout from 'components/Website/Layout/WebsiteLayout';
import PresentationDetails from 'views/Admin/Presentation/PresentationDetails';
import PresentationEnded from 'views/User/PresentationStatus/PresentationEnded';
import Support from 'views/Website/Support';
import Test from 'views/Website/test';
import TeamPlanCheckout from 'views/Admin/Team/Checkout';
import Directory from 'views/Admin/Directory';
import Visitors from 'views/Admin/Directory/Visitors';
import Leads from 'views/Admin/Directory/Leads';
import PresentationMetrics from 'views/Admin/Presentation/PresentationMetrics';
import Settings from 'views/Admin/Settings';
import RemovedUser from 'views/User/RemovedUser';
import WordCloudTest from 'views/Admin/Test/WordCloudTest';
import MyBriefCase from 'views/Admin/Briefcase';

const { createBrowserRouter, Routes, Route } = require('react-router-dom');
const { default: ErrorPage } = require('views/ErrorPage');
const { default: Login } = require('views/Authentication/Login');

export const router = createBrowserRouter([
  //website routes
  {
    path: '/',
    element: (
      <Routes>
        <Route
          path='/'
          element={
            <WebsiteLayout>
              <Home />
            </WebsiteLayout>
          }
        />
      </Routes>
    ),
  },

  // {
  //   path: "/test",
  //   element: <Test />,
  // },

  {
    path: '/about-us',
    element: (
      <WebsiteLayout>
        <AboutUs />
      </WebsiteLayout>
    ),
  },

  {
    path: '/pricing',
    element: (
      <WebsiteLayout>
        <Pricing />
      </WebsiteLayout>
    ),
  },

  {
    path: '/support',
    element: (
      <WebsiteLayout>
        <Support />
      </WebsiteLayout>
    ),
  },

  //admin routes

  {
    path: '/login',
    element: (
      <AuthRoute>
        <Login />
      </AuthRoute>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: '/signup',
    element: (
      <AuthRoute>
        <Register />
      </AuthRoute>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: '/OTP',
    element: (
      <AuthRoute>
        <OTP />
      </AuthRoute>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: '/onboarding',
    element: <Onboarding />,
    errorElement: <ErrorPage />,
  },

  {
    path: '/admin',
    element: (
      <ProtectedRoute>
        <AdminLayout />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'test',
        element: <WordCloudTest />,
      },
      {
        path: 'mypresentations',
        element: <Presentation />,
      },

      {
        path: 'mybriefcase',
        element: <MyBriefCase />,
      },

      {
        path: 'workspace',
        element: <Workspace />,
      },

      // {
      //   path: 'directory',
      //   element: <Directory />,
      //   children: [],
      // },
      { path: 'visitors', element: <Visitors /> },
      { path: 'leads', element: <Leads /> },

      {
        path: 'presentation-metric/:presentationId',
        element: <PresentationMetrics />,
      },

      {
        path: 'mydocuments',
        element: <MyDocuments />,
      },

      // {
      //   path: "mydocuments/:id",
      //   element: <DocumentList />,
      // },

      {
        path: 'team',
        element: <Team />,
      },

      {
        path: 'checkout/:id',
        element: <TeamPlanCheckout />,
      },

      {
        path: 'settings',
        element: <Settings />,
      },
    ],
  },

  {
    path: 'presentation-details/:presentationId',
    element: (
      <ProtectedRoute>
        <PresentationDetails />
      </ProtectedRoute>
    ),
  },

  {
    path: 'active-presentation/:presentationId',
    element: (
      <ProtectedRoute>
        <ActivePresentation />
      </ProtectedRoute>
    ),
  },

  //user's portal
  {
    path: 'join-presentation/:presentationId',
    element: <JoinPresentation />,
  },
  {
    path: 'waiting-area/',
    element: <WaitingArea />,
  },

  {
    path: 'viewer-mode/:friendlyId',
    element: <ViewerMode />,
  },

  {
    path: 'presentation-ended',
    element: <PresentationEnded />,
  },
  {
    path: 'removed',
    element: <RemovedUser />,
  },
]);
