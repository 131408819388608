export const generateRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  // Exclude white (#FFFFFF) and black (#000000)
  if (color === "#FFFFFF" || color === "#000000") {
    return generateRandomColor();
  }
  return color;
};
