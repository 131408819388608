import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

//libraries
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { Toast } from "primereact/toast";

//assets
import expand from "assets/svg/expand.svg";
import leftArrow from "assets/svg/leftArrow.svg";

//components
import Loader from "components/UI/Loader";
import Navbar from "components/User/ViewerMode/Navbar";

//utils

import {
  getConnectionId,
  setUnlockedSlides,
  stopViewerJourney,
  getUnlockedSlides,
  beginViewerJourney,
  resetUnlockedSlides,
  hasViewerJourneyBegan,
  resetTimer,
  getPresentationId,
  localStorageSetter,
} from "utils/localStorageServices";
import { baseUrl } from "utils/baseUrl";
import { imageStream } from "utils/imageStream";

//slices
import {
  connectSignalR,
  getConnectionState,
  getSignalRConnection,
  getSignalRState,
} from "store/SignalR/signalRSlice";
import {
  getPresentationDetails,
  getPresentationState,
} from "store/Presentations/presentationSlice";
import { viewerModeUseCase } from "hooks/SignalR/EventResponse/UseCases/ViewerModeUseCase";

//hook
import { useViewerModeEventResponse } from "hooks/SignalR/EventResponse/useViewerModeResponse";
import { useSignalRConnectionStatus } from "hooks/SignalR/Listeners/Global/useSignalConnectionStatus";
import { RecordTimeline } from "hooks/SignalR/Invokers/Viewer/RecordTimeline";
import { useSignalREventListener } from "hooks/SignalR/Listeners/Global/useSignalRListener";
import { useHasViewerBeenRemoved } from "hooks/SignalR/Listeners/Viewers/useHasViewerBeenRemoved";
import { getPollDetails } from "store/Poll/pollSlice";
import PollQuestionTemplate from "components/User/ViewerMode/PollQuestionTemplate";
import { downloadSlide } from "store/Slides/slidesSlice";
import { ClipLoader } from "react-spinners";
import { useGenericListener } from "hooks/SignalR/Listeners/Global/UseGenericListener";
import {
  pollDetailService,
  startPollService,
  storePolls,
} from "utils/activePresentationUtils";
import { formatDate } from "utils/formatDate";

let connection = null;

export default function ViewerMode() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  connection = getSignalRConnection();
  const connectionState = getConnectionState();

  const { friendlyId } = useParams();

  const { presentationDetails } = useSelector(getPresentationState);
  const { connectionId } = useSelector(getSignalRState);

  // usememo
  const documentSlideCache = useMemo(() => ({}), []);

  //hook

  useViewerModeEventResponse(
    connection,
    (eventName, status, message, presenterConnectionId) => {
      viewerModeUseCase(eventName, status, presenterConnectionId);
    }
  );
  useSignalRConnectionStatus(connection);

  useSignalREventListener(
    connection,
    (id, eventName, status, message, presenterConnectionId) => {
      const event = eventName?.toLowerCase();

      // console.log(event, message, "event name");
    }
  );

  //usestate
  const [count, setCount] = useState(0);
  const [modalPosition, setModalPosition] = useState();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [orientation, setOrientation] = useState(
    window.matchMedia("(orientation: portrait)").matches
      ? "portrait"
      : "landscape"
  );

  //new usestates
  const [timer, setTimer] = useState(null);
  const [pollID, setPollID] = useState(null);
  const [timeSpent, setTimeSpent] = useState(0);
  const [canNavBack, setCanNavBack] = useState(false);
  const [canNavForward, setCanNavForward] = useState(false);

  const [presentationId, setPresentationId] = useState(null);
  const [title, setTitle] = useState("Starting presentation...");
  const [activeStreamImage, setActiveStreamImage] = useState(null);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  //experiment usestate
  const [activeCount, setActiveCount] = useState(0);
  const [documentObj, setDocumentObj] = useState({});
  const [documentList, setDocumentList] = useState([]);
  const [playlistNumber, setPlaylistNumber] = useState(null);
  const [activePlaylist, setActivePlaylist] = useState(null);
  const [unlockedSlideId, setUnlockedSlideId] = useState(null);
  const [loadingFirstDocument, setLoadingFirstDocument] = useState(true);
  const [presenterActiveCount, setPresenterActiveCount] = useState(null);
  const [presenterActivePlaylist, setPresenterActivePlaylist] = useState(null);

  // useref
  const toast = useRef(null);
  const activeImgRef = useRef(null);
  const timeSpentRef = useRef(timeSpent);

  //hook
  useHasViewerBeenRemoved(connection, presentationId);

  //experiment useeffect

  console.log(Intl.DateTimeFormat().resolvedOptions().timeZone, "time zone");
  //get presentation details
  useEffect(() => {
    if (connectionState === "Connected") {
      if (getConnectionId()) {
        connection
          .invoke("Attend", friendlyId, 1, getConnectionId())
          .then(() => {
            dispatch(getPresentationDetails(friendlyId)).then(
              ({ type, payload }) => {
                if (type?.includes("fulfilled")) {
                  const presentationId = payload?.presentation?.id;
                 
                  const timestamp = formatDate(
                    payload?.presentation?.startTime
                  );
                  console.log(timestamp)
                  const dateObject = new Date(timestamp);
                  const startTime = dateObject.getTime();
                  // const startTime = date.getTime() + 3600000; //subtract an hour
                  localStorage.setItem("presentationId", presentationId);
                  localStorage.setItem("startTime", JSON.stringify(startTime));
                  localStorage.setItem("isActive", JSON.stringify("true"));
                  setPresentationId(presentationId);
                  window.dispatchEvent(new Event("timer"));
                }
              }
            );
          })
          .catch((err) => {
            console.log(err);
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail:
                "An unexpected error occurred invoking 'Attend' on the server",
            });
          });
      } else {
        let routeOrigin = window.location.origin;
        window.location.href = `${routeOrigin}/join-presentation/${friendlyId}`;
      }
    }
  }, [connectionState, friendlyId, presentationId, dispatch]);

  //handles presentation details (stores document list, saves active document & caches document ids/keys)
  useEffect(() => {
    if (presentationDetails.data) {
      const { data } = presentationDetails ?? {};

      if (data) {
        const { doc } = data ?? {};

        if (doc) {
          let docs = [...doc];
          const sortedDocs = docs.sort((a, b) => {
            const first = Number(a?.position ?? 0);
            const second = Number(b?.position ?? 0);
            if (first < second) {
              return -1;
            } else if (first > second) {
              return 1;
            } else if (first === second) {
              return 0;
            }
            return 0;
          });

          const currentSlide =
            data?.presentation?.currentSlide === -1
              ? 0
              : data?.presentation?.currentSlide;
          const currentDoc =
            data?.presentation?.currentItem ?? sortedDocs?.[0]?.documentId;

          setDocumentList(sortedDocs);

          //set presentation title
          setTitle(data?.presentation?.title);

          //save unlocked slides
          setUnlockedSlides(uuidv4(), currentDoc, currentSlide);

          if (docs?.length > 0) {
            setActivePlaylist(currentDoc);
            setActiveCount(currentSlide);

            setPresenterActiveCount(currentSlide);
            setPresenterActivePlaylist(currentDoc);
          }

          sortedDocs.forEach((document) => {
            documentSlideCache[document.documentId] = [];
          });

          setDocumentObj(documentSlideCache);
        }
      }
    }
  }, [presentationDetails, documentSlideCache]);

  //THE ENGINE
  useEffect(() => {
    //convert object into an array of keys
    const documentKeys = Object.keys(documentObj);
    const storePoll = (pollId) => {
      if (pollId && documentSlideCache[pollId]?.length < 1) {
        dispatch(getPollDetails({ presentationId, pollId })).then(
          ({ type, payload }) => {
            if (type?.includes("fulfilled")) {
              storePolls(payload);
              const questionList = payload?.questions;

              documentSlideCache[pollId] = questionList;

              if (activePlaylist === pollId) {
                setLoadingFirstDocument(false);
                // console.log(documentSlideCache, "first load poll");
                // Mark first document slides as loaded
              }
            }
          }
        );
      }
    };

    const slideObj = async (docSlides, docId, slide, retries = 3) => {
      for (let attempt = 1; attempt <= retries; attempt++) {
        const id = slide?.id ?? slide;
        try {
          const slideExist = documentSlideCache[docId].some(
            (slide) => slide.slideId === id
          );
          if (!slideExist) {
            const url = await imageStream(id);

            if (url) {
              const slideObj = {
                slideId: id,
                imgUrl: url,
                title: slide?.title ?? "1",
                fileType: slide?.url,
              };

              documentSlideCache[docId].push(slideObj);
              documentSlideCache[docId].sort((a, b) => {
                const first = Number(a?.title.split("_")?.[1]);
                const second = Number(b?.title.split("_")?.[1]);
                if (first < second) {
                  return -1;
                } else if (first > second) {
                  return 1;
                }
                return 0;
              });

              // Check if this is the first document and if all slides are loaded
              if (
                activePlaylist === docId &&
                documentSlideCache[docId].length === (docSlides.length ?? 1)
              ) {
                setLoadingFirstDocument(false); // Mark first document slides as loaded
              }
            }
            break; //Exit when successful
          } else {
            break; //exit iteration and move to the next
          }
        } catch (err) {
          if (attempt === retries) {
            console.error(
              `Error fetching image stream for slide ID: ${id} after ${retries} attempts`,
              err
            );
          } else {
            console.warn(
              `Retrying image stream for slide ID: ${id}, attempt ${attempt}`
            );
          }
        }
      }
    };

    const getDocumentDetails = async (docId, retries = 3) => {
      //this retries a broken request 3 times
      for (let attempt = 1; attempt <= retries; attempt++) {
        try {
          const documentType = documentList
            ?.find((doc) => doc?.documentId === docId)
            ?.type?.toLowerCase();

          if (documentType === "poll") {
            storePoll(docId);
            // break;
            // Exit the loop if successful
          } else {
            //fetch document details
            const documentDetails = await axios.get(
              `${baseUrl}/Document/${docId}`
            );

            // assign slides
            const docSlides = documentDetails?.data?.slides;

            if (docSlides?.length > 0) {
              //function to get stream of each slide
              await Promise.all(
                docSlides.map(async (slide) => {
                  await slideObj(docSlides, docId, slide);
                })
              );
            } else {
              // usecase for when it's a different MIME type (image, video)
              await slideObj(1, docId, documentDetails?.data?.id);
            }

            break; // Exit the loop if successful
          }
        } catch (err) {
          if (attempt === retries) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Error occured while fetching slides",
            });
            console.error(
              `Error fetching document details for document ID: ${docId} after ${retries} attempts`,
              err
            );
          } else {
            console.warn(
              `Retrying document details for document ID: ${docId}, attempt ${attempt}`
            );
          }
        }
      }
    };

    const fetchDocumentList = async () => {
      try {
        const docId = activePlaylist?.documentId ?? activePlaylist;

        //run first object request first or savedDoc
        await getDocumentDetails(docId);

        let remainingKeys = documentKeys.filter((key) => key !== docId);

        //process other document keys next and in parallel except 1

        await Promise.all(
          remainingKeys.map(
            (key) =>
              documentSlideCache[key]?.length < 1 && getDocumentDetails(key)
          )
        );

        console.log(documentSlideCache, "check2");
      } catch (err) {
        console.error("Error in fetching document list", err);
      }
    };

    if (documentKeys?.length > 0) {
      fetchDocumentList();
    }
  }, [documentObj, documentSlideCache, dispatch]);

  //renders active image and next image

  // useEffect(() => {
  //   // This is technically not needed
  //   timeSpentRef.current = timeSpent;
  //   console.log("i keep getting triggered for every re-rendering");
  // }, [timeSpent]);

  // Timer to update timeSpent
  useEffect(() => {
    // This technically does not get re-rendered but because the setInterval timer is active, it would basically continue running
    const slideTime = setInterval(() => {
      timeSpentRef.current += 1;
    }, 1000);

    return () => clearInterval(slideTime);
  }, []);

  // useEffect(() => {
  //   const slideTime = setInterval(() => {
  //     setTimeSpent(timeSpentRef.current + 1);
  //   }, 1000);

  //   return () => {
  //     clearInterval(slideTime);
  //   };
  // }, []);

  useEffect(() => {
    if (!loadingFirstDocument) {
      const id = activeCount === -1 ? 0 : activeCount;

      const slide = documentSlideCache?.[activePlaylist]?.[id];

      setActiveStreamImage(slide);

      //navigation
      let unlockedSlides = getUnlockedSlides();
      const reversedArr = unlockedSlides?.reverse();
      let foundReverseIndex;

      reversedArr.some((el, index) => {
        if (activePlaylist === el.docId && activeCount === el.slideNumber) {
          setUnlockedSlideId(el.id);
          foundReverseIndex = index;
          return true;
        }
        return false;
      });
      // checking if viewer can move backwards
      if (reversedArr[foundReverseIndex + 1]) {
        setCanNavBack(true);
      } else {
        setCanNavBack(false);
      }

      //checking if viewer can move forward
      if (reversedArr[foundReverseIndex - 1]) {
        setCanNavForward(true);
      } else {
        setCanNavForward(false);
      }
    }
  }, [activeCount, loadingFirstDocument, activePlaylist, documentSlideCache]);

  ///

  // useeffect

  // UI handles fullscreen

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(orientation: portrait)");

    const handleOrientationChange = () => {
      setOrientation(mediaQuery.matches ? "portrait" : "landscape");
    };

    mediaQuery.addEventListener("change", handleOrientationChange);

    return () => {
      mediaQuery.removeEventListener("change", handleOrientationChange);
    };
  }, []);

  // attention modal positions
  useEffect(() => {
    let positions = [
      {
        left: "29%",
      },
      {
        right: "29%",
      },
      {
        top: "2%",
      },
      {
        bottom: "29%",
      },
    ];

    let index = Math.floor(Math.random() * 3);

    setModalPosition(positions[index]);
  }, []);
  //
  //

  // Signal R connection

  useEffect(() => {
    if (!connection) {
      dispatch(connectSignalR());
    }
  }, [dispatch]);

  useEffect(() => {
    if (connection) {
      //close connection
      connection.onclose((e) => {
        if (e) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: `Connection closed with error: "${e || ""}"`,
          });
        }
      });

      //events
      connection.on("MoveSlide", (id, int, docId) => {
        int = int === -1 ? 0 : int;

        // console.log(id, int, docId, "presenter moved slide");
        setPresenterActiveCount(int);
        setPresenterActivePlaylist(docId);
        const timeSpent = timeSpentRef.current;

        let unlockedSlides = getUnlockedSlides();
        let unlockedSlideLength = unlockedSlides?.length;
        let leavingSlide = unlockedSlides?.[unlockedSlideLength - 1]?.id;
        let previousSlide = unlockedSlides?.[unlockedSlideLength - 2]?.id;

        //record timeline
        RecordTimeline(
          connection,
          leavingSlide,
          "presenter",
          timeSpentRef.current,
          previousSlide
        );

        timeSpentRef.current = 0;
        //save unlocked slides
        setUnlockedSlides(uuidv4(), docId, int);
        if (!hasViewerJourneyBegan()) {
          //reset
          setActiveCount(int);
          setActivePlaylist(docId);
          //
        }
      });

      connection.on("Attend");
      connection.on("EndPresentation", (message, something) => {
        navigate("/presentation-ended");
        resetTimer();
        resetUnlockedSlides();
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, connection]);

  // UI useffects
  useEffect(() => {
    if (activePlaylist) {
      const documentNumber = Object.keys(documentSlideCache).findIndex((el) => {
        return el === activePlaylist;
      });

      setPlaylistNumber(documentNumber + 1);
    }
  }, [activePlaylist, documentSlideCache]);

  useEffect(() => {
    const previousSlide = () => {
      beginViewerJourney();

      let unlockedSlides = getUnlockedSlides();

      const currentSlidePosition = unlockedSlides.findIndex((el) => {
        return el.id === unlockedSlideId;
      });
      const slide = unlockedSlides[currentSlidePosition - 1];
      const { docId, slideNumber } = slide ?? {};
      if (slide) {
        setActivePlaylist(docId);
        setActiveCount(slideNumber);

        let unlockedSlides = getUnlockedSlides();
        let leavingSlide = unlockedSlides?.[currentSlidePosition]?.id;
        let previousSlide = slide?.id;

        //record timeline
        RecordTimeline(
          connection,
          leavingSlide,
          "presenter",
          timeSpentRef.current,
          previousSlide
        );

        timeSpentRef.current = 0;
      }
    };

    const nextSlide = () => {
      beginViewerJourney();
      let unlockedSlides = getUnlockedSlides();

      const currentSlidePosition = unlockedSlides.findIndex((el) => {
        return el.id === unlockedSlideId;
      });
      const slide = unlockedSlides[currentSlidePosition + 1];
      const { docId, slideNumber } = slide ?? {};

      if (slide) {
        setActivePlaylist(docId);
        setActiveCount(slideNumber);
      }

      let leavingSlide = unlockedSlides?.[currentSlidePosition]?.id;
      let previousSlide = unlockedSlides?.[currentSlidePosition - 1]?.id;

      //record timeline
      RecordTimeline(
        connection,
        leavingSlide,
        "presenter",
        timeSpentRef.current,
        previousSlide
      );

      timeSpentRef.current = 0;
    };
    const handleEventListener = (e) => {
      if (connection) {
        if (e.key === "ArrowRight") {
          nextSlide();
        }

        if (e.key === "ArrowLeft") {
          previousSlide();
        }
      }
    };

    window.addEventListener("keydown", handleEventListener);

    return () => {
      window.removeEventListener("keydown", handleEventListener);
    };
  }, [unlockedSlideId]);

  //

  //function

  //fullscreen function

  const enterFullscreen = () => {
    const elem = activeImgRef.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      elem.webkitRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  };

  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      enterFullscreen();
    } else {
      exitFullscreen();
    }
  };
  ///

  const previousSlide = () => {
    beginViewerJourney();
    let unlockedSlides = getUnlockedSlides();

    const currentSlidePosition = unlockedSlides.findIndex((el) => {
      return el.id === unlockedSlideId;
    });
    const slide = unlockedSlides[currentSlidePosition - 1];
    const { docId, slideNumber } = slide ?? {};

    setActivePlaylist(docId);
    setActiveCount(slideNumber);

    let leavingSlide = unlockedSlides?.[currentSlidePosition]?.id;
    let previousSlide = slide?.id ?? null;

    //record timeline
    RecordTimeline(
      connection,
      leavingSlide,
      "presenter",
      timeSpentRef.current,
      previousSlide
    );

    timeSpentRef.current = 0;
  };

  const nextSlide = () => {
    beginViewerJourney();
    let unlockedSlides = getUnlockedSlides();

    const currentSlidePosition = unlockedSlides.findIndex((el) => {
      return el.id === unlockedSlideId;
    });
    const slide = unlockedSlides[currentSlidePosition + 1];
    const { docId, slideNumber } = slide ?? {};

    setActivePlaylist(docId);
    setActiveCount(slideNumber);

    let leavingSlide = unlockedSlides?.[currentSlidePosition]?.id;
    let previousSlide = unlockedSlides?.[currentSlidePosition - 1]?.id;

    //record timeline
    RecordTimeline(
      connection,
      leavingSlide,
      "presenter",
      timeSpentRef.current,
      previousSlide
    );

    timeSpentRef.current = 0;
  };
  const catchPresenter = () => {
    stopViewerJourney();
    setActiveCount(presenterActiveCount);
    setActivePlaylist(presenterActivePlaylist);
  };

  const isLandscape = () => {
    return orientation === "landscape" && 1000 > screenWidth;
  };

  const download = () => {
    console.log(activeStreamImage, "img");

    setIsDownloadLoading(true);
    const slideId = activeStreamImage?.slideId;
    const presentationId = getPresentationId();
    const fileName = activeStreamImage?.title;
    const presentaConnectionId = getConnectionId();
    dispatch(
      downloadSlide({ slideId, presentationId, presentaConnectionId })
    ).then(({ type, payload }) => {
      setIsDownloadLoading(false);
      if (type?.includes("fulfilled")) {
        let filename = fileName ?? "downloaded-file";

        const url = URL.createObjectURL(new Blob([payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}.jpg`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    });
  };

  ////// HANDLING POLLS

  //hook

  useGenericListener(connection, (presentationId, eventName, obj) => {
    const event = eventName?.toLowerCase();
    const { pollId } = obj ?? {};

    setPollID(pollId);

    if (event === "startpoll") {
      //get poll
      const pollDetails = pollDetailService(pollId);
      setActiveStreamImage(pollDetails);
      startPollService();
      setCount(0);
      setTimer(pollDetails?.questions?.[0]?.duration);

      //

      //reset answer
      localStorage.removeItem("pollAnswer");

      const pollAnswer = {
        pollId,
        answers: [],
      };

      localStorage.setItem("pollAnswer", JSON.stringify(pollAnswer));
    }
  });

  //////

  return (
    <section className="relative">
      <Toast ref={toast} className="text-sm" position="top-center" />
      {/* nav */}

      <Navbar
        title={title}
        toast={toast}
        connection={connection}
        activeImage={activeStreamImage?.imgUrl}
        slideId={activeStreamImage?.slideId}
        activeDocument={activePlaylist}
        slideNumber={activeCount}
        presentationId={presentationId}
        friendlyId={friendlyId}
      />

      {/*  */}

      {/* main */}

      <section className="  mt-[4rem] w-full absolute  ">
        {/* loader */}
        {/* {(documentFiles?.isLoading || !activeImage) && <Loader />} */}
        {(presentationDetails?.isLoading ||
          !connection ||
          loadingFirstDocument ||
          !presentationId) && <Loader />}
        {/*  */}

        {!presentationDetails?.isLoading &&
          connection &&
          !loadingFirstDocument &&
          presentationId && (
            <section className="lg:h-[90vh] h-[80vh] flex flex-col py-20 ">
              <section
                className={`lg:w-[60%] w-[98%] flex flex-col items-center justify-center h-full mx-auto ${
                  isLandscape() && "w-[90%] py-20"
                }`}
              >
                {/* slides */}
                <section
                  className={`flex items-center ${
                    orientation === "landscape" && "mt-1"
                  } z-50 gap-x-2`}
                >
                  {/* left control */}
                  {isLandscape() && (
                    <button
                      className={` ${
                        !canNavBack && "opacity-40"
                      }    cursor-pointer  `}
                      onClick={previousSlide}
                      disabled={!canNavBack}
                    >
                      <img
                        src={leftArrow}
                        alt="move left"
                        className={`cursor-pointer`}
                      />
                    </button>
                  )}
                  {/*  */}

                  {/* image stream   */}
                  <section>
                    <section>
                      {!activeStreamImage?.questions && (
                        <section
                          onClick={handleFullscreen}
                          className={`z-10 flex w-full py-2 mt-3 rounded-lg items-center justify-center bg-gray100 `}
                        >
                          {!activeStreamImage?.question && (
                            <img
                              ref={activeImgRef}
                              alt="presentation slide"
                              src={activeStreamImage?.imgUrl}
                              className={` object-contain rounded-lg cursor-pointer lg:w-[1900px] [ lg:max-h-[550px]  vl:max-h-[620px] vvl:h-[800px]  ] py-1 px-4  mx-auto`}
                            />
                          )}
                        </section>
                      )}

                      {activeStreamImage?.questions && (
                        <section className=" bg-gray100 w-full  px-4 pt-4 pb-6 overflow-y-scroll  [  lg:max-h-[35rem]  vl:max-h-[37rem] vvl:max-h-[43rem] ] ">
                          <PollQuestionTemplate
                            toast={toast}
                            documentSlideCache={documentSlideCache}
                            connection={connection}
                            pollID={pollID}
                            pollDetails={activeStreamImage}
                            timer={timer}
                            setTimer={setTimer}
                            count={count}
                            setCount={setCount}
                          />
                        </section>
                      )}
                    </section>
                  </section>
                  {/*  */}

                  {/* right control */}
                  {isLandscape() && (
                    <button
                      className={` ${
                        !canNavForward && "opacity-40"
                      }  cursor-pointer `}
                      onClick={nextSlide}
                      disabled={!canNavForward}
                    >
                      <img
                        src={leftArrow}
                        alt="move left"
                        className={`cursor-pointer rotate-180`}
                      />
                    </button>
                  )}
                  {/*  */}
                </section>

                {/*  */}

                {/* controls */}
                {!activeStreamImage?.questions && (
                  <section className="flex flex-col items-center justify-between w-full px-2 my-4 gap-y-2 lg:flex-row">
                    {!isLandscape() && (
                      <section className="flex gap-x-4">
                        <button
                          className={` ${
                            !canNavBack && "opacity-40"
                          }  flex items-center px-5 py-2 lg:text-sm text-xs border cursor-pointer rounded-lg bg-gray100 gap-x-2 text-gray500`}
                          onClick={previousSlide}
                          disabled={!canNavBack}
                        >
                          <img
                            src={leftArrow}
                            alt="move left"
                            className={`cursor-pointer`}
                          />
                          <p>Previous slide</p>
                        </button>

                        {/* slide number  */}
                        <div className="mt-2 text-sm text-center ">
                          {" "}
                          <span className="px-3 py-2 font-medium text-white rounded-full bg-primary">
                            {activeCount + 1}
                          </span>
                        </div>

                        {/*  */}

                        <button
                          className={` ${
                            !canNavForward && "opacity-40"
                          } flex items-center px-5 py-2 lg:text-sm text-xs border cursor-pointer rounded-lg bg-gray100 gap-x-2 text-gray500`}
                          onClick={nextSlide}
                          disabled={!canNavForward}
                        >
                          <p>Next slide</p>
                          <img
                            src={leftArrow}
                            alt="move left"
                            className={`cursor-pointer rotate-180`}
                          />
                        </button>
                      </section>
                    )}

                    <section className="flex items-center gap-x-2">
                      {isDownloadLoading ? (
                        <ClipLoader color="gray" size={14} />
                      ) : (
                        <i
                          onClick={download}
                          className="mr-2 text-gray-500 pi pi-download"
                          style={{ fontSize: "1.4rem" }}
                        ></i>
                      )}

                      <img
                        src={expand}
                        alt="move right"
                        onClick={handleFullscreen}
                        className={`cursor-pointer w-6  h-full`}
                      />

                      {hasViewerJourneyBegan() && (
                        <button
                          className={`  flex items-center px-4 py-2 text-sm border  rounded-md cursor-pointer bg-primary gap-x-2 text-white`}
                          onClick={catchPresenter}
                        >
                          <p>Catch up with presenter</p>
                        </button>
                      )}
                    </section>
                  </section>
                )}
                {/*  */}
              </section>
            </section>
          )}
      </section>
      {/*  */}
    </section>
  );
}
