import React, { useEffect, useRef, useState } from "react";

//assets
import left from "assets/svg/left.svg";
import right from "assets/svg/right.svg";
import expand from "assets/svg/expand.svg";
import message from "assets/svg/messagetext.svg";

//utils
import { getFileTypeImg } from "utils/getFileType";

//libraries
import { ClipLoader } from "react-spinners";

//components
import FlexContainer from "components/UI/StyleComponents/FlexContainer";
import { useSelector } from "react-redux";
import { getPollState } from "store/Poll/pollSlice";
import { trimText } from "utils/formatter";
import { Tooltip } from "antd";

export default function BottomNavigation({
  jumpTo,
  activeCount,
  activeImgRef,
  documentList,
  navigateSlides,
  activePlaylist,
  nextStreamImage,
  navigatePlaylist,

  documentSlideCache,
}) {
  // useref
  const carouselRef = useRef(null);

  //useselector
  const { pollDetails } = useSelector(getPollState);

  //usestate
  const [diffMIME, setDiffMIME] = useState(true);
  const [carouselImg, setCarouselImg] = useState(null);

  useEffect(() => {
    if (carouselRef.current) {
      const carouselElement =
        carouselRef.current.children[activeCount + 4] && activeCount > 4
          ? carouselRef.current.children[activeCount + 4]
          : carouselRef.current.children[activeCount];
      carouselElement?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [activeCount, carouselRef, diffMIME]);

  useEffect(() => {
    setCarouselImg([]);
    const slides =
      activePlaylist?.type?.toLowerCase() !== "poll"
        ? documentSlideCache?.[activePlaylist]
        : pollDetails?.data?.questions;

    setCarouselImg(slides);
    setDiffMIME(slides?.[0]?.title === "1");
  }, [activePlaylist, documentSlideCache, pollDetails]);

  //function
  const enterFullscreen = () => {
    const elem = activeImgRef.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      elem.webkitRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  };

  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      enterFullscreen();
    } else {
      exitFullscreen();
    }
  };

  return (
    <section className="w-full mt-4 mb-10">
      <section className="">
        <section>
          <FlexContainer
            extraClass={`w-[75%]  ${!nextStreamImage && "mx-auto"}   `}
          >
            {/* navigation */}
            <section className="flex items-center gap-x-2">
              <img
                src={left}
                alt="move left"
                className={`cursor-pointer`}
                onClick={() => navigateSlides("prev")}
              />

              <p className="text-sm mt-[-2px] ">
                {" "}
                {activeCount + 1} / {carouselImg?.length}
              </p>

              <img
                src={right}
                alt="move right"
                className={`cursor-pointer`}
                onClick={() => navigateSlides("next")}
              />
            </section>
            {/*  */}

            {!activePlaylist?.type && (
              <div className="flex items-center px-3 py-2 rounded-lg gap-x-4 bg-gray30 ">
                {/* <img
                src={message}
                alt="move right"
                className={`cursor-pointer w-5`}
              /> */}

                <img
                  src={expand}
                  alt="move right"
                  onClick={handleFullscreen}
                  className={`cursor-pointer w-5 object-cover`}
                />
              </div>
            )}
          </FlexContainer>
        </section>
        <section className="w-full px-2 py-4 my-4 border rounded-xl">
          {/* Carousel  */}
          {!diffMIME && (
            <section className="w-full overflow-x-scroll present-scroll">
              {!carouselImg && (
                <div className="flex items-center justify-center w-full">
                  <ClipLoader size={12} color="gray" />
                </div>
              )}

              <section
                className="flex w-full mx-auto gap-x-16"
                ref={carouselRef}
              >
                {carouselImg?.map((slide, index) => {
                  const count = index + 1;
                  const { imgUrl, question, questionType } = slide ?? {};
                  return (
                    <div
                      key={index}
                      className={`flex items-center gap-x-1 min-w-[11.2rem]  `}
                      onClick={() => jumpTo(index)}
                    >
                      <div className="">
                        <p
                          className={`w-[18px] h-[18px] flex items-center justify-center text-[12px]  rounded-full  font-bold  ${
                            activeCount === index
                              ? " text-white bg-primary"
                              : "text-gray500 bg-gray10"
                          } `}
                        >
                          {count}
                        </p>
                      </div>

                      {question && (
                        <section
                          className={`my-2 text-center flex flex-col items-center justify-center gap-x-1 w-[10rem] h-[6.125rem] rounded-lg cursor-pointer  ${
                            activeCount === index
                              ? " border-2 border-primary p-1 bg-primary text-white"
                              : "shadow-lg "
                          }`}
                        >
                          <p className="font-semibold"> {question}</p>{" "}
                          <small>({questionType})</small>
                        </section>
                      )}

                      {!question && (
                        <img
                          src={imgUrl}
                          alt="presentation slide"
                          className={`w-[11rem] h-[100px] rounded-2xl cursor-pointer   ${
                            activeCount === index ? " bg-primary p-1" : " "
                          }`}
                        />
                      )}
                    </div>
                  );
                })}
              </section>
            </section>
          )}
          {/*  */}

          {/* Playlist  */}
          <section className="flex w-full px-2 mt-4 text-sm rounded-lg bg-gray100 gap-x-4 ">
            {documentList?.map((doc) => {
              const { url, type, title } = doc ?? {};
              const isActive =
                (activePlaylist?.documentId ?? activePlaylist) ===
                doc?.documentId;
              const img = getFileTypeImg(url !== "" ? url : type, isActive);

              return (
                <Tooltip key={doc.id} placement="bottom" title={title ?? type}>
                  <div
                    onClick={() => navigatePlaylist(doc)}
                    className={`flex flex-col items-center min-w-20 px-2 mb-4 cursor-pointer pt-4 pb-2 rounded-b-xl text-sm text-gray60  ${
                      isActive && " bg-white text-primary font-bold"
                    } `}
                  >
                    <img src={img} alt="" className="w-5 " />
                    <p className="mt-1">{trimText(title ?? type, 20)}</p>
                  </div>
                </Tooltip>
              );
            })}
          </section>

          {/*  */}
        </section>
      </section>

      {/*  */}
    </section>
  );
}
