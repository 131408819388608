import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//libraries
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

//assets
import team from "assets/svg/team.svg";
import noImg from "assets/image/noImg.png";
import copyLink from "assets/svg/copyLInk.svg";

//slices
import {
  addPresentationGuest,
  getInvitedGuests,
  getPresentationState,
  schedulePresentation,
} from "store/Presentations/presentationSlice";

//components
import Label from "components/UI/Label";
import ToggleSwitch from "components/UI/ToggleSwitch";
import SubmitButton from "components/UI/Button/SubmitButton";
import FlexContainer from "components/UI/StyleComponents/FlexContainer";

//utils
import { imageStream } from "utils/imageStream";
import { trimText } from "utils/formatter";
import emailValidation from "utils/emailValidation";

function SchedulePresentation(props, ref) {
  const { closeModal, presentationId, scheduledTime, shareLink } = props;

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  let routeOrigin = window.location.origin.replace("https://", "");

  //useselector
  const { invitedGuests } = useSelector(getPresentationState);
  const { presentationDetails } = useSelector(getPresentationState);

  //usestate
  const [guestList, setGuestList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [makePrivate, setMakePrivate] = useState(false);
  const [thumbnailImg, setThumbnailImg] = useState(null);
  const [disableButton, setDisableButton] = useState(true);
  const [startTime, setStartTime] = useState(scheduledTime);

  useImperativeHandle(ref, () => ({
    resetModal() {
      setGuestList([]);
      setMakePrivate(false);
      setIsLoading(false);
      setDisableButton(true);
    },
    openDrawer() {
      dispatch(getInvitedGuests(presentationId));
    },
  }));

  //useeffect
  useEffect(() => {
    dispatch(getInvitedGuests(presentationId));
  }, [dispatch, presentationId]);

  useEffect(() => {
    const fetchImageStream = async () => {
      const thumbnail = presentationDetails.data?.presentation?.thumbnail;
      if (!thumbnail || thumbnail?.includes("//")) {
        setThumbnailImg(noImg);
      } else {
        const url = await imageStream(thumbnail);
        setThumbnailImg(url);
      }
    };
    fetchImageStream();
  }, []);

  useEffect(() => {
    if (invitedGuests?.data) {
      // const arr = new Set([...guestList, guest]);
      setGuestList([...invitedGuests?.data]);
    }
  }, [invitedGuests]);

  //funvtion

  const onDateChange = (value, dateString) => {
    const selectedDate = dayjs.utc(dateString); // Convert selected date string to dayjs object in UTC
    const formattedDate = selectedDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    setStartTime(formattedDate);
  };

  const addGuests = () => {
    if (guestList?.length < 1) {
      toast.error("Enter a guest");
      return;
    }

    setIsLoading(true);
    setDisableButton(false);

    dispatch(addPresentationGuest({ presentationId, guestList })).then(
      (payload) => {
        if (payload?.meta?.requestStatus === "fulfilled") {
          toast.success(" Guest added successfully");
          closeModal();
        }
        setIsLoading(false);
        setDisableButton(true);
      }
    );
  };

  const schedule = () => {
    if (!startTime) {
      toast.error("Enter presentation start time");
      return;
    }

    console.log(guestList, "guest");
    if (guestList?.length < 1) {
      toast.error("Enter a guest");
      return;
    }

    let data = {
      presentationDate: startTime,
      makePrivate,
      guestList,
    };

    setIsLoading(true);
    setDisableButton(false);

    dispatch(schedulePresentation({ presentationId, data })).then((payload) => {
      if (payload?.meta?.requestStatus === "fulfilled") {
        setIsLoading(false);
        setDisableButton(true);
        toast.success("Presentation settings updated successfully");
        closeModal();
      }
    });
  };

  const toggleState = () => {
    setMakePrivate(!makePrivate);
  };

  const removeEmail = (removeGuest) => {
    const newGuestList = guestList?.filter((guest) => {
      return guest !== removeGuest;
    });
    setGuestList(newGuestList);
  };

  const handleBlur = (e) => {
    const guest = e.target.value;

    if (guest?.length > 2) {
      if (emailValidation.checkEmail(guest)) {
        // const arr = new Set([...guestList, guest]);
        setGuestList([...guestList, guest]);
      }

      e.target.value = "";
    }
  };

  const handleKeyDown = (e) => {
    const guest = e.target.value.replace(",", "");

    if (e.key === "Enter" || e.key === "," || e.key === " ") {
      if (guest?.length > 2) {
        if (emailValidation.checkEmail(guest)) {
          // const arr = new Set([...guestList, guest]);
          // setGuestList(arr);
          // console.log(arr, "arr");
          setGuestList([...guestList, guest]);
        }
        e.target.value = "";
      }
    }
  };

  return (
    <section className="flex flex-col h-full lg:flex-row md:flex-row gap-y-4 ">
      <section className="flex flex-col items-center justify-center lg:w-1/2 md:5/12 gap-y-4 ">
        <h3 className="text-lg font-semibold text-center">
          {presentationDetails.data?.presentation?.title}
        </h3>

        <div className="rounded-lg bg-gray100   w-[70%] h-[20rem] flex items-center justify-center">
          {thumbnailImg ? (
            <img
              alt={presentationDetails.data?.presentation?.title}
              src={thumbnailImg}
              className="p-3  h-[20rem] object-cover w-full"
            />
          ) : (
            <div className="flex items-center justify-center">
              <ClipLoader size={12} color="gray" />
            </div>
          )}
        </div>

        <div className="flex cursor-pointer gap-x-2" onClick={shareLink}>
          <p className="text-lg font-semibold text-primary">
            {trimText(
              ` ${routeOrigin}/${presentationDetails?.data?.presentation?.friendlyId}`,
              25
            )}
          </p>
          <img src={copyLink} alt="" />
        </div>
      </section>

      <section className="relative flex items-center justify-center lg:w-1/2 md:7/12 bg-orange10">
        <div
          className="absolute cursor-pointer right-10 top-5"
          onClick={closeModal}
        >
          <i className="pi pi-times" style={{ fontSize: "1.7rem" }}></i>
        </div>
        <section className="lg:w-[60%] md:w-11/12 bg-white rounded-xl p-4">
          <div>
            <h2 className="text-lg font-medium text-gray600">Share</h2>
            <p className="mt-1 text-sm font-medium text-gray40">
              People with access
            </p>
          </div>

          <section>
            {scheduledTime && (
              <div className="my-4 ">
                <Label text="Presentation date" />
                <div className="mt-2">
                  <DatePicker
                    className="w-full px-2 py-2 text-sm rounded-md datepicker border-3 text-gray700 bg-orange10"
                    showTime
                    onChange={onDateChange}
                    // onOk={onOk}
                    format="YYYY-MM-DD HH:mm"
                    defaultValue={dayjs(scheduledTime ?? new Date())}
                    minDate={dayjs()}
                  />
                </div>
              </div>
            )}
          </section>

          <section className="my-4">
            <p className="font-semibold text-gray40">Other controls</p>
            <FlexContainer extraClass="my-4 text-gray40 font-semibold text-sm">
              <p>Private Presentation</p>
              <ToggleSwitch toggleState={toggleState} />
            </FlexContainer>
          </section>

          <section className="my-4">
            {/* <p className="font-medium">Add guest</p> */}

            <div className="flex flex-col mt-2 lg:flex-row card p-fluid gap-x-1">
              <input
                type="email"
                placeholder="add email"
                onBlur={(e) => handleBlur(e)}
                onKeyDown={(e) => handleKeyDown(e)}
                className="w-full px-4 py-2 text-sm border rounded-md outline-none bg-gray30"
              />

              <button className="  text-sm px-3 py-2 lg:w-[40%] text-center justify-center text-gray140 rounded-lg flex items-center gap-x-2 bg-gray30 ">
                <img src={team} alt="" className="w-4" /> upload bulk
              </button>
            </div>

            <setion className="max-w-[60px] py-4">
              {!invitedGuests?.isLoading ? (
                <div className="flex flex-wrap max-w-full pt-2 gap-x-2 gap-y-2">
                  {guestList?.length > 0 &&
                    guestList?.map((guest, index) => {
                      return (
                        <div
                          className="flex items-center justify-between px-4 py-1 text-sm text-black border rounded-full bg-gray30 gap-x-2 border-gray350"
                          key={index}
                        >
                          <p>{guest}</p>
                          <i
                            className="pt-[3px] cursor-pointer pi pi-times"
                            style={{ fontSize: "0.8rem" }}
                            onClick={() => removeEmail(guest)}
                          ></i>
                        </div>
                      );
                    })}

                  {guestList?.length < 1 && (
                    <div className="w-full py-2 font-semibold text-center text-gray350">
                      No guest added
                    </div>
                  )}
                </div>
              ) : (
                <section className="flex justify-center my-4">
                  <ClipLoader size={12} color="gray" />
                </section>
              )}
            </setion>
          </section>

          <SubmitButton
            title="Done"
            isLoading={isLoading}
            isActive={disableButton}
            onClick={
              pathname?.includes("active-presentation") ? addGuests : schedule
            }
          />
        </section>
      </section>
    </section>
  );
}
export default forwardRef(SchedulePresentation);
