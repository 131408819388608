import React from "react";

//assets
// import gif from "assets/svg/websiteGif.svg"

//components
import Banner from "components/Website/Home/Banner";
import Features from "components/Website/Home/Features";
import BlackBackground from "components/Website/Home/BlackBackground";
import StackCard from "components/Website/Home/StackCard";

export default function Home() {
  return (
    <section>
      <Banner />
      <Features />
      <section className="sticky top-20">
        <StackCard />
      </section>
      <BlackBackground />
    </section>
  );
}
