import React from "react";



//components
import RequestListCard from "./RequestListCard";


export default function RequestList({
  requests,
  connection,
  presentationId,
  isPresentationActive,
}) {
  return (
    <section>
      <section>
        {requests?.map((request, index) => {
          return (
            <RequestListCard
              key={index}
              viewer={request}
              connection={connection}
              presentationId={presentationId}
              isPresentationActive={isPresentationActive}
            />
          );
        })}
      </section>
    </section>
  );
}
