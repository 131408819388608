import React, { useEffect, useState } from "react";

//assets
import empty from "assets/svg/emptyTemplate.svg";
import PollOptionList from "components/Admin/Presentation/PollCreation/PollOptionList";

export default function PollDrawer({ connection, presentationId }) {
  //usestate
  const [options, setOptions] = useState(null);

  return (
    <section className="relative">
      <div className="">
        <h5 className="text-sm font-semibold text-gray60">POLL QUESTION?</h5>
        <textarea
          rows={4}
          name="pollQuestion"
          placeholder="Type your questions"
          className="w-full px-4 py-3 mt-3 rounded-lg outline-none bg-orange10"
        ></textarea>
      </div>

      <section className="my-4">
        <h5 className="mb-3 text-sm font-semibold text-gray60">CREATE YOUR OPTIONS?</h5>
        <PollOptionList setOptions={setOptions} />
      </section>

      <section className="mt-4">
        <h5 className="text-sm font-semibold uppercase text-gray60">Duration</h5>

        <div className="flex w-full mt-4 gap-x-2">
          <input
            type="text"
            placeholder="Mins"
            className="w-1/2 px-4 py-3 border rounded-lg placeholder:text-gray350"
          />

          <input
            type="text"
            placeholder="Secs"
            className="w-1/2 px-4 py-3 border rounded-lg placeholder:text-gray350"
          />
        </div>
      </section>

      <button className="w-full py-4 my-4 text-sm font-semibold text-white bg-black rounded-lg">
        Create poll
      </button>
    </section>
  );
}
