import { toast } from "react-toastify";

export const RemoveGuest = (connection, presentationId, email, status) => {
  if (connection) {
    console.log(presentationId, email, status);

    connection
      .invoke("RemoveUser", presentationId, email, status)
      .catch((err) => {
        toast.error(err);
        console.log(err);
      });
  }
};
